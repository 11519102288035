<template>
  <div class="layout">
    <header class="header" :class="{ bgchange: bgChange }">
      <FooterDownload />
      <div class="container">
        <router-link to="/">
          <div class="handle-logo">
            <h1 class="logo"></h1>
            <h1 class="">MosApp</h1>
          </div>
        </router-link>
        <nav class="nav">
          <ul class="menu hidden-xs-only">
            <li class="menu-item" v-for="item in nav" :key="item.name">
              <router-link class="menu-item-link" :id="item.to" :to="item.to">{{
                $t(`Layout['${item.name}']`)
              }}</router-link>
              <!-- <transition name="fade"> -->
              <div
                v-show="
                  (item.to !== '/' && $route.path.includes(item.to)) ||
                  ($route.path === '/' && item.to === '/')
                "
                class="nav-line"
              ></div>
              <!-- </transition> -->
              <ul class="submenu" v-if="item.children">
                <li
                  class="submenu-item"
                  v-for="subItem in item.children"
                  :key="subItem.name"
                >
                  <router-link :to="subItem.to">{{
                    $t(`Layout['${subItem.name}']`)
                  }}</router-link>
                </li>
              </ul>
            </li>
            <li class="menu-item">
              <a href="https://dl.mosapp.app/" class="menu-item-link"><i class="el-icon-bottom"></i>{{ $t(`Footer['download']`) }}</a>
            </li>
          </ul>
          <div class="buttons">
            <el-dropdown
              class="changeLang"
              trigger="click"
              @command="changeLange"
            >
              <span class="el-dropdown-link">
                {{ lang }}<i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="zh">中文</el-dropdown-item>
                <el-dropdown-item command="en">English</el-dropdown-item>
                <!-- <el-dropdown-item command="kh">ខ្មែរ</el-dropdown-item> -->
              </el-dropdown-menu>
            </el-dropdown>
            <i
              class="menu-icon el-icon-menu show-md-and-down"
              style="color: #3370FF;"
              @click="menuShow = true"
            ></i>
          </div>
        </nav>
      </div>
      <!-- 侧边栏 -->
      <el-drawer
        class="menu-list"
        :with-header="false"
        :visible.sync="menuShow"
        direction="rtl"
        append-to-body
      >
        <el-menu
          :default-active="$route.path"
          :router="true"
          @select="menuShow = false"
          class="el-menu-vertical-demo"
        >
          <div v-for="item in nav" :key="item.name">
            <el-submenu :index="item.to" v-if="item.children">
              <template slot="title">
                <span>{{ $t(`Layout['${item.name}']`) }}</span>
              </template>
              <el-menu-item-group>
                <el-menu-item
                  v-for="subItem in item.children"
                  :key="subItem.name"
                  :route="subItem.to"
                  index="subItem.name"
                  >{{ $t(`Layout['${subItem.name}']`) }}</el-menu-item
                >
              </el-menu-item-group>
            </el-submenu>
            <el-menu-item :route="item.to" :index="item.to" v-else>
              <span slot="title">{{ $t(`Layout['${item.name}']`) }}</span>
            </el-menu-item>
          </div>
          <div class="drawer-menu-two">
            <ul>
              <el-menu-item><router-link to="/contact-us">{{ $t('Layout.contactUs') }}</router-link></el-menu-item>
            </ul>
            <ul v-for="(list, i) in footerSecurity" :key="i">
              <el-menu-item v-for="item in list" :key="item.name">
                <router-link :to="item.to">{{ $t(`Footer['${item.label_name}']`) }}</router-link>
              </el-menu-item>
            </ul>
          </div>
          <!-- <div>
            <el-menu-item route="/search" index="搜寻">
              <span slot="title">{{ $t(`Layout['搜寻']`) }}</span>
            </el-menu-item>
          </div> -->
          <div class="footer-social footer-drawer">
            <div class="container">
              <div class="content-socail content-f-drawer">
                <p>© 2014-2023 MosApp. All Rights Reserved.</p>
                <div class="container-socail">
                  <p>{{ $t(`Footer['followUs']`) }}</p>
                  <div class="box-socail">
                    <a href="https://twitter.com/MosappLin"><img src="../assets/image/home/x.png" alt="" /></a>
                    <a href="https://www.instagram.com/mosappcustomer?utm_source=qr"><img src="../assets/image/home/instagram.png" alt="" /></a>
                    <a href="https://u.wechat.com/ELD_ypQZSWx_6R_gHSwIjPU"><img src="../assets/image/home/wechat.png" alt="" /></a>
                    <router-link to="/about-us"><img src="../assets/image/home/facebook.png" alt="" /></router-link>
                    <router-link to="/about-us"><img src="../assets/image/home/linkedin.png" alt="" /></router-link>
                    <a href="https://vm.tiktok.com/ZSLuW3UEg/"><img src="../assets/image/home/tiktok.png" alt="" /></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </el-menu>
      </el-drawer>
    </header>
    <section>
      <router-view />
    </section>
    <footer>
      <div class="footer-category">
        <div class="container">
          <div class="footer-content">
            <div class="footer-logo">
              <img src="../assets/image/FooterMosApp-logo@2x.png" alt="U-PAY" />
            </div>
            <div class="footer-menu">
              <div class="left-box-list">
                <h1>{{ $t(`Footer['aboutUs']`) }}</h1>
                <ul v-for="(list, i) in footerAboutMos" :key="i">
                  <li v-for="item in list" :key="item.name">
                    <router-link :to="item.to">{{ $t(`Footer['${item.label_name}']`) }}</router-link>
                  </li>
                </ul>
              </div>
              <div class="left-box-list">
                <h1>{{ $t(`Footer['company']`) }}</h1>
                <ul v-for="(list, i) in footerCompany" :key="i">
                  <li v-for="item in list" :key="item.name">
                    <router-link :to="item.to">{{ $t(`Footer['${item.label_name}']`) }}</router-link>
                  </li>
                </ul>
              </div>
              <div class="left-box-list">
                <h1>{{ $t(`Footer['security']`) }}</h1>
                <ul v-for="(list, i) in footerSecurity" :key="i">
                  <li v-for="item in list" :key="item.name">
                    <router-link :to="item.to">{{ $t(`Footer['${item.label_name}']`) }}</router-link>
                  </li>
                </ul>
              </div>
            </div>
            <div>
              <!-- <FooterMap /> -->
            </div>
          </div>
        </div>
      </div>
      <div class="footer-social">
        <div class="container">
          <div class="content-socail">
            <p>© 2014-2023 MosApp. All Rights Reserved.</p>
            <div class="container-socail">
              <p>{{ $t(`Footer['followUs']`) }}</p>
              <div class="box-socail">
                <a href="https://twitter.com/MosappLin"><img src="../assets/image/home/x.png" alt="" /></a>
                <a href="https://www.instagram.com/mosappcustomer?utm_source=qr"><img src="../assets/image/home/instagram.png" alt="" /></a>
                <a href="https://u.wechat.com/ELD_ypQZSWx_6R_gHSwIjPU"><img src="../assets/image/home/wechat.png" alt="" /></a>
                <router-link to="/about-us"><img src="../assets/image/home/facebook.png" alt="" /></router-link>
                <router-link to="/about-us"><img src="../assets/image/home/linkedin.png" alt="" /></router-link>
                <a href="https://vm.tiktok.com/ZSLuW3UEg/"><img src="../assets/image/home/tiktok.png" alt="" /></a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
// import FooterMap from '../components/FooterMap.vue'
import FooterDownload from '../components/FooterDownload.vue'
export default {
  name: 'layout',
  components: {
    // FooterMap,
    FooterDownload
  },
  computed: {
    nav() {
      return [
        { name: 'features', to: '/features' },
        { name: 'helpCenter', to: '/help-center' },
        { name: 'about', to: '/about-us' },
        { name: 'careers', to: '/careers' },
        { name: 'blogs', to: '/blogs' }
      ]
    },
    footerAboutMos() {
      return [
        [
          { name: 'About Us', to: '/about-us', label_name: 'about' },
          { name: 'ContactUs', to: '/contact-us', label_name: 'contactUs' },
          // { name: 'FAQ', to: '/faq', label_name: 'faq' },
          { name: 'Help Center', to: '/help-center', label_name: 'helpCenter' }
        ]
      ]
    },
    footerCompany() {
      return [
        [
          { name: 'Blogs', to: '/blogs', label_name: 'blogs' },
          { name: 'Careers', to: '/careers', label_name: 'careers' }
        ]
      ]
    },
    footerSecurity() {
      return [
        [
          { name: 'User Terms of Services', to: '/user-term-service', label_name: 'userTermService' },
          { name: 'Privacy Policy', to: '/privacy-policy', label_name: 'privacyPolicy' },
          { name: 'ChildSafetyPolicy', to: '/child-safety-policy', label_name: 'childSafetyPolicy' }
        ]
      ]
    },
    lang() {
      switch (this.$i18n.locale) {
        case 'zh':
          return '中文'
        case 'en':
          return 'English'
        // case 'kh':
        //   return 'ខ្មែរ'
        default:
          this.changeLange('zh')
          return '中文'
      }
    },
    smollScreen() {
      return window.clientWidth < 1200
    }
  },
  data() {
    return {
      bgChange: false,
      menuShow: false,
      mapDialogVisible: false
      // mapIframeLoading: true
    }
  },
  mounted() {
    window.onscroll = () => {
      if (window.pageYOffset > 75) {
        this.bgChange = false
      } else {
        this.bgChange = false
      }
    }
    if (this.$i18n.locale === 'kh') {
      const body = document.querySelector('body')
      body.style.fontFamily =
        "'KhmerOS', 'Source Han Sans CN', 'microsoft yahei'"
      // body.style.wordBreak = 'break-all'
      // body.style.wordWrap = 'break-word'
    }
  },
  methods: {
    changeLange(lang) {
      this.$i18n.locale = lang
      if (lang === 'kh') {
        const body = document.querySelector('body')
        body.style.fontFamily =
          "'KhmerOS', 'Source Han Sans CN', 'microsoft yahei'"
      } else {
        const body = document.querySelector('body')
        // body.style.fontFamily =
        //   "'Source Han Sans CN', 'microsoft yahei', 'KhmerOS'"
        body.style.fontFamily =
          "'Roboto'"
      }
      localStorage.setItem('locale', lang)
    }
    // showMapDialog() {
    //   this.$data.mapDialogVisible = true
    //   this.$nextTick(() => {
    //     this.$refs.mapIframe.onload = () => {
    //       this.$data.mapIframeLoading = false
    //     }
    //   })
    // }
  }
}
</script>
<style lang="less">
.menu-list {
  .el-drawer:focus {
    outline: none;
  }
  .el-menu {
    border: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    height: 100%;
  }
  .el-drawer__body {
    padding-top: 15px;
  }
  .el-drawer.rtl {
    width: 60% !important;
    .el-menu-item,
    .el-submenu__title {
      font-size: 15px;
      line-height: 50px;
      height: 50px;
      color: #333;
    }
    @media screen and (max-width: 768px) {
      .el-menu-item:focus, .el-menu-item:hover {
        background-color: #E7EEFF;
      }
    }
    .el-submenu__icon-arrow {
      font-size: 12px;
    }
    .el-menu-item-group ul .el-menu-item {
      font-size: 14px;
    }
  }
}
</style>
<style lang="less" scoped>
@bacecolor: #e60013;
@headerHeight: 74px;
.layout {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  & > section {
    flex: 1;
    padding-top: @headerHeight;
  }
}
.header {
  position: fixed;
  z-index: 999;
  width: 100%;
  background: rgba(255, 255, 255, 0.2);
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.08);
  transition: all 0.1s;
  background-color: #fff;
  button {
    width: 80px;
    height: 34px;
    outline: none;
    background: none;
    margin-left: 20px;
    border: 1px solid #ffffff;
    border-radius: 6px;
    line-height: 34px;
    color: #fff;
    cursor: pointer;
    transition: all 0.3s;
    &:hover {
      border-color: @bacecolor;
      background-color: @bacecolor;
      color: #fff;
      opacity: 0.8;
    }
  }
  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: @headerHeight;
    line-height: 1.5;
    color: #fff;
    // width: 1650px;
    .logo {
      height: 38px;
      width: 38px;
      background: url("../assets/image/MosApp-logo@2x.png") no-repeat;
      background-size: contain;
      color: rgba(0, 0, 0, 0);
      line-height: 38px;
    }
    .handle-logo {
      display: flex;
      align-items: center;
      h1 {
        font-size: 26px;
        font-weight: 700;
        margin-left: 10px;
        color: #000;
        white-space: nowrap;
      }
    }
    .nav {
      display: flex;
      margin-left: 20px;
      .menu {
        display: flex;
        gap: 10px;
        .menu-item-link {
          text-align: center;
          height: 45px;
          display: flex;
          align-items: center;
          padding: 0 15px;
          color: #1F1F1F;
          white-space: nowrap;
        }
        .menu-item:last-child {
          background-color: #3370FF;
          border-radius: 20px;
          width: 150px;
          .menu-item-link {
            color: #fff;
          }
          i.el-icon-bottom {
            height: 20px;
            width: 20px;
            background-color: #fff;
            border-radius: 50%;
            color: #3370FF;
            font-weight: 700;
            padding: 3px;
            margin-right: 10px;
            display: flex;
            align-items: center;
            justify-content: space-around;
          }
        }
        .menu-item {
          display: flex;
          position: relative;
          flex-direction: column;
          align-items: center;
          // color: #1F1F1F;
          .submenu {
            box-sizing: border-box;
            overflow: hidden;
            border-radius: 0 0 8px 8px;
            position: absolute;
            top: 74px;
            flex-direction: column;
            height: 0;
            min-width: 100px;
            transition: all 0.3s;
            background: rgba(255, 255, 255, 0.3);
            box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.1);
            .submenu-item {
              line-height: 50px;
              text-align: center;
              a {
                padding: 0 10px;
                height: unset;
                white-space: nowrap;
                color: #fff;
              }
              &:hover {
                background: rgba(253, 230, 231, 0.6);
                a {
                  color: #666;
                }
              }
            }
          }
          &:hover {
            z-index: 9999;
            .submenu {
              display: block;
              height: auto;
              padding: 10px 0 15px;
            }
          }
        }
      }
      .nav-line {
        position: absolute;
        bottom: 0px;
        width: 100%;
        height: 2px;
        background: #3370FF;
      }
      .fade-enter-active, .fade-leave-active {
        transition: width 0.3s;
      }
      .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
        width: 0;
      }
      .buttons {
        display: flex;
        align-items: center;
        margin-left: 15px;
        /deep/.changeLang {
          white-space: nowrap;
          margin-left: 20px;
          .el-dropdown-link {
            cursor: pointer;
            color: #1F1F1F;
          }
        }
        .menu-icon {
          display: none;
          margin-left: 15px;
          font-size: 28px;
          vertical-align: middle;
          cursor: pointer;
          &:hover {
            color: #ffe6e7;
          }
        }
        @media screen and (max-width: 768px) {
          .menu-icon {
            display: inline-block;
          }
        }
      }
      @media screen and (max-width: 1200px) {
        .menu .menu-item-link {
          padding: 0 8px;
        }
        .buttons {
          margin-left: 0px;
        }
      }
      @media screen and (max-width: 992px) {
        .menu .menu-item-link {
          font-size: 13px;
          padding: 0 8px;
        }
        .buttons {
          margin-left: 0px;
        }
      }
    }
  }
}
.bgchange {
  background: #ffffff;
  box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.1);
  .container,
  a {
    color: #333333 !important;
  }
  .submenu {
    background: #ffffff !important;
  }
  .nav-line {
    background: #e60013 !important;
  }
  .logo {
    background-image: url("../assets/image/MosApp-logo@2x.png") !important;
  }
  button {
    color: #333333;
    border-color: #333333;
    &:hover {
      color: #fff;
      border-color: #e60013;
      opacity: 0.8;
    }
  }
  /deep/.changeLang {
    .el-dropdown-link {
      color: #333333 !important;
    }
  }
}
footer {
  @media screen and (max-width: 992px) {
    display: none;
  }
}
.footer-category {
  background-color: #F5F5F5;
  padding: 60px 0;
  .container {
    .footer-content {
      display: flex; justify-content: space-between;
      @media screen and (max-width: 1200px) {
        display: inherit;
      }
      .footer-menu {
        display: flex; column-gap: 15px; justify-content: space-between;
      }
      @media screen and (max-width: 577px) {
        .footer-menu {
          display: inherit;
          margin-top: 15px;
        }
      }
    }
    a {
      transform: color 0.3s;
      &:hover {
        color: #3370FF !important;
      }
    }
    .footer-logo img {
      height: 38px;
    }
    .left-box-list {
      // display: flex;
      flex-wrap: wrap;
      margin-bottom: 20px;
      h1 {
        font-size: 20px;
      }
      ul {
        margin-right: 60px;
        margin-top: 20px;
        line-height: 36px;
        a {
          color: #434343;
        }
      }
    }
  }
}
.footer-drawer {
  background: none !important;
  position: absolute;
  bottom: 0px;
  .content-f-drawer {
    padding-bottom: 20px !important;
  }
}
.drawer-menu-two {
  padding: 10px 0;
  border-top: 1px solid #ddd;
  ul li {
      // padding: 10px 0;
      a { color: #333;}
      a:focus-visible {
          outline-offset: -10px;
      }
  }
}
.footer-social {
  background-color: #F5F5F5;
  .container {
    .content-socail {
      display: flex; justify-content: space-between; align-items: center; padding-bottom: 50px;
      p {
        color: #595959;
      }
      @media screen and (max-width: 1200px) {
        display: inherit;
        // margin-top: 30px;
      }
    }
    .container-socail {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      @media screen and (max-width: 1200px) {
        justify-content: start;
        display: inherit;
        p {
          margin-top: 15px;
        }
      }
      .box-socail img{
        margin-left: 15px;
        width: 30px;
        @media screen and (max-width: 1200px) {
          margin-right: 10px;
          margin-left: 0px;
          margin-top: 5px;
        }
      }
    }
  }
}

/deep/ .map-iframe-diaglog {
  .el-dialog__header {
    display: none;
  }
  .el-dialog__body {
    padding: 5px 9px 9px 5px;
    height: 80vh;
  }
  .el-dialog {
    margin-top: 8vh !important;
    width: 90vw;
    max-width: 1200px;
  }
  .mapdialog-content {
    height: 100%;
    width: 100%;
  }
}
</style>
