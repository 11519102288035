import { makeApiRequest } from '@/utils/request'
const baseApiUrl = process.env.VUE_APP_IM_API

export function getDeleteAccountOPT(param) {
  return makeApiRequest({
    baseURL: baseApiUrl,
    url: '/web/getDeleteAccountOPT',
    method: 'POST',
    data: param
  })
}

export function checkOPT(param) {
  return makeApiRequest({
    baseURL: baseApiUrl,
    url: '/web/checkOPT',
    method: 'POST',
    data: param
  })
}

export function deleteAccount(param) {
  return makeApiRequest({
    baseURL: baseApiUrl,
    url: '/web/deleteAccount',
    method: 'POST',
    data: param
  })
}
