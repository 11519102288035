import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '../layout'

Vue.use( VueRouter )

const routes = [
  {
    path: '/',
    component: Layout,
    children: [
      { path: '/', name: 'Home', component: () => import( '@/views/Home.vue' ), meta: { title: 'Home - MosApp' } },
      { name: 'Features', path: '/features', component: () => import( '@/views/Featurs.vue' ), meta: { title: 'Features' } },
      { name: 'Help center', path: '/help-center', component: () => import( '@/views/HelpCenter.vue' ), meta: { title: 'Help center' } },
      { name: 'Help center list', path: '/help-center/categories/:title', component: () => import( '@/views/HelpCenterList.vue' ), meta: { title: 'Help center list' } },
      { name: 'Help center detail', path: '/help-center/articles/:page', component: () => import( '@/views/HelpCenterDetail.vue' ), meta: { title: 'Help center detail' } },
      { name: 'About us', path: '/about-us', component: () => import( '@/views/About.vue' ), meta: { title: 'About us' } },
      { name: 'Careers', path: '/careers', component: () => import( '@/views/Career.vue' ), meta: { title: 'Careers' } },
      { name: 'Career detail', path: '/careers/job-description/web-front-end', component: () => import( '@/views/job-description/WebFrontEnd.vue' ), meta: { title: 'Career detail' } },
      { name: 'Career detail', path: '/careers/job-description/FrontendArchitect', component: () => import( '@/views/job-description/FrontendArchitect.vue' ), meta: { title: 'Career detail' } },
      { name: 'Career detail', path: '/careers/job-description/JavaArchitect', component: () => import( '@/views/job-description/JavaArchitect.vue' ), meta: { title: 'Career detail' } },
      { name: 'Career detail', path: '/careers/job-description/iOSEngineer', component: () => import( '@/views/job-description/IOSEngineer.vue' ), meta: { title: 'Career detail' } },
      { name: 'Career detail', path: '/careers/job-description/AndroidEngineer', component: () => import( '@/views/job-description/AndroidEngineer.vue' ), meta: { title: 'Career detail' } },
      { name: 'Career detail', path: '/careers/job-description/UI_UXDesigner', component: () => import( '@/views/job-description/UI_UXDesigner.vue' ), meta: { title: 'Career detail' } },
      { name: 'Career detail', path: '/careers/job-description/ProductManager', component: () => import( '@/views/job-description/ProductManager.vue' ), meta: { title: 'Career detail' } },
      { name: 'Blogs', path: '/blogs', component: () => import( '@/views/Blogs.vue' ), meta: { title: 'Blogs' } },
      { name: 'Blog detail', path: '/blogs/InstantMessagine', component: () => import( '@/views/blog-description/InstantMessagine.vue' ), meta: { title: 'Blog detail' } },
      { name: 'Blog detail', path: '/blogs/MosApp', component: () => import( '@/views/blog-description/MosApp.vue' ), meta: { title: 'Blog detail' } },
      { name: 'Blog detail', path: '/blogs/CallingFriendsAndFamilyAround', component: () => import( '@/views/blog-description/CallingFriendsAndFamilyAround.vue' ), meta: { title: 'Blog detail' } },
      // { name: 'Download', path: '/download', component: () => import( '@/views/Download.vue' ), meta: { title: 'Download' } },
      { name: 'FAQ', path: '/faq', component: () => import( '@/views/Faq.vue' ), meta: { title: 'FAQ' } },
      { name: 'Contact us', path: '/contact-us', component: () => import( '@/views/ContactUs.vue' ), meta: { title: 'Contact us' } },
      { name: 'User term service', path: '/user-term-service', component: () => import( '@/views/UserTermService.vue' ), meta: { title: 'User term service' } },
      { name: 'Privacy policy', path: '/privacy-policy', component: () => import( '@/views/PrivacyPolicy.vue' ), meta: { title: 'Privacy policy' } },
      { name: 'Cookie policy', path: '/cookie-policy', component: () => import( '@/views/CookiePolicy.vue' ), meta: { title: 'Cookie policy' } },
      { name: 'ChildSafetyPolicy', path: '/child-safety-policy', component: () => import( '@/views/ChildSafetyPolicy.vue' ), meta: { title: 'Child Safety Policy' } },
      { name: 'DeactivateAccount', path: '/deactivate-account', component: () => import( '@/views/DeactivateAccount.vue' ), meta: { title: 'Deactivate account' } }
    ]
  },
  {
    path: '/privacy_policy',
    name: 'PrivacyPolicy',
    component: () => import( '../views/PrivacyPolicy.vue' )
  },
  {
    path: '/:lang/privacy-policy',
    name: 'UserPrivacyPolicy',
    component: () => import( '@/views/AgreementTemplate.vue' ),
    meta: { title: 'Privacy policy' }
  },
  {
    path: '/:lang/user-term-service',
    name: 'UserTermService',
    component: () => import( '@/views/AgreementTemplate.vue' ),
    meta: { title: 'User term service' }
  },
  {
    path: '/:lang/vip-service',
    name: 'VIPService',
    component: () => import( '@/views/AgreementTemplate.vue' ),
    meta: { title: 'VIP service' }
  },
  {
    path: '/:lang/vip-continuous',
    name: 'VIPContinuous',
    component: () => import( '@/views/AgreementTemplate.vue' ),
    meta: { title: 'VIP continuous' }
  },
  {
    path: '*',
    name: '404',
    component: () => import( '../views/404.vue' )
  }
]

const router = new VueRouter( {
  mode: 'history',
  scrollBehavior ( to, from, savedPosition ) {
    if ( to.hash ) {
      return {
        selector: to.hash
      }
    }
    if ( savedPosition ) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },
  routes
} )

const DEFAULT_TITLE = 'MosApp'
router.beforeEach((toRoute, fromRoute, next) => {
  const title = toRoute.meta && toRoute.meta.title ? toRoute.meta.title : DEFAULT_TITLE
  window.document.title = title
  document.getElementsByTagName('title')[0].innerHTML = title
  next()
})

export default router
