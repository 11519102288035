import { render, staticRenderFns } from "./MakeGroupVideoCall.vue?vue&type=template&id=01b8f9c3&scoped=true"
import script from "./MakeGroupVideoCall.vue?vue&type=script&lang=js"
export * from "./MakeGroupVideoCall.vue?vue&type=script&lang=js"
import style0 from "./MakeGroupVideoCall.vue?vue&type=style&index=0&id=01b8f9c3&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "01b8f9c3",
  null
  
)

export default component.exports