<template>
  <div v-show="scY > 600">
    <div v-if="isDesktop" class="fixed-scroll-footer">
      <div class="footer-promotion fadeInUp">
        <span @click="isDesktop = false" ><i class="el-icon-close"></i></span>
        <div class="container">
          <el-row :gutter="24">
            <el-col :xs="24" :md="24" :lg="12">
              <div class="content-promotion">
                <h1>{{ $t(`Layout['download_mosappOn']`) }}</h1>
                <!-- <p>{{ $t(`Layout['DownloadMosAppNowtoSecure']`) }}</p> -->
              </div>
            </el-col>
            <el-col :xs="24" :md="24" :lg="12">
              <div class="download">
                  <!-- <p style="font-size: 20px; margin-bottom: 20px; font-weight: 500;">{{ $t(`Home['download_mosappOn']`) }}</p> -->
                  <ul>
                    <li v-for="item in downloadList" :key="item.value">
                      <img :src="getAssetsFile(item.src + '.png')" alt="" class="down-img" @click="clickHandle(item)">
                    </li>
                  </ul>
                </div>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>
    <div v-else class="fixed-scroll-footer" id="mobile-footer-head" >
      <div v-if="isDeviceType === 'android'" class="mobile-footer">
        <div class="mobile-icon-close">
          <span @click="closeDownload"><i class="el-icon-close"></i></span>
          <img :src="getAssetsFile('MosApp-logo@2x.png')" alt="mos-logo">
        </div>
        <a @click="clickHandleMobile('app-android','https://cdn-oss.mos.me/public/mosapp/app/MosApp-Android-Latest.apk','Android')">{{ $t(`Layout['download']`) }}</a>
      </div>
      <div v-else-if="isDeviceType === 'ios'" class="mobile-footer">
        <div class="mobile-icon-close">
          <span @click="closeDownload"><i class="el-icon-close"></i></span>
          <img :src="getAssetsFile('MosApp-logo@2x.png')" alt="mos-logo">
        </div>
        <a @click="clickHandleMobile('app-store','https://apps.apple.com/cn/app/mosapp/id6446265600','IOS')">{{ $t(`Layout['install']`) }}</a>
      </div>
      <div v-else></div>
    </div>
  </div>
</template>
<script>
import { getAssetsFile, downloadFile } from '@/utils'
export default {
  created () {
    window.addEventListener('scroll', this.handleScroll)
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll)
  },
  data() {
    return {
      scTimer: 0,
      scY: 0,
      isDownload: true,
      getAssetsFile,
      downloadFile,
      downloadList: [
        {
          type: 'IOS',
          value: 'appstore',
          url: 'https://apps.apple.com/cn/app/mosapp/id6446265600',
          src: 'app-store'
        },
        {
          type: 'Android',
          value: 'appandroid',
          url: 'https://cdn-oss.mos.me/public/mosapp/app/MosApp-Android-Latest.apk',
          src: 'app-android'
        },
        {
          type: 'Windows',
          value: 'windows',
          url: 'https://cdn-oss.mos.me/public/mosapp/pc/MosApp-Windows-Latest.exe',
          src: 'win-os'
        },
        {
          type: 'MacOS',
          value: 'mac',
          url: 'https://cdn-oss.mos.me/public/mosapp/pc/MosApp-MacOS-Latest.dmg',
          src: 'mac-os'
        }
      ],
      isDesktop: true,
      isDeviceType: 'desktop',
      isDesktopData: ''
    }
  },
  watch: {
    isDesktopData: {
      handler: function(newVal, oldVal) {
        const os = this.getType()
        if (os === 'Android') {
          this.isDeviceType = 'android'
          this.isDesktop = false
        } else if (os === 'IOS') {
          this.isDeviceType = 'ios'
          this.isDesktop = false
        } else {
          this.isDeviceType = 'desktop'
          this.isDesktop = true
        }
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    // handleScroll() {
    //   console.log('onScroll')
    //   document.getElementById('footerDownload').style.display = 'block'
    // },
    handleScroll: function () {
      if (this.scTimer) return
      this.scTimer = setTimeout(() => {
        this.scY = window.scrollY
        clearTimeout(this.scTimer)
        this.scTimer = 0
      }, 100)
    },
    mounted() {
      window.addEventListener('scroll', this.handleScroll)
    },
    clickHandle (item) {
      const os = this.getType()
      if (item.value === 'appstore') {
        if (os === 'IOS') {
          window.open('itms-apps://apps.apple.com/cn/app/mosapp/id6446265600')
        } else {
          window.open(item.url)
        }
      } else {
        const fileName = item.url.substring(item.url.lastIndexOf('/') + 1)
        if (os === 'IOS' && (item.value === 'windows' || item.value === 'mac')) {
          // showDialog({ message: t('home.tips') })
        } else {
          downloadFile(item.url, fileName)
        }
      }
    },
    getType () {
      // 判断设备是PC端还是移动端: false PC端 | true 移动端
      const isMobile = () => {
        return !!navigator.userAgent.match(/(iPhone|iPod|Android|ios|iOS|WebOS|Windows Phone|Phone)/i)
      }
      const isMacOs = () => {
        return /macintosh|mac os x/i.test(navigator.userAgent)
      }
      const isWindows = () => {
        const agent = navigator.userAgent.toLowerCase()
        if (agent.indexOf('win32') >= 0 || agent.indexOf('wow32') >= 0) {
          return true
        }
        if (agent.indexOf('win64') >= 0 || agent.indexOf('wow64') >= 0) {
          return true
        }
        return false
      }
      // 判断设备时安卓还是IOS
      const isAndroid = () => {
        return /android/i.test(navigator.userAgent.toLowerCase())
      }
      const isIOS = () => {
        const reg = /iPhone|iPad|iOS|Macintosh/i
        return reg.test(navigator.userAgent.toLowerCase())
      }
      if (isMobile()) {
        if (isAndroid()) return 'Android'
        if (isIOS()) return 'IOS'
      } else {
        if (isMacOs()) return 'MacOS'
        if (isWindows()) return 'Windows'
      }
    },
    clickHandleMobile (item, url, os) {
      if (item === 'appstore') {
        if (os === 'IOS') {
          window.open('itms-apps://apps.apple.com/cn/app/mosapp/id6446265600')
        } else {
          window.open(url)
        }
      } else {
        const fileName = url.substring(url.lastIndexOf('/') + 1)
        if (os === 'IOS' && (item === 'windows' || item === 'mac')) {
          // showDialog({ message: t('home.tips') })
        } else {
          downloadFile(url, fileName)
        }
      }
    },
    closeDownload () {
      this.isDesktop = false
      this.isDeviceType = 'windows'
    }
  }
}

</script>
<style lang="less" scoped>
#footerDownload {
  display: none;
}
.fixed-scroll-footer {
  position: relative;
  .fadeInUp{animation:fadeInUp 1s ease backwards;}
  @keyframes fadeInUp{
    0%{transform:translate(0px, 100px); opacity: 0;}
    100%{transform:translate(0px, 0); opacity: 1;}
  }
}
  .footer-promotion {
    padding: 20px 0px 30px 0px;
    position: fixed;
    bottom: 0;
    overflow: hidden;
    z-index: 1;
    width: 100%;
    background-color: #fff;
    box-shadow: 7px 11px 25px -4px gray;
    // height: 65px;
    display: flex;
    align-items: center;
    span {
        position: absolute;
        right: 20px;
        top: 0;
        font-size: 25px;
        cursor: pointer;
        z-index: 9;
    }
    .content-promotion {
      width: 415px;
      margin-top: 10px;
      h1 {
        font-size: 24px;
      }
      p {
        font-size: 16px;
        color: #595959;
        margin-top: 5px;
      }
    }
    .download {
      text-align: center;
      @media screen and (max-width: 992px) {
        margin-top: 20px;
      }
      ul {
        display: flex;
        flex-wrap: wrap;
        // margin-top: 10px;
        justify-content: space-between;
        li {
          margin: 0 15px 0 0;
          img {
            width: 180px;
            height: 50px;
            cursor: pointer;
          }
        }
        @media screen and (max-width: 1625px) {
          li {
            margin: 0 8px 15px;
            img {
              width: 130px;
              height: 41.6px;
            }
          }
        }
      }
    }
  }
  .mobile-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #DAEAF9;
    padding: 15px 0;
  }
  .mobile-icon-close span {
    margin-left: 15px;
    background-color: #fff;
    border-radius: 50%;
    height: 20px;
    width: 20px;
    line-height: 22px;
    text-align: center;
    font-size: 12px;
    display: inline-block;
  }
  .mobile-footer a {
    background-color: #3370ff;
    padding: 5px 20px;
    border-radius: 20px;
    color: #fff;
    margin-right: 15px;
  }
  .mobile-footer img {
    height: 30px;
    margin-left: 15px;
  }
</style>
