<!--
 * @Author: Sky
 * @Date: 2025-01-09 10:15:03
 * @LastEditors: Sky
 * @LastEditTime: 2025-01-24 18:07:23
 * @Description: 各种协议文件
-->
<template>
  <div class="container">
    <div class="container-text">{{ content }}</div>
  </div>
</template>
<script>
import UserPrivacyPolicyZh from '@/assets/agreement/UserPrivacyPolicy_zh.txt'
import UserPrivacyPolicyEn from '@/assets/agreement/UserPrivacyPolicy_en.txt'
import UserTermServiceZh from '@/assets/agreement/UserTermService_zh.txt'
import UserTermServiceEn from '@/assets/agreement/UserTermService_en.txt'
import VIPServiceZh from '@/assets/agreement/VIPService_zh.txt'
import VIPServiceEn from '@/assets/agreement/VIPService_en.txt'
import VIPContinuousZh from '@/assets/agreement/VIPContinuous_zh.txt'
import VIPContinuousEn from '@/assets/agreement/VIPContinuous_en.txt'
import ChildSafetyPolicyZh from '@/assets/agreement/ChildSafetyPolicy_zh.txt'
import ChildSafetyPolicyEn from '@/assets/agreement/ChildSafetyPolicy_en.txt'
export default {
  name: 'AgreementTemplate',
  data() {
    return {
      content: ''
    }
  },
  watch: {
    $route: {
      handler(val) {
        let lang
        if (val.params.lang) {
          lang = val.params.lang === 'zh' ? 'zh' : 'en'
        } else {
          lang = this.$i18n.locale
        }
        this.getContent(lang)
      },
      immediate: true
    },
    '$i18n.locale': {
      handler(val) {
        if (this.$route.params.lang) return
        this.getContent(val)
      },
      immediate: true
    }
  },
  methods: {
    getContent(lang) {
      switch (this.$route.name) {
        case 'UserPrivacyPolicy':
          if (lang === 'zh') {
            this.content = UserPrivacyPolicyZh
          } else {
            this.content = UserPrivacyPolicyEn
          }
          break
        case 'UserTermService':
          if (lang === 'zh') {
            this.content = UserTermServiceZh
          } else {
            this.content = UserTermServiceEn
          }
          break
        case 'VIPService':
          if (lang === 'zh') {
            this.content = VIPServiceZh
          } else {
            this.content = VIPServiceEn
          }
          break
        case 'VIPContinuous':
          if (lang === 'zh') {
            this.content = VIPContinuousZh
          } else {
            this.content = VIPContinuousEn
          }
          break
        case 'ChildSafetyPolicy':
          if (lang === 'zh') {
            this.content = ChildSafetyPolicyZh
          } else {
            this.content = ChildSafetyPolicyEn
          }
          break
        default:
          break
      }
    }
  }
}
</script>
<style lang="less" scoped>
.container-text {
  font-size: 16px;
  padding: 40px 0 80px;
  white-space: pre-wrap;
  text-align: justify;
}
</style>
