// 获取i18n的语言
const list = [
  {
    shortName: 'CN',
    tel: '86',
    name: '中国',
    'zh-CN': '中国',
    en: 'China mainland',
    'en-US': 'China mainland',
    'zh-HK': '中國',
    'zh-TW': '中國',
    'km-KH': 'ចិនដីគោក',
    'vi-VN': 'Trung Quốc đại lục',
    'th-TH': 'จีนแผ่นดินใหญ่',
    'ms-MY': 'tanah besar China',
    'ja-JP': '中国本土',
    'ko-KR': '중국 본토',
    'id-ID': 'daratan Tiongkok',
    'lo-LA': 'ຈີນແຜ່ນດິນໃຫຍ່',
    'hi-IN': 'चीन (महाद्वीप'
  },
  {
    shortName: 'IN',
    tel: '91',
    name: '印度',
    'zh-CN': '印度',
    en: 'India',
    'en-US': 'India',
    'zh-HK': '印度',
    'zh-TW': '印度',
    'km-KH': 'ប្រទេសឥណ្ឌា',
    'vi-VN': 'Ấn Độ',
    'th-TH': 'อินเดีย',
    'ms-MY': 'India',
    'ja-JP': 'インド',
    'ko-KR': '인도',
    'id-ID': 'India',
    'lo-LA': 'ປະເທດອິນເດຍ',
    'hi-IN': 'भारत'
  },
  {
    shortName: 'ID',
    tel: '62',
    name: '印度尼西亚',
    'zh-CN': '印度尼西亚',
    en: 'Indonesia',
    'en-US': 'Indonesia',
    'zh-HK': '印尼',
    'zh-TW': '印尼',
    'km-KH': 'ប្រទេសឥណ្ឌូនេស៊ី',
    'vi-VN': 'Indonesia',
    'th-TH': 'อินโดนีเซีย',
    'ms-MY': 'Indonesia',
    'ja-JP': 'インドネシア',
    'ko-KR': '인도네시아 공화국',
    'id-ID': 'Indonesia',
    'lo-LA': 'ອິນໂດເນເຊຍ',
    'hi-IN': 'इंडोनेशिया'
  },
  {
    shortName: 'SG',
    tel: '65',
    name: '新加坡',
    'zh-CN': '新加坡',
    en: 'Singapore',
    'en-US': 'Singapore',
    'zh-HK': '新加坡',
    'zh-TW': '新加坡',
    'km-KH': 'សិង្ហបុរី',
    'vi-VN': 'Singapore',
    'th-TH': 'สิงคโปร์',
    'ms-MY': 'Singapura',
    'ja-JP': 'シンガポール',
    'ko-KR': '싱가포르',
    'id-ID': 'Singapura',
    'lo-LA': 'ສິງກະໂປ',
    'hi-IN': 'सिंगापुर'
  },
  {
    shortName: 'KH',
    tel: '855',
    name: '柬埔寨',
    'zh-CN': '柬埔寨',
    en: 'Cambodia',
    'en-US': 'Cambodia',
    'zh-HK': '柬埔寨',
    'zh-TW': '柬埔寨',
    'km-KH': 'ប្រទេសកម្ពុជា',
    'vi-VN': 'Campuchia',
    'th-TH': 'กัมพูชา',
    'ms-MY': 'Kemboja',
    'ja-JP': 'カンボジア',
    'ko-KR': '캄보디아',
    'id-ID': 'Kamboja',
    'lo-LA': 'ກຳປູເຈຍ',
    'hi-IN': 'कंबोडिया'
  },
  {
    shortName: 'VN',
    tel: '84',
    name: '越南',
    'zh-CN': '越南',
    en: 'Vietnam',
    'en-US': 'Vietnam',
    'zh-HK': '越南',
    'zh-TW': '越南',
    'km-KH': 'ប្រទេសវៀតណាម',
    'vi-VN': 'Việt Nam',
    'th-TH': 'เวียดนาม',
    'ms-MY': 'Vietnam',
    'ja-JP': 'ベトナム',
    'ko-KR': '베트남',
    'id-ID': 'Vietnam',
    'lo-LA': 'ຫວຽດນາມ',
    'hi-IN': 'वियतनाम'
  },
  {
    shortName: 'MY',
    tel: '60',
    name: '马来西亚',
    'zh-CN': '马来西亚',
    en: 'Malaysia',
    'en-US': 'Malaysia',
    'zh-HK': '馬來西亞',
    'zh-TW': '馬來西亞',
    'km-KH': 'ម៉ាឡេស៊ី',
    'vi-VN': 'Malaysia',
    'th-TH': 'มาเลเซีย',
    'ms-MY': 'Malaysia',
    'ja-JP': 'マレーシア',
    'ko-KR': '말레이시아',
    'id-ID': 'Malaysia',
    'lo-LA': 'ມາເລເຊຍ',
    'hi-IN': 'मलेशिया'
  },
  {
    shortName: 'AC',
    tel: '247',
    name: '阿森松岛',
    'zh-CN': '阿森松岛',
    en: 'Ascension Island',
    'en-US': 'Ascension Island',
    'zh-HK': '阿森松島',
    'zh-TW': '阿森松島',
    'km-KH': 'កោះ Ascension',
    'vi-VN': 'Đảo Thăng Thiên',
    'th-TH': 'เกาะแอสเซนชัน',
    'ms-MY': 'Pulau Ascension',
    'ja-JP': 'アセンション島',
    'ko-KR': '승천섬',
    'id-ID': 'Pulau Kenaikan',
    'lo-LA': 'ເກາະ Ascension',
    'hi-IN': 'असेंशन द्वीप'
  },
  {
    shortName: 'AD',
    tel: '376',
    name: '安道尔',
    'zh-CN': '安道尔',
    en: 'Andorra',
    'en-US': 'Andorra',
    'zh-HK': '安道爾',
    'zh-TW': '安道爾',
    'km-KH': 'អង់ដូរ៉ា',
    'vi-VN': 'Andorra',
    'th-TH': 'อันดอร์รา',
    'ms-MY': 'Andorra',
    'ja-JP': 'アンドラ',
    'ko-KR': '안도라',
    'id-ID': 'Andorra',
    'lo-LA': 'ອັນດໍຣາ',
    'hi-IN': 'एंडोरा'
  },
  {
    shortName: 'AE',
    tel: '971',
    name: '阿拉伯联合酋长国',
    'zh-CN': '阿拉伯联合酋长国',
    en: 'United Arab Emirates',
    'en-US': 'United Arab Emirates',
    'zh-HK': '阿拉伯聯合大公國',
    'zh-TW': '阿拉伯聯合大公國',
    'km-KH': 'អេមីរ៉ាតអារ៉ាប់រួម',
    'vi-VN': 'Các tiểu vương quốc Ả Rập thống nhất',
    'th-TH': 'สหรัฐอาหรับเอมิเรตส์',
    'ms-MY': 'Emiriah Arab Bersatu',
    'ja-JP': 'アラブ首長国連邦',
    'ko-KR': '아랍에미리트',
    'id-ID': 'Uni Emirat Arab',
    'lo-LA': 'ສະຫະລັດອາຣັບເອມີເຣດ',
    'hi-IN': 'संयुक्त अरब अमीरात'
  },
  {
    shortName: 'AF',
    tel: '93',
    name: '阿富汗',
    'zh-CN': '阿富汗',
    en: 'Afghanistan',
    'en-US': 'Afghanistan',
    'zh-HK': '阿富汗',
    'zh-TW': '阿富汗',
    'km-KH': 'អាហ្វហ្គានីស្ថាន',
    'vi-VN': 'Afghanistan',
    'th-TH': 'อัฟกานิสถาน',
    'ms-MY': 'Afghanistan',
    'ja-JP': 'アフガニスタン',
    'ko-KR': '아프가니스탄',
    'id-ID': 'Afganistan',
    'lo-LA': 'ອັຟການິສຖານ',
    'hi-IN': 'अफ़ग़ानिस्तान'
  },
  {
    shortName: 'AG',
    tel: '1268',
    name: '安提瓜和巴布达',
    'zh-CN': '安提瓜和巴布达',
    en: 'Antigua & Barbuda',
    'en-US': 'Antigua & Barbuda',
    'zh-HK': '安地卡及巴布達',
    'zh-TW': '安地卡及巴布達',
    'km-KH': 'អង់ទីហ្គា និងបាប៊ូដា',
    'vi-VN': 'Antigua & Barbuda',
    'th-TH': 'แอนติกาและบาร์บูดา',
    'ms-MY': 'Antigua & Barbuda',
    'ja-JP': 'アンティグア・バーブーダ',
    'ko-KR': '앤티가 바부다',
    'id-ID': 'Antigua & Barbuda',
    'lo-LA': 'Antigua & Barbuda',
    'hi-IN': 'एंटीगुआ और बारबुडा'
  },
  {
    shortName: 'AI',
    tel: '1264',
    name: '安圭拉',
    'zh-CN': '安圭拉',
    en: 'Anguilla',
    'en-US': 'Anguilla',
    'zh-HK': '安圭拉',
    'zh-TW': '安圭拉',
    'km-KH': 'អង់ហ្គីឡា',
    'vi-VN': 'Anguilla',
    'th-TH': 'แองกวิลลา',
    'ms-MY': 'Anguilla',
    'ja-JP': 'アンギラ',
    'ko-KR': '앵귈라',
    'id-ID': 'Angola',
    'lo-LA': 'Anguilla',
    'hi-IN': 'एंगुइला'
  },
  {
    shortName: 'AL',
    tel: '355',
    name: '阿尔巴尼亚',
    'zh-CN': '阿尔巴尼亚',
    en: 'Albania',
    'en-US': 'Albania',
    'zh-HK': '阿爾巴尼亞',
    'zh-TW': '阿爾巴尼亞',
    'km-KH': 'អាល់បានី',
    'vi-VN': 'Albania',
    'th-TH': 'แอลเบเนีย',
    'ms-MY': 'Albania',
    'ja-JP': 'アルバニア',
    'ko-KR': '알바니아',
    'id-ID': 'Albania',
    'lo-LA': 'ອານບານີ',
    'hi-IN': 'अल्बानिया'
  },
  {
    shortName: 'AM',
    tel: '374',
    name: '亚美尼亚',
    'zh-CN': '亚美尼亚',
    en: 'Armenia',
    'en-US': 'Armenia',
    'zh-HK': '亞美尼亞',
    'zh-TW': '亞美尼亞',
    'km-KH': 'អាមេនី',
    'vi-VN': 'Armenia',
    'th-TH': 'อาร์เมเนีย',
    'ms-MY': 'Armenia',
    'ja-JP': 'アルメニア',
    'ko-KR': '아르메니아',
    'id-ID': 'Armenia',
    'lo-LA': 'ອາເມເນຍ',
    'hi-IN': 'आर्मीनिया'
  },
  {
    shortName: 'AO',
    tel: '244',
    name: '安哥拉',
    'zh-CN': '安哥拉',
    en: 'Angola',
    'en-US': 'Angola',
    'zh-HK': '安哥拉',
    'zh-TW': '安哥拉',
    'km-KH': 'អង់ហ្គោឡា',
    'vi-VN': 'Ăng-gô-la',
    'th-TH': 'แองโกลา',
    'ms-MY': 'Angola',
    'ja-JP': 'アンゴラ',
    'ko-KR': '앙골라',
    'id-ID': 'Angola',
    'lo-LA': 'ອັງໂກລາ',
    'hi-IN': 'अंगोला'
  },
  {
    shortName: 'AQ',
    tel: '672',
    name: '南极洲',
    'zh-CN': '南极洲',
    en: 'Antarctica',
    'en-US': 'Antarctica',
    'zh-HK': '南極洲',
    'zh-TW': '南極洲',
    'km-KH': 'អង់តាក់ទិក',
    'vi-VN': 'Nam Cực',
    'th-TH': 'แอนตาร์กติกา',
    'ms-MY': 'Antartika',
    'ja-JP': '南極大陸',
    'ko-KR': '남극 대륙',
    'id-ID': 'Antartika',
    'lo-LA': 'ແອນຕາກຕິກ',
    'hi-IN': 'अंटार्कटिका'
  },
  {
    shortName: 'AR',
    tel: '54',
    name: '阿根廷',
    'zh-CN': '阿根廷',
    en: 'Argentina',
    'en-US': 'Argentina',
    'zh-HK': '阿根廷',
    'zh-TW': '阿根廷',
    'km-KH': 'អាហ្សង់ទីន',
    'vi-VN': 'Argentina',
    'th-TH': 'อาร์เจนตินา',
    'ms-MY': 'Argentina',
    'ja-JP': 'アルゼンチン',
    'ko-KR': '아르헨티나',
    'id-ID': 'Argentina',
    'lo-LA': 'ອາເຈນຕິນາ',
    'hi-IN': 'अर्जेंटीना'
  },
  {
    shortName: 'AS',
    tel: '1684',
    name: '美属萨摩亚',
    'zh-CN': '美属萨摩亚',
    en: 'American Samoa',
    'en-US': 'American Samoa',
    'zh-HK': '美屬薩摩亞',
    'zh-TW': '美屬薩摩亞',
    'km-KH': 'សាម័រអាមេរិក',
    'vi-VN': 'Samoa thuộc Mỹ',
    'th-TH': 'อเมริกันซามัว',
    'ms-MY': 'Samoa Amerika',
    'ja-JP': 'アメリカ領サモア',
    'ko-KR': '아메리칸사모아',
    'id-ID': 'Samoa Amerika',
    'lo-LA': 'ອາ​ເມ​ລິ​ກາ Samoa',
    'hi-IN': 'अमेरिकी समोआ'
  },
  {
    shortName: 'AT',
    tel: '43',
    name: '奥地利',
    'zh-CN': '奥地利',
    en: 'Austria',
    'en-US': 'Austria',
    'zh-HK': '奧地利',
    'zh-TW': '奧地利',
    'km-KH': 'អូទ្រីស',
    'vi-VN': 'Áo',
    'th-TH': 'ออสเตรีย',
    'ms-MY': 'Austria',
    'ja-JP': 'オーストリア',
    'ko-KR': '오스트리아',
    'id-ID': 'Austria',
    'lo-LA': 'ອອສເຕຣຍ',
    'hi-IN': 'ऑस्ट्रिया'
  },
  {
    shortName: 'AU',
    tel: '61',
    name: '澳大利亚',
    'zh-CN': '澳大利亚',
    en: 'Australia',
    'en-US': 'Australia',
    'zh-HK': '澳洲',
    'zh-TW': '澳洲',
    'km-KH': 'អូស្ត្រាលី',
    'vi-VN': 'Úc',
    'th-TH': 'ออสเตรเลีย',
    'ms-MY': 'Australia',
    'ja-JP': 'オーストラリア',
    'ko-KR': '호주',
    'id-ID': 'Australia',
    'lo-LA': 'ອອສເຕຣເລຍ',
    'hi-IN': 'ऑस्ट्रेलिया'
  },
  {
    shortName: 'AW',
    tel: '297',
    name: '阿鲁巴',
    'zh-CN': '阿鲁巴',
    en: 'Aruba',
    'en-US': 'Aruba',
    'zh-HK': '阿魯巴',
    'zh-TW': '阿魯巴',
    'km-KH': 'អារូបា',
    'vi-VN': 'Aruba',
    'th-TH': 'อารูบา',
    'ms-MY': 'Aruba',
    'ja-JP': 'アルバ',
    'ko-KR': '아루바',
    'id-ID': 'Aruba',
    'lo-LA': 'ອາຣູບາ',
    'hi-IN': 'अरूबा'
  },
  {
    shortName: 'AX',
    tel: '358',
    name: '奥兰群岛',
    'zh-CN': '奥兰群岛',
    en: 'Aland Islands',
    'en-US': 'Aland Islands',
    'zh-HK': '奧蘭群島',
    'zh-TW': '奧蘭群島',
    'km-KH': 'កោះ Aland',
    'vi-VN': 'Quần đảo Åland',
    'th-TH': 'หมู่เกาะโอลันด์',
    'ms-MY': 'Kepulauan Åland',
    'ja-JP': 'オーランド諸島',
    'ko-KR': '올란드 제도',
    'id-ID': 'Kepulauan Aland',
    'lo-LA': 'ໝູ່ເກາະເອແລນ',
    'hi-IN': 'ऑलैंड द्वीप समूह'
  },
  {
    shortName: 'AZ',
    tel: '994',
    name: '阿塞拜疆',
    'zh-CN': '阿塞拜疆',
    en: 'Azerbaijan',
    'en-US': 'Azerbaijan',
    'zh-HK': '亞塞拜然',
    'zh-TW': '亞塞拜然',
    'km-KH': 'អាស៊ែបៃហ្សង់',
    'vi-VN': 'Azerbaijan',
    'th-TH': 'อาเซอร์ไบจาน',
    'ms-MY': 'Azerbaijan',
    'ja-JP': 'アゼルバイジャン',
    'ko-KR': '아제르바이잔',
    'id-ID': 'Azerbaijan',
    'lo-LA': 'ອາເຊີໄບຈັນ',
    'hi-IN': 'आज़रबाइजान'
  },
  {
    shortName: 'BA',
    tel: '387',
    name: '波斯尼亚和黑塞哥维那',
    'zh-CN': '波斯尼亚和黑塞哥维那',
    en: 'Bosnia & Herzegovina',
    'en-US': 'Bosnia & Herzegovina',
    'zh-HK': '波士尼亞與赫塞哥維納',
    'zh-TW': '波士尼亞與赫塞哥維納',
    'km-KH': 'បូស្នៀ និងហឺហ្សេហ្គោវីណា',
    'vi-VN': 'Bosnia & Herzegovina',
    'th-TH': 'บอสเนียและเฮอร์เซโกวีนา',
    'ms-MY': 'Bosnia & Herzegovina',
    'ja-JP': 'ボスニア・ヘルツェゴビナ',
    'ko-KR': '보스니아 헤르체고비나',
    'id-ID': 'Bosnia & Herzegovina',
    'lo-LA': 'ບອສເນຍ ແລະ ເຮີເຊໂກວິນາ',
    'hi-IN': 'बोस्निया और हर्जेगोविना'
  },
  {
    shortName: 'BB',
    tel: '1246',
    name: '巴巴多斯',
    'zh-CN': '巴巴多斯',
    en: 'Barbados',
    'en-US': 'Barbados',
    'zh-HK': '巴貝多',
    'zh-TW': '巴貝多',
    'km-KH': 'បាបាដូស',
    'vi-VN': 'Barbados',
    'th-TH': 'บาร์เบโดส',
    'ms-MY': 'Barbados',
    'ja-JP': 'バルバドス',
    'ko-KR': '바베이도스',
    'id-ID': 'Barbados',
    'lo-LA': 'ບາບາໂດສ',
    'hi-IN': 'बारबाडोस'
  },
  {
    shortName: 'BD',
    tel: '880',
    name: '孟加拉国',
    'zh-CN': '孟加拉国',
    en: 'Bangladesh',
    'en-US': 'Bangladesh',
    'zh-HK': '孟加拉',
    'zh-TW': '孟加拉',
    'km-KH': 'បង់ក្លាដែស',
    'vi-VN': 'Bangladesh',
    'th-TH': 'บังคลาเทศ',
    'ms-MY': 'Bangladesh',
    'ja-JP': 'バングラデシュ',
    'ko-KR': '방글라데시',
    'id-ID': 'Bangladesh',
    'lo-LA': 'ບັງກະລາເທດ',
    'hi-IN': 'बांग्लादेश'
  },
  {
    shortName: 'BE',
    tel: '32',
    name: '比利时',
    'zh-CN': '比利时',
    en: 'Belgium',
    'en-US': 'Belgium',
    'zh-HK': '比利時',
    'zh-TW': '比利時',
    'km-KH': 'បែលហ្សិក',
    'vi-VN': 'nước Bỉ',
    'th-TH': 'เบลเยียม',
    'ms-MY': 'Belgium',
    'ja-JP': 'ベルギー',
    'ko-KR': '벨기에',
    'id-ID': 'Belgia',
    'lo-LA': 'ປະເທດແບນຊິກ',
    'hi-IN': 'बेल्जियम'
  },
  {
    shortName: 'BF',
    tel: '226',
    name: '布基纳法索',
    'zh-CN': '布基纳法索',
    en: 'Burkina Faso',
    'en-US': 'Burkina Faso',
    'zh-HK': '布吉納法索',
    'zh-TW': '布吉納法索',
    'km-KH': 'ប៊ូគីណាហ្វាសូ',
    'vi-VN': 'Burkina Faso',
    'th-TH': 'บูร์กินาฟาโซ',
    'ms-MY': 'Burkina Faso',
    'ja-JP': 'ブルキナファソ',
    'ko-KR': '부르키나파소',
    'id-ID': 'Burkina Faso',
    'lo-LA': 'ບູກີນາຟາໂຊ',
    'hi-IN': 'बुर्किना फासो'
  },
  {
    shortName: 'BG',
    tel: '359',
    name: '保加利亚',
    'zh-CN': '保加利亚',
    en: 'Bulgaria',
    'en-US': 'Bulgaria',
    'zh-HK': '保加利亞',
    'zh-TW': '保加利亞',
    'km-KH': 'ប៊ុលហ្គារី',
    'vi-VN': 'Bulgaria',
    'th-TH': 'บัลแกเรีย',
    'ms-MY': 'Bulgaria',
    'ja-JP': 'ブルガリア',
    'ko-KR': '불가리아',
    'id-ID': 'Bulgaria',
    'lo-LA': 'ບຸນກາຣີ',
    'hi-IN': 'बुल्गारिया'
  },
  {
    shortName: 'BH',
    tel: '973',
    name: '巴林',
    'zh-CN': '巴林',
    en: 'Bahrain',
    'en-US': 'Bahrain',
    'zh-HK': '巴林',
    'zh-TW': '巴林',
    'km-KH': 'បារ៉ែន',
    'vi-VN': 'Bahrain',
    'th-TH': 'บาห์เรน',
    'ms-MY': 'Bahrain',
    'ja-JP': 'バーレーン',
    'ko-KR': '바레인',
    'id-ID': 'Bahrain',
    'lo-LA': 'ບາເຣນ',
    'hi-IN': 'बहरीन'
  },
  {
    shortName: 'BI',
    tel: '257',
    name: '布隆迪',
    'zh-CN': '布隆迪',
    en: 'Burundi',
    'en-US': 'Burundi',
    'zh-HK': '蒲隆地',
    'zh-TW': '蒲隆地',
    'km-KH': 'ប៊ូរុនឌី',
    'vi-VN': 'Burundi',
    'th-TH': 'บุรุนดี',
    'ms-MY': 'Burundi',
    'ja-JP': 'ブルンジ',
    'ko-KR': '부룬디',
    'id-ID': 'Burundi',
    'lo-LA': 'ບູຣຸນດີ',
    'hi-IN': 'बुस्र्न्दी'
  },
  {
    shortName: 'BJ',
    tel: '229',
    name: '贝宁',
    'zh-CN': '贝宁',
    en: 'Benin',
    'en-US': 'Benin',
    'zh-HK': '貝南',
    'zh-TW': '貝南',
    'km-KH': 'បេនីន',
    'vi-VN': 'Bénin',
    'th-TH': 'เบนิน',
    'ms-MY': 'Benin',
    'ja-JP': 'ベナン',
    'ko-KR': '베냉',
    'id-ID': 'Benin',
    'lo-LA': 'ເບນິນ',
    'hi-IN': 'बेनिन'
  },
  {
    shortName: 'BL',
    tel: '590',
    name: '圣巴泰勒米',
    'zh-CN': '圣巴泰勒米',
    en: 'St. Barthélemy',
    'en-US': 'St. Barthélemy',
    'zh-HK': '聖巴泰勒米',
    'zh-TW': '聖巴泰勒米',
    'km-KH': 'សាំងបាថេលេមី',
    'vi-VN': 'Thánh Barthélemy',
    'th-TH': 'เซนต์บาร์เธเลมี',
    'ms-MY': 'St. Barthélemy',
    'ja-JP': 'サン・バルテルミー島',
    'ko-KR': '생바르텔레미',
    'id-ID': 'St.Barthélemy',
    'lo-LA': 'ເຊນ ບາເທເລມີ',
    'hi-IN': 'सेंट बार्थेलेमी'
  },
  {
    shortName: 'BM',
    tel: '1441',
    name: '百慕大',
    'zh-CN': '百慕大',
    en: 'Bermuda',
    'en-US': 'Bermuda',
    'zh-HK': '百慕達',
    'zh-TW': '百慕達',
    'km-KH': 'ប៊ឺមូដា',
    'vi-VN': 'Bermuda',
    'th-TH': 'เบอร์มิวดา',
    'ms-MY': 'Bermuda',
    'ja-JP': 'バミューダ',
    'ko-KR': '버뮤다',
    'id-ID': 'bermuda',
    'lo-LA': 'ເບີມິວດາ',
    'hi-IN': 'बरमूडा'
  },
  {
    shortName: 'BN',
    tel: '673',
    name: '文莱',
    'zh-CN': '文莱',
    en: 'Brunei',
    'en-US': 'Brunei',
    'zh-HK': '汶萊',
    'zh-TW': '汶萊',
    'km-KH': 'ប្រ៊ុយណេ',
    'vi-VN': 'Brunei',
    'th-TH': 'บรูไน',
    'ms-MY': 'Brunei',
    'ja-JP': 'ブルネイ',
    'ko-KR': '브루나이',
    'id-ID': 'Brunei',
    'lo-LA': 'ບຣູໄນ',
    'hi-IN': 'ब्रुनेई'
  },
  {
    shortName: 'BO',
    tel: '591',
    name: '玻利维亚',
    'zh-CN': '玻利维亚',
    en: 'Bolivia',
    'en-US': 'Bolivia',
    'zh-HK': '玻利維亞',
    'zh-TW': '玻利維亞',
    'km-KH': 'បូលីវា',
    'vi-VN': 'Bôlivia',
    'th-TH': 'โบลิเวีย',
    'ms-MY': 'Bolivia',
    'ja-JP': 'ボリビア',
    'ko-KR': '볼리비아',
    'id-ID': 'Bolivia',
    'lo-LA': 'ໂບລິເວຍ',
    'hi-IN': 'बोलीविया'
  },
  {
    shortName: 'BQ',
    tel: '599',
    name: '荷属加勒比区',
    'zh-CN': '荷属加勒比区',
    en: 'Caribbean Netherlands',
    'en-US': 'Caribbean Netherlands',
    'zh-HK': '荷屬加勒比海區',
    'zh-TW': '荷屬加勒比海區',
    'km-KH': 'ការ៉ាប៊ីន ហូឡង់',
    'vi-VN': 'Caribe Hà Lan',
    'th-TH': 'เนเธอร์แลนด์แคริบเบียน',
    'ms-MY': 'Caribbean Belanda',
    'ja-JP': 'カリブ海オランダ',
    'ko-KR': '카리브해 네덜란드',
    'id-ID': 'Karibia Belanda',
    'lo-LA': 'Caribbean ເນເທີແລນ',
    'hi-IN': 'कैरेबियन नीदरलैंड'
  },
  {
    shortName: 'BR',
    tel: '55',
    name: '巴西',
    'zh-CN': '巴西',
    en: 'Brazil',
    'en-US': 'Brazil',
    'zh-HK': '巴西',
    'zh-TW': '巴西',
    'km-KH': 'ប្រេស៊ីល',
    'vi-VN': 'Brazil',
    'th-TH': 'บราซิล',
    'ms-MY': 'Brazil',
    'ja-JP': 'ブラジル',
    'ko-KR': '브라질',
    'id-ID': 'Brazil',
    'lo-LA': 'ບຣາຊິນ',
    'hi-IN': 'ब्राज़िल'
  },
  {
    shortName: 'BS',
    tel: '1242',
    name: '巴哈马',
    'zh-CN': '巴哈马',
    en: 'Bahamas',
    'en-US': 'Bahamas',
    'zh-HK': '巴哈馬',
    'zh-TW': '巴哈馬',
    'km-KH': 'បាហាម៉ាស',
    'vi-VN': 'Bahamas',
    'th-TH': 'บาฮามาส',
    'ms-MY': 'Bahamas',
    'ja-JP': 'バハマ',
    'ko-KR': '바하마',
    'id-ID': 'Bahama',
    'lo-LA': 'ບາຮາມາດ',
    'hi-IN': 'बहामा'
  },
  {
    shortName: 'BT',
    tel: '975',
    name: '不丹',
    'zh-CN': '不丹',
    en: 'Bhutan',
    'en-US': 'Bhutan',
    'zh-HK': '不丹',
    'zh-TW': '不丹',
    'km-KH': 'ប៊ូតាន',
    'vi-VN': 'Bhutan',
    'th-TH': 'ภูฏาน',
    'ms-MY': 'Bhutan',
    'ja-JP': 'ブータン',
    'ko-KR': '부탄',
    'id-ID': 'Bhutan',
    'lo-LA': 'ພູຖານ',
    'hi-IN': 'भूटान'
  },
  {
    shortName: 'BW',
    tel: '267',
    name: '博茨瓦纳',
    'zh-CN': '博茨瓦纳',
    en: 'Botswana',
    'en-US': 'Botswana',
    'zh-HK': '波札那',
    'zh-TW': '波札那',
    'km-KH': 'បូតស្វាណា',
    'vi-VN': 'Botswana',
    'th-TH': 'บอตสวานา',
    'ms-MY': 'Botswana',
    'ja-JP': 'ボツワナ',
    'ko-KR': '보츠와나',
    'id-ID': 'Botswana',
    'lo-LA': 'ບອດສະວານາ',
    'hi-IN': 'बोत्सवाना'
  },
  {
    shortName: 'BY',
    tel: '375',
    name: '白俄罗斯',
    'zh-CN': '白俄罗斯',
    en: 'Belarus',
    'en-US': 'Belarus',
    'zh-HK': '白俄羅斯',
    'zh-TW': '白俄羅斯',
    'km-KH': 'បេឡារុស្ស',
    'vi-VN': 'Bêlarut',
    'th-TH': 'เบลารุส',
    'ms-MY': 'Belarus',
    'ja-JP': 'ベラルーシ',
    'ko-KR': '벨라루스',
    'id-ID': 'Belarusia',
    'lo-LA': 'ເບລາຣຸດ',
    'hi-IN': 'बेलोरूस'
  },
  {
    shortName: 'BZ',
    tel: '501',
    name: '伯利兹',
    'zh-CN': '伯利兹',
    en: 'Belize',
    'en-US': 'Belize',
    'zh-HK': '貝里斯',
    'zh-TW': '貝里斯',
    'km-KH': 'បេលីស',
    'vi-VN': 'Belize',
    'th-TH': 'เบลีซ',
    'ms-MY': 'Belize',
    'ja-JP': 'ベリーズ',
    'ko-KR': '벨리즈',
    'id-ID': 'Belize',
    'lo-LA': 'ເບລິຊ',
    'hi-IN': 'बेलीज़'
  },
  {
    shortName: 'CC',
    tel: '61',
    name: '科科斯（基林）群岛',
    'zh-CN': '科科斯（基林）群岛',
    en: 'Cocos (Keeling) Islands',
    'en-US': 'Cocos (Keeling) Islands',
    'zh-HK': '科科斯（基林）群島',
    'zh-TW': '科科斯（基林）群島',
    'km-KH': 'កោះ Cocos (Keeling)',
    'vi-VN': 'Quần đảo Cocos (Keeling)',
    'th-TH': 'หมู่เกาะโคโคส (คีลิง)',
    'ms-MY': 'Kepulauan Cocos (Keeling).',
    'ja-JP': 'ココス (キーリング) 諸島',
    'ko-KR': '코코스(킬링) 제도',
    'id-ID': 'Kepulauan Cocos (Keeling).',
    'lo-LA': 'ໝູ່ເກາະ Cocos (Keeling).',
    'hi-IN': 'कोकोस (कीलिंग) द्वीप समूह'
  },
  {
    shortName: 'CD',
    tel: '243',
    name: '刚果（金）',
    'zh-CN': '刚果（金）',
    en: 'Congo - Kinshasa',
    'en-US': 'Congo - Kinshasa',
    'zh-HK': '剛果（金）',
    'zh-TW': '剛果（金）',
    'km-KH': 'កុងហ្គោ - គីនសាសា',
    'vi-VN': 'Congo - Kinshasa',
    'th-TH': 'คองโก - กินชาซา',
    'ms-MY': 'Congo - Kinshasa',
    'ja-JP': 'コンゴ - キンシャサ',
    'ko-KR': '콩고 - 킨샤사',
    'id-ID': 'Kongo - Kinshasa',
    'lo-LA': 'ຄອງໂກ - ກິນຊາຊາ',
    'hi-IN': 'कांगो - किंशासा'
  },
  {
    shortName: 'CF',
    tel: '236',
    name: '中非共和国',
    'zh-CN': '中非共和国',
    en: 'Central African Republic',
    'en-US': 'Central African Republic',
    'zh-HK': '中非共和國',
    'zh-TW': '中非共和國',
    'km-KH': 'សាធារណរដ្ឋអាហ្វ្រិកកណ្តាល',
    'vi-VN': 'Cộng hòa Trung Phi',
    'th-TH': 'สาธารณรัฐอัฟริกากลาง',
    'ms-MY': 'Republik Afrika Tengah',
    'ja-JP': '中央アフリカ共和国',
    'ko-KR': '중앙아프리카공화국',
    'id-ID': 'Republik Afrika Tengah',
    'lo-LA': 'ສາທາລະນະລັດອາຟຣິກາກາງ',
    'hi-IN': 'केन्द्रीय अफ़्रीकी गणराज्य'
  },
  {
    shortName: 'CG',
    tel: '242',
    name: '刚果（布）',
    'zh-CN': '刚果（布）',
    en: 'Congo - Brazzaville',
    'en-US': 'Congo - Brazzaville',
    'zh-HK': '剛果（布）',
    'zh-TW': '剛果（布）',
    'km-KH': 'កុងហ្គោ - Brazzaville',
    'vi-VN': 'Congo-Brazzaville',
    'th-TH': 'คองโก - บราซซาวิล',
    'ms-MY': 'Congo - Brazzaville',
    'ja-JP': 'コンゴ - ブラザビル',
    'ko-KR': '콩고 - 브라자빌',
    'id-ID': 'Kongo - Brazzaville',
    'lo-LA': 'ຄອງໂກ - Brazzaville',
    'hi-IN': 'कांगो - ब्रेज़ाविल'
  },
  {
    shortName: 'CH',
    tel: '41',
    name: '瑞士',
    'zh-CN': '瑞士',
    en: 'Switzerland',
    'en-US': 'Switzerland',
    'zh-HK': '瑞士',
    'zh-TW': '瑞士',
    'km-KH': 'ស្វីស',
    'vi-VN': 'Thụy Sĩ',
    'th-TH': 'สวิตเซอร์แลนด์',
    'ms-MY': 'Switzerland',
    'ja-JP': 'スイス',
    'ko-KR': '스위스',
    'id-ID': 'Swiss',
    'lo-LA': 'ສະວິດເຊີແລນ',
    'hi-IN': 'स्विट्ज़रलैंड'
  },
  {
    shortName: 'CI',
    tel: '225',
    name: '科特迪瓦',
    'zh-CN': '科特迪瓦',
    en: 'Côte d’Ivoire',
    'en-US': 'Côte d’Ivoire',
    'zh-HK': '科特迪瓦',
    'zh-TW': '科特迪瓦',
    'km-KH': 'កូតឌីវ័រ',
    'vi-VN': 'Bờ Biển Ngà',
    'th-TH': 'ชายฝั่งงาช้าง',
    'ms-MY': 'Pantai Gading',
    'ja-JP': 'コートジボワール',
    'ko-KR': '상아 해안',
    'id-ID': 'Pantai Gading',
    'lo-LA': 'Ivory Coast',
    'hi-IN': 'हाथीदांत का किनारा'
  },
  {
    shortName: 'CK',
    tel: '682',
    name: '库克群岛',
    'zh-CN': '库克群岛',
    en: 'Cook Islands',
    'en-US': 'Cook Islands',
    'zh-HK': '庫克群島',
    'zh-TW': '庫克群島',
    'km-KH': 'កោះឃុក',
    'vi-VN': 'Quần đảo Cook',
    'th-TH': 'หมู่เกาะคุก',
    'ms-MY': 'Kepulauan Cook',
    'ja-JP': 'クック諸島',
    'ko-KR': '쿡 제도',
    'id-ID': 'Kepulauan Cook',
    'lo-LA': 'ໝູ່ເກາະຄຸກ',
    'hi-IN': 'कुक आइलैंड्स'
  },
  {
    shortName: 'CL',
    tel: '56',
    name: '智利',
    'zh-CN': '智利',
    en: 'Chile',
    'en-US': 'Chile',
    'zh-HK': '智利',
    'zh-TW': '智利',
    'km-KH': 'ឈីលី',
    'vi-VN': 'Chilê',
    'th-TH': 'ชิลี',
    'ms-MY': 'Chile',
    'ja-JP': 'チリ',
    'ko-KR': '칠레',
    'id-ID': 'Chili',
    'lo-LA': 'ຊິລີ',
    'hi-IN': 'चिली'
  },
  {
    shortName: 'CM',
    tel: '237',
    name: '喀麦隆',
    'zh-CN': '喀麦隆',
    en: 'Cameroon',
    'en-US': 'Cameroon',
    'zh-HK': '喀麥隆',
    'zh-TW': '喀麥隆',
    'km-KH': 'កាមេរូន',
    'vi-VN': 'Ca-mơ-run',
    'th-TH': 'แคเมอรูน',
    'ms-MY': 'Cameroon',
    'ja-JP': 'カメルーン',
    'ko-KR': '카메룬',
    'id-ID': 'Kamerun',
    'lo-LA': 'ປະເທດ Cameroon',
    'hi-IN': 'कैमरून'
  },
  {
    shortName: 'CO',
    tel: '57',
    name: '哥伦比亚',
    'zh-CN': '哥伦比亚',
    en: 'Colombia',
    'en-US': 'Colombia',
    'zh-HK': '哥倫比亞',
    'zh-TW': '哥倫比亞',
    'km-KH': 'កូឡុំប៊ី',
    'vi-VN': 'Colombia',
    'th-TH': 'โคลอมเบีย',
    'ms-MY': 'Colombia',
    'ja-JP': 'コロンビア',
    'ko-KR': '콜롬비아',
    'id-ID': 'Kolumbia',
    'lo-LA': 'ໂຄລໍາເບຍ',
    'hi-IN': 'कोलंबिया'
  },
  {
    shortName: 'CR',
    tel: '506',
    name: '哥斯达黎加',
    'zh-CN': '哥斯达黎加',
    en: 'Costa Rica',
    'en-US': 'Costa Rica',
    'zh-HK': '哥斯大黎加',
    'zh-TW': '哥斯大黎加',
    'km-KH': 'កូស្តារីកា',
    'vi-VN': 'Costa Rica',
    'th-TH': 'คอสตาริกา',
    'ms-MY': 'Costa Rica',
    'ja-JP': 'コスタリカ',
    'ko-KR': '코스타리카',
    'id-ID': 'Kosta Rika',
    'lo-LA': 'Costa Rica',
    'hi-IN': 'कोस्टा रिका'
  },
  {
    shortName: 'CU',
    tel: '53',
    name: '古巴',
    'zh-CN': '古巴',
    en: 'Cuba',
    'en-US': 'Cuba',
    'zh-HK': '古巴',
    'zh-TW': '古巴',
    'km-KH': 'គុយបា',
    'vi-VN': 'Cuba',
    'th-TH': 'คิวบา',
    'ms-MY': 'Cuba',
    'ja-JP': 'キューバ',
    'ko-KR': '쿠바',
    'id-ID': 'Kuba',
    'lo-LA': 'ຄິວບາ',
    'hi-IN': 'क्यूबा'
  },
  {
    shortName: 'CV',
    tel: '238',
    name: '佛得角',
    'zh-CN': '佛得角',
    en: 'Cape Verde',
    'en-US': 'Cape Verde',
    'zh-HK': '維德角',
    'zh-TW': '維德角',
    'km-KH': 'ខេប វីដេ',
    'vi-VN': 'Cabo Verde',
    'th-TH': 'เคปเวิร์ด',
    'ms-MY': 'Tanjung Verde',
    'ja-JP': 'カーボベルデ',
    'ko-KR': '카보베르데',
    'id-ID': 'Tanjung Verde',
    'lo-LA': 'Cape Verde',
    'hi-IN': 'केप वर्ड'
  },
  {
    shortName: 'CW',
    tel: '599 9',
    name: '库拉索',
    'zh-CN': '库拉索',
    en: 'Curaçao',
    'en-US': 'Curaçao',
    'zh-HK': '庫拉索',
    'zh-TW': '庫拉索',
    'km-KH': 'គូរ៉ាកាវ',
    'vi-VN': 'Curaçao',
    'th-TH': 'คูราเซา',
    'ms-MY': 'Curaçao',
    'ja-JP': 'キュラソー',
    'ko-KR': '퀴라소',
    'id-ID': 'Curacao',
    'lo-LA': 'ຄູຣາຊາວ',
    'hi-IN': 'कुराकाओ'
  },
  {
    shortName: 'CX',
    tel: '61',
    name: '圣诞岛',
    'zh-CN': '圣诞岛',
    en: 'Christmas Island',
    'en-US': 'Christmas Island',
    'zh-HK': '聖誕島',
    'zh-TW': '聖誕島',
    'km-KH': 'កោះណូអែល',
    'vi-VN': 'Đảo Giáng Sinh',
    'th-TH': 'เกาะคริสต์มาส',
    'ms-MY': 'Pulau Krismas',
    'ja-JP': 'クリスマス島',
    'ko-KR': '크리스마스 섬',
    'id-ID': 'Pulau Natal',
    'lo-LA': 'ເກາະຄຣິສມາດ',
    'hi-IN': 'क्रिसमस द्वीप'
  },
  {
    shortName: 'CY',
    tel: '357',
    name: '塞浦路斯',
    'zh-CN': '塞浦路斯',
    en: 'Cyprus',
    'en-US': 'Cyprus',
    'zh-HK': '賽普勒斯',
    'zh-TW': '賽普勒斯',
    'km-KH': 'ស៊ីប',
    'vi-VN': 'Síp',
    'th-TH': 'ไซปรัส',
    'ms-MY': 'Cyprus',
    'ja-JP': 'キプロス',
    'ko-KR': '키프로스',
    'id-ID': 'Siprus',
    'lo-LA': 'ໄຊປຣັສ',
    'hi-IN': 'साइप्रस'
  },
  {
    shortName: 'CZ',
    tel: '420',
    name: '捷克',
    'zh-CN': '捷克',
    en: 'Czechia',
    'en-US': 'Czechia',
    'zh-HK': '捷克',
    'zh-TW': '捷克',
    'km-KH': 'ឆេក',
    'vi-VN': 'Séc',
    'th-TH': 'เช็กเกีย',
    'ms-MY': 'Czechia',
    'ja-JP': 'チェコ',
    'ko-KR': '체코',
    'id-ID': 'Ceko',
    'lo-LA': 'ເຊັກໂກ',
    'hi-IN': 'चेकिया'
  },
  {
    shortName: 'DE',
    tel: '49',
    name: '德国',
    'zh-CN': '德国',
    en: 'Germany',
    'en-US': 'Germany',
    'zh-HK': '德國',
    'zh-TW': '德國',
    'km-KH': 'អាល្លឺម៉ង់',
    'vi-VN': 'nước Đức',
    'th-TH': 'เยอรมนี',
    'ms-MY': 'Jerman',
    'ja-JP': 'ドイツ',
    'ko-KR': '독일',
    'id-ID': 'Jerman',
    'lo-LA': 'ເຢຍລະມັນ',
    'hi-IN': 'जर्मनी'
  },
  {
    shortName: 'DJ',
    tel: '253',
    name: '吉布提',
    'zh-CN': '吉布提',
    en: 'Djibouti',
    'en-US': 'Djibouti',
    'zh-HK': '吉布地',
    'zh-TW': '吉布地',
    'km-KH': 'ជីប៊ូទី',
    'vi-VN': 'Djibouti',
    'th-TH': 'จิบูตี',
    'ms-MY': 'Djibouti',
    'ja-JP': 'ジブチ',
    'ko-KR': '지부티',
    'id-ID': 'Djibouti',
    'lo-LA': 'ຈີບູຕີ',
    'hi-IN': 'ज़िबूटी'
  },
  {
    shortName: 'DK',
    tel: '45',
    name: '丹麦',
    'zh-CN': '丹麦',
    en: 'Denmark',
    'en-US': 'Denmark',
    'zh-HK': '丹麥',
    'zh-TW': '丹麥',
    'km-KH': 'ដាណឺម៉ាក',
    'vi-VN': 'Đan Mạch',
    'th-TH': 'เดนมาร์ก',
    'ms-MY': 'Denmark',
    'ja-JP': 'デンマーク',
    'ko-KR': '덴마크',
    'id-ID': 'Denmark',
    'lo-LA': 'ເດນມາກ',
    'hi-IN': 'डेनमार्क'
  },
  {
    shortName: 'DM',
    tel: '1767',
    name: '多米尼克',
    'zh-CN': '多米尼克',
    en: 'Dominica',
    'en-US': 'Dominica',
    'zh-HK': '多明尼克',
    'zh-TW': '多明尼克',
    'km-KH': 'ដូមីនីកា',
    'vi-VN': 'Dominica',
    'th-TH': 'โดมินิกา',
    'ms-MY': 'Dominica',
    'ja-JP': 'ドミニカ',
    'ko-KR': '도미니카',
    'id-ID': 'Dominika',
    'lo-LA': 'ໂດມິນິກາ',
    'hi-IN': 'डोमिनिका'
  },
  {
    shortName: 'DO',
    tel: '1849',
    name: '多米尼加共和国',
    'zh-CN': '多米尼加共和国',
    en: 'Dominican Republic',
    'en-US': 'Dominican Republic',
    'zh-HK': '多明尼加共和國',
    'zh-TW': '多明尼加共和國',
    'km-KH': 'សាធារណរដ្ឋដូមីនីកែន',
    'vi-VN': 'Cộng hòa Dominica',
    'th-TH': 'สาธารณรัฐโดมินิกัน',
    'ms-MY': 'Republik Dominican',
    'ja-JP': 'ドミニカ共和国',
    'ko-KR': '도미니카 공화국',
    'id-ID': 'Republik Dominika',
    'lo-LA': 'ສາທາລະນະລັດໂດມິນິກັນ',
    'hi-IN': 'डोमिनिकन गणराज्य'
  },
  {
    shortName: 'DZ',
    tel: '213',
    name: '阿尔及利亚',
    'zh-CN': '阿尔及利亚',
    en: 'Algeria',
    'en-US': 'Algeria',
    'zh-HK': '阿爾及利亞',
    'zh-TW': '阿爾及利亞',
    'km-KH': 'អាល់ហ្សេរី',
    'vi-VN': 'Algérie',
    'th-TH': 'แอลจีเรีย',
    'ms-MY': 'Algeria',
    'ja-JP': 'アルジェリア',
    'ko-KR': '알제리',
    'id-ID': 'Aljazair',
    'lo-LA': 'ແອລຈີເລຍ',
    'hi-IN': 'एलजीरिया'
  },
  {
    shortName: 'EC',
    tel: '593',
    name: '厄瓜多尔',
    'zh-CN': '厄瓜多尔',
    en: 'Ecuador',
    'en-US': 'Ecuador',
    'zh-HK': '厄瓜多',
    'zh-TW': '厄瓜多',
    'km-KH': 'អេក្វាឌ័រ',
    'vi-VN': 'Ecuador',
    'th-TH': 'เอกวาดอร์',
    'ms-MY': 'Ecuador',
    'ja-JP': 'エクアドル',
    'ko-KR': '에콰도르',
    'id-ID': 'Ekuador',
    'lo-LA': 'ເອກົວດໍ',
    'hi-IN': 'इक्वेडोर'
  },
  {
    shortName: 'EE',
    tel: '372',
    name: '爱沙尼亚',
    'zh-CN': '爱沙尼亚',
    en: 'Estonia',
    'en-US': 'Estonia',
    'zh-HK': '愛沙尼亞',
    'zh-TW': '愛沙尼亞',
    'km-KH': 'អេស្តូនី',
    'vi-VN': 'Estonia',
    'th-TH': 'เอสโตเนีย',
    'ms-MY': 'Estonia',
    'ja-JP': 'エストニア',
    'ko-KR': '에스토니아',
    'id-ID': 'Estonia',
    'lo-LA': 'ເອສໂຕເນຍ',
    'hi-IN': 'एस्तोनिया'
  },
  {
    shortName: 'EG',
    tel: '20',
    name: '埃及',
    'zh-CN': '埃及',
    en: 'Egypt',
    'en-US': 'Egypt',
    'zh-HK': '埃及',
    'zh-TW': '埃及',
    'km-KH': 'អេហ្ស៊ីប',
    'vi-VN': 'Ai Cập',
    'th-TH': 'อียิปต์',
    'ms-MY': 'Mesir',
    'ja-JP': 'エジプト',
    'ko-KR': '이집트',
    'id-ID': 'Mesir',
    'lo-LA': 'ປະເທດເອຢິບ',
    'hi-IN': 'मिस्र'
  },
  {
    shortName: 'EH',
    tel: '212',
    name: '西撒哈拉',
    'zh-CN': '西撒哈拉',
    en: 'Western Sahara',
    'en-US': 'Western Sahara',
    'zh-HK': '西撒哈拉',
    'zh-TW': '西撒哈拉',
    'km-KH': 'សាហារ៉ាខាងលិច',
    'vi-VN': 'Tây Sahara',
    'th-TH': 'ซาฮาราตะวันตก',
    'ms-MY': 'Sahara Barat',
    'ja-JP': '西サハラ',
    'ko-KR': '서사하라',
    'id-ID': 'Sahara Barat',
    'lo-LA': 'ຊາຮາຣາຕາເວັນຕົກ',
    'hi-IN': 'पश्चिमी सहारा'
  },
  {
    shortName: 'ER',
    tel: '291',
    name: '厄立特里亚',
    'zh-CN': '厄立特里亚',
    en: 'Eritrea',
    'en-US': 'Eritrea',
    'zh-HK': '厄利垂亞',
    'zh-TW': '厄利垂亞',
    'km-KH': 'អេរីទ្រា',
    'vi-VN': 'Eritrea',
    'th-TH': 'เอริเทรีย',
    'ms-MY': 'Eritrea',
    'ja-JP': 'エリトリア',
    'ko-KR': '에리트레아',
    'id-ID': 'Eritrea',
    'lo-LA': 'ເອຣິເທຣຍ',
    'hi-IN': 'इरिट्रिया'
  },
  {
    shortName: 'ES',
    tel: '34',
    name: '西班牙',
    'zh-CN': '西班牙',
    en: 'Spain',
    'en-US': 'Spain',
    'zh-HK': '西班牙',
    'zh-TW': '西班牙',
    'km-KH': 'អេស្ប៉ាញ',
    'vi-VN': 'Tây ban nha',
    'th-TH': 'สเปน',
    'ms-MY': 'Sepanyol',
    'ja-JP': 'スペイン',
    'ko-KR': '스페인',
    'id-ID': 'Spanyol',
    'lo-LA': 'ສະເປນ',
    'hi-IN': 'स्पेन'
  },
  {
    shortName: 'ET',
    tel: '251',
    name: '埃塞俄比亚',
    'zh-CN': '埃塞俄比亚',
    en: 'Ethiopia',
    'en-US': 'Ethiopia',
    'zh-HK': '衣索比亞',
    'zh-TW': '衣索比亞',
    'km-KH': 'អេត្យូពី',
    'vi-VN': 'Ethiopia',
    'th-TH': 'เอธิโอเปีย',
    'ms-MY': 'Habsyah',
    'ja-JP': 'エチオピア',
    'ko-KR': '에티오피아',
    'id-ID': 'Etiopia',
    'lo-LA': 'ເອທິໂອເປຍ',
    'hi-IN': 'इथियोपिया'
  },
  {
    shortName: 'FI',
    tel: '358',
    name: '芬兰',
    'zh-CN': '芬兰',
    en: 'Finland',
    'en-US': 'Finland',
    'zh-HK': '芬蘭',
    'zh-TW': '芬蘭',
    'km-KH': 'ហ្វាំងឡង់',
    'vi-VN': 'Phần Lan',
    'th-TH': 'ฟินแลนด์',
    'ms-MY': 'Finland',
    'ja-JP': 'フィンランド',
    'ko-KR': '핀란드',
    'id-ID': 'Finlandia',
    'lo-LA': 'ຟິນແລນ',
    'hi-IN': 'फिनलैंड'
  },
  {
    shortName: 'FJ',
    tel: '679',
    name: '斐济',
    'zh-CN': '斐济',
    en: 'Fiji',
    'en-US': 'Fiji',
    'zh-HK': '斐濟',
    'zh-TW': '斐濟',
    'km-KH': 'ហ្វីជី',
    'vi-VN': 'Fiji',
    'th-TH': 'ฟิจิ',
    'ms-MY': 'Fiji',
    'ja-JP': 'フィジー',
    'ko-KR': '피지',
    'id-ID': 'Fiji',
    'lo-LA': 'ຟິຈິ',
    'hi-IN': 'फ़िजी'
  },
  {
    shortName: 'FK',
    tel: '500',
    name: '福克兰群岛',
    'zh-CN': '福克兰群岛',
    en: 'Falkland Islands',
    'en-US': 'Falkland Islands',
    'zh-HK': '福克蘭群島',
    'zh-TW': '福克蘭群島',
    'km-KH': 'កោះហ្វក់លែន',
    'vi-VN': 'Quần đảo Falkland',
    'th-TH': 'หมู่เกาะฟอล์กแลนด์',
    'ms-MY': 'Kepulauan Falkland',
    'ja-JP': 'フォークランド諸島',
    'ko-KR': '포클랜드 제도',
    'id-ID': 'Kepulauan Falkland',
    'lo-LA': 'ໝູ່ເກາະຟອກແລນ',
    'hi-IN': 'फ़ॉकलैंड द्वीप समूह'
  },
  {
    shortName: 'FM',
    tel: '691',
    name: '密克罗尼西亚',
    'zh-CN': '密克罗尼西亚',
    en: 'Micronesia',
    'en-US': 'Micronesia',
    'zh-HK': '密克羅尼西亞',
    'zh-TW': '密克羅尼西亞',
    'km-KH': 'មីក្រូនេស៊ី',
    'vi-VN': 'Micronesia',
    'th-TH': 'ไมโครนีเซีย',
    'ms-MY': 'Mikronesia',
    'ja-JP': 'ミクロネシア',
    'ko-KR': '미크로네시아',
    'id-ID': 'Mikronesia',
    'lo-LA': 'ໄມໂຄຣນີເຊຍ',
    'hi-IN': 'माइक्रोनेशिया'
  },
  {
    shortName: 'FO',
    tel: '298',
    name: '法罗群岛',
    'zh-CN': '法罗群岛',
    en: 'Faroe Islands',
    'en-US': 'Faroe Islands',
    'zh-HK': '法羅群島',
    'zh-TW': '法羅群島',
    'km-KH': 'កោះហ្វារ៉ូ',
    'vi-VN': 'Quần đảo Faroe',
    'th-TH': 'หมู่เกาะแฟโร',
    'ms-MY': 'Kepulauan Faroe',
    'ja-JP': 'フェロー諸島',
    'ko-KR': '페로 제도',
    'id-ID': 'Kepulauan Faroe',
    'lo-LA': 'ໝູ່ເກາະຟາໂຣ',
    'hi-IN': 'फरो आइलैंड्स'
  },
  {
    shortName: 'FR',
    tel: '33',
    name: '法国',
    'zh-CN': '法国',
    en: 'France',
    'en-US': 'France',
    'zh-HK': '法國',
    'zh-TW': '法國',
    'km-KH': 'ប្រទេសបារាំង',
    'vi-VN': 'Pháp',
    'th-TH': 'ฝรั่งเศส',
    'ms-MY': 'Perancis',
    'ja-JP': 'フランス',
    'ko-KR': '프랑스',
    'id-ID': 'Perancis',
    'lo-LA': 'ປະເທດຝຣັ່ງ',
    'hi-IN': 'फ्रांस'
  },
  {
    shortName: 'GA',
    tel: '241',
    name: '加蓬',
    'zh-CN': '加蓬',
    en: 'Gabon',
    'en-US': 'Gabon',
    'zh-HK': '加彭',
    'zh-TW': '加彭',
    'km-KH': 'ហ្គាបុង',
    'vi-VN': 'Gabon',
    'th-TH': 'กาบอง',
    'ms-MY': 'Gabon',
    'ja-JP': 'ガボン',
    'ko-KR': '가봉',
    'id-ID': 'Gabon',
    'lo-LA': 'ກາບອນ',
    'hi-IN': 'गैबॉन'
  },
  {
    shortName: 'GB',
    tel: '44',
    name: '英国',
    'zh-CN': '英国',
    en: 'United Kingdom',
    'en-US': 'United Kingdom',
    'zh-HK': '英國',
    'zh-TW': '英國',
    'km-KH': 'ចក្រភពអង់គ្លេស',
    'vi-VN': 'Vương quốc Anh',
    'th-TH': 'สหราชอาณาจักร',
    'ms-MY': 'United Kingdom',
    'ja-JP': 'イギリス',
    'ko-KR': '영국',
    'id-ID': 'Inggris Raya',
    'lo-LA': 'ສະຫະລາຊະອານາຈັກ',
    'hi-IN': 'यूनाइटेड किंगडम'
  },
  {
    shortName: 'GD',
    tel: '1473',
    name: '格林纳达',
    'zh-CN': '格林纳达',
    en: 'Grenada',
    'en-US': 'Grenada',
    'zh-HK': '格瑞那達',
    'zh-TW': '格瑞那達',
    'km-KH': 'ហ្គ្រេណាដា',
    'vi-VN': 'Grenada',
    'th-TH': 'เกรเนดา',
    'ms-MY': 'Grenada',
    'ja-JP': 'グレナダ',
    'ko-KR': '그레나다',
    'id-ID': 'Granada',
    'lo-LA': 'ກຣີນາດາ',
    'hi-IN': 'ग्रेनेडा'
  },
  {
    shortName: 'GE',
    tel: '995',
    name: '格鲁吉亚',
    'zh-CN': '格鲁吉亚',
    en: 'Georgia',
    'en-US': 'Georgia',
    'zh-HK': '喬治亞',
    'zh-TW': '喬治亞',
    'km-KH': 'ហ្សកហ្ស៊ី',
    'vi-VN': 'Gruzia',
    'th-TH': 'จอร์เจีย',
    'ms-MY': 'Georgia',
    'ja-JP': 'ジョージア',
    'ko-KR': '그루지야',
    'id-ID': 'Georgia',
    'lo-LA': 'ຈໍເຈຍ',
    'hi-IN': 'जॉर्जिया'
  },
  {
    shortName: 'GF',
    tel: '594',
    name: '法属圭亚那',
    'zh-CN': '法属圭亚那',
    en: 'French Guiana',
    'en-US': 'French Guiana',
    'zh-HK': '法屬圭亞那',
    'zh-TW': '法屬圭亞那',
    'km-KH': 'ហ្គីយ៉ាណាបារាំង',
    'vi-VN': 'Guiana thuộc Pháp',
    'th-TH': 'เฟรนช์เกียนา',
    'ms-MY': 'Guiana Perancis',
    'ja-JP': 'フランス領ギアナ',
    'ko-KR': '프랑스령 기아나',
    'id-ID': 'Guyana Perancis',
    'lo-LA': 'ຝຣັ່ງກີອານາ',
    'hi-IN': 'फ़्रेंच गुयाना'
  },
  {
    shortName: 'GG',
    tel: '44',
    name: '根西岛',
    'zh-CN': '根西岛',
    en: 'Guernsey',
    'en-US': 'Guernsey',
    'zh-HK': '根西島',
    'zh-TW': '根西島',
    'km-KH': 'ហ្គេនស៊ី',
    'vi-VN': 'Guernsey',
    'th-TH': 'เกิร์นซีย์',
    'ms-MY': 'Guernsey',
    'ja-JP': 'ガーンジー島',
    'ko-KR': '건지',
    'id-ID': 'Guernsey',
    'lo-LA': 'ເກີ່ນຊີ',
    'hi-IN': 'ग्वेर्नसे'
  },
  {
    shortName: 'GH',
    tel: '233',
    name: '加纳',
    'zh-CN': '加纳',
    en: 'Ghana',
    'en-US': 'Ghana',
    'zh-HK': '迦納',
    'zh-TW': '迦納',
    'km-KH': 'ហ្គាណា',
    'vi-VN': 'Ghana',
    'th-TH': 'กานา',
    'ms-MY': 'Ghana',
    'ja-JP': 'ガーナ',
    'ko-KR': '가나',
    'id-ID': 'Ghana',
    'lo-LA': 'ການາ',
    'hi-IN': 'घाना'
  },
  {
    shortName: 'GI',
    tel: '350',
    name: '直布罗陀',
    'zh-CN': '直布罗陀',
    en: 'Gibraltar',
    'en-US': 'Gibraltar',
    'zh-HK': '直布羅陀',
    'zh-TW': '直布羅陀',
    'km-KH': 'ជីប្រាតា',
    'vi-VN': 'Gibraltar',
    'th-TH': 'ยิบรอลตาร์',
    'ms-MY': 'Gibraltar',
    'ja-JP': 'ジブラルタル',
    'ko-KR': '지브롤터',
    'id-ID': 'Gibraltar',
    'lo-LA': 'Gibraltar',
    'hi-IN': 'जिब्राल्टर'
  },
  {
    shortName: 'GL',
    tel: '299',
    name: '格陵兰',
    'zh-CN': '格陵兰',
    en: 'Greenland',
    'en-US': 'Greenland',
    'zh-HK': '格陵蘭',
    'zh-TW': '格陵蘭',
    'km-KH': 'ហ្គ្រីនឡែន',
    'vi-VN': 'Greenland',
    'th-TH': 'กรีนแลนด์',
    'ms-MY': 'Greenland',
    'ja-JP': 'グリーンランド',
    'ko-KR': '그린란드',
    'id-ID': 'Tanah penggembalaan',
    'lo-LA': 'ກຣີນແລນ',
    'hi-IN': 'ग्रीनलैंड'
  },
  {
    shortName: 'GM',
    tel: '220',
    name: '冈比亚',
    'zh-CN': '冈比亚',
    en: 'Gambia',
    'en-US': 'Gambia',
    'zh-HK': '甘比亞',
    'zh-TW': '甘比亞',
    'km-KH': 'ហ្គាំប៊ី',
    'vi-VN': 'Gambia',
    'th-TH': 'แกมเบีย',
    'ms-MY': 'Gambia',
    'ja-JP': 'ガンビア',
    'ko-KR': '감비아',
    'id-ID': 'Gambia',
    'lo-LA': 'ແກມເບຍ',
    'hi-IN': 'गाम्बिया'
  },
  {
    shortName: 'GN',
    tel: '224',
    name: '几内亚',
    'zh-CN': '几内亚',
    en: 'Guinea',
    'en-US': 'Guinea',
    'zh-HK': '幾內亞',
    'zh-TW': '幾內亞',
    'km-KH': 'ហ្គីណេ',
    'vi-VN': 'Ghi-nê',
    'th-TH': 'กินี',
    'ms-MY': 'Guinea',
    'ja-JP': 'ギニア',
    'ko-KR': '기니',
    'id-ID': 'Guinea',
    'lo-LA': 'ກີນີ',
    'hi-IN': 'गिनी'
  },
  {
    shortName: 'GP',
    tel: '590',
    name: '瓜德罗普',
    'zh-CN': '瓜德罗普',
    en: 'Guadeloupe',
    'en-US': 'Guadeloupe',
    'zh-HK': '瓜德羅普',
    'zh-TW': '瓜德羅普',
    'km-KH': 'ហ្គូដលូប',
    'vi-VN': 'Guadeloupe',
    'th-TH': 'กวาเดอลูป',
    'ms-MY': 'Guadeloupe',
    'ja-JP': 'グアドループ',
    'ko-KR': '과들루프',
    'id-ID': 'Guadeloupe',
    'lo-LA': 'Guadeloupe',
    'hi-IN': 'ग्वाडेलोप'
  },
  {
    shortName: 'GQ',
    tel: '240',
    name: '赤道几内亚',
    'zh-CN': '赤道几内亚',
    en: 'Equatorial Guinea',
    'en-US': 'Equatorial Guinea',
    'zh-HK': '赤道幾內亞',
    'zh-TW': '赤道幾內亞',
    'km-KH': 'អេក្វាទ័រហ្គីណេ',
    'vi-VN': 'Guinea Xích đạo',
    'th-TH': 'อิเควทอเรียลกินี',
    'ms-MY': 'Guinea Khatulistiwa',
    'ja-JP': '赤道ギニア',
    'ko-KR': '적도기니',
    'id-ID': 'Guinea Khatulistiwa',
    'lo-LA': 'Equatorial Guinea',
    'hi-IN': 'इक्वेटोरियल गिनी'
  },
  {
    shortName: 'GR',
    tel: '30',
    name: '希腊',
    'zh-CN': '希腊',
    en: 'Greece',
    'en-US': 'Greece',
    'zh-HK': '希臘',
    'zh-TW': '希臘',
    'km-KH': 'ប្រទេសក្រិក',
    'vi-VN': 'Hy Lạp',
    'th-TH': 'กรีซ',
    'ms-MY': 'Greece',
    'ja-JP': 'ギリシャ',
    'ko-KR': '그리스',
    'id-ID': 'Yunani',
    'lo-LA': 'ປະເທດເກຣັກ',
    'hi-IN': 'ग्रीस'
  },
  {
    shortName: 'GS',
    tel: '500',
    name: '南乔治亚和南桑威奇群岛',
    'zh-CN': '南乔治亚和南桑威奇群岛',
    en: 'So. Georgia & So. Sandwich Isl.',
    'en-US': 'So. Georgia & So. Sandwich Isl.',
    'zh-HK': '南喬治亞和南桑威奇群島',
    'zh-TW': '南喬治亞和南桑威奇群島',
    'km-KH': 'ហ្សកហ្ស៊ី &កោះសាំងវិច',
    'vi-VN': 'Vì thế. Georgia & Vì vậy. Đảo Sandwich.',
    'th-TH': 'ดังนั้น. จอร์เจียและโซ หมู่เกาะแซนด์วิช',
    'ms-MY': 'Jadi. Georgia & Jadi. Sandwic Isl.',
    'ja-JP': 'それで。ジョージア＆ソー。サンドイッチ島',
    'ko-KR': '그래서. 조지아 & 소. 샌드위치섬',
    'id-ID': 'Jadi. Georgia & Jadi. Pulau Sandwich',
    'lo-LA': 'ດັ່ງນັ້ນ. ຈໍເຈຍ ແລະດັ່ງນັ້ນ. ແຊນວິດ Isl.',
    'hi-IN': 'इसलिए। जॉर्जिया एंड सो. सैंडविच आईएसएल.'
  },
  {
    shortName: 'GT',
    tel: '502',
    name: '危地马拉',
    'zh-CN': '危地马拉',
    en: 'Guatemala',
    'en-US': 'Guatemala',
    'zh-HK': '瓜地馬拉',
    'zh-TW': '瓜地馬拉',
    'km-KH': 'ហ្គាតេម៉ាឡា',
    'vi-VN': 'Guatemala',
    'th-TH': 'กัวเตมาลา',
    'ms-MY': 'Guatemala',
    'ja-JP': 'グアテマラ',
    'ko-KR': '과테말라',
    'id-ID': 'Guatemala',
    'lo-LA': 'ກົວເຕມາລາ',
    'hi-IN': 'ग्वाटेमाला'
  },
  {
    shortName: 'GU',
    tel: '1671',
    name: '关岛',
    'zh-CN': '关岛',
    en: 'Guam',
    'en-US': 'Guam',
    'zh-HK': '關島',
    'zh-TW': '關島',
    'km-KH': 'កោះហ្គាំ',
    'vi-VN': 'đảo Guam',
    'th-TH': 'กวม',
    'ms-MY': 'Guam',
    'ja-JP': 'グアム',
    'ko-KR': '괌',
    'id-ID': 'Guam',
    'lo-LA': 'Guam',
    'hi-IN': 'गुआम'
  },
  {
    shortName: 'GW',
    tel: '245',
    name: '几内亚比绍',
    'zh-CN': '几内亚比绍',
    en: 'Guinea-Bissau',
    'en-US': 'Guinea-Bissau',
    'zh-HK': '幾內亞比紹',
    'zh-TW': '幾內亞比紹',
    'km-KH': 'ហ្គីណេ-ប៊ីសា',
    'vi-VN': 'Guiné-Bissau',
    'th-TH': 'กินี-บิสเซา',
    'ms-MY': 'Guinea-Bissau',
    'ja-JP': 'ギニアビサウ',
    'ko-KR': '기니비사우',
    'id-ID': 'Guinea-Bissau',
    'lo-LA': 'Guinea-Bissau',
    'hi-IN': 'गिनी-बिसाऊ'
  },
  {
    shortName: 'GY',
    tel: '595',
    name: '圭亚那',
    'zh-CN': '圭亚那',
    en: 'Guyana',
    'en-US': 'Guyana',
    'zh-HK': '蓋亞那',
    'zh-TW': '蓋亞那',
    'km-KH': 'ហ្គីយ៉ាណា',
    'vi-VN': 'Guyana',
    'th-TH': 'กายอานา',
    'ms-MY': 'Guyana',
    'ja-JP': 'ガイアナ',
    'ko-KR': '가이아나',
    'id-ID': 'Guyana',
    'lo-LA': 'ກູຢານາ',
    'hi-IN': 'गुयाना'
  },
  {
    shortName: 'HK',
    tel: '852',
    name: '中国香港',
    'zh-CN': '中国香港',
    en: 'Hong Kong',
    'en-US': 'Hong Kong',
    'zh-HK': '中國香港',
    'zh-TW': '中國香港',
    'km-KH': 'ហុងកុង',
    'vi-VN': 'Hồng Kông',
    'th-TH': 'ฮ่องกง',
    'ms-MY': 'Hong Kong',
    'ja-JP': '香港',
    'ko-KR': '홍콩',
    'id-ID': 'Hongkong',
    'lo-LA': 'ຮົງກົງ',
    'hi-IN': 'हांगकांग'
  },
  {
    shortName: 'HM',
    tel: '672',
    name: '赫德岛和麦克唐纳群岛',
    'zh-CN': '赫德岛和麦克唐纳群岛',
    en: 'Heard & McDonald Islands',
    'en-US': 'Heard & McDonald Islands',
    'zh-HK': '赫德島和麥克唐納群島',
    'zh-TW': '赫德島和麥克唐納群島',
    'km-KH': 'កោះ ហឺត & កោះមេកដូណាល់',
    'vi-VN': 'Quần đảo Heard & McDonald',
    'th-TH': 'หมู่เกาะเฮิร์ดและหมู่เกาะแมกดอนัลด์',
    'ms-MY': 'Pulau Heard & McDonald',
    'ja-JP': 'ハード島とマクドナルド諸島',
    'ko-KR': '허드 & 맥도날드 제도',
    'id-ID': 'Kepulauan Heard & McDonald',
    'lo-LA': 'Heard & McDonald Islands',
    'hi-IN': 'हर्ड और मैकडोनाल्ड द्वीप समूह'
  },
  {
    shortName: 'HN',
    tel: '504',
    name: '洪都拉斯',
    'zh-CN': '洪都拉斯',
    en: 'Honduras',
    'en-US': 'Honduras',
    'zh-HK': '宏都拉斯',
    'zh-TW': '宏都拉斯',
    'km-KH': 'ហុងឌូរ៉ាស',
    'vi-VN': 'Honduras',
    'th-TH': 'ฮอนดูรัส',
    'ms-MY': 'Honduras',
    'ja-JP': 'ホンジュラス',
    'ko-KR': '온두라스',
    'id-ID': 'Honduras',
    'lo-LA': 'ຮອນດູຣັສ',
    'hi-IN': 'होंडुरस'
  },
  {
    shortName: 'HR',
    tel: '385',
    name: '克罗地亚',
    'zh-CN': '克罗地亚',
    en: 'Croatia',
    'en-US': 'Croatia',
    'zh-HK': '克羅埃西亞',
    'zh-TW': '克羅埃西亞',
    'km-KH': 'ក្រូអាត',
    'vi-VN': 'Croatia',
    'th-TH': 'โครเอเชีย',
    'ms-MY': 'Croatia',
    'ja-JP': 'クロアチア',
    'ko-KR': '크로아티아',
    'id-ID': 'Kroasia',
    'lo-LA': 'ໂຄຣເອເຊຍ',
    'hi-IN': 'क्रोएशिया'
  },
  {
    shortName: 'HT',
    tel: '509',
    name: '海地',
    'zh-CN': '海地',
    en: 'Haiti',
    'en-US': 'Haiti',
    'zh-HK': '海地',
    'zh-TW': '海地',
    'km-KH': 'ហៃទី',
    'vi-VN': 'Haiti',
    'th-TH': 'เฮติ',
    'ms-MY': 'Haiti',
    'ja-JP': 'ハイチ',
    'ko-KR': '아이티',
    'id-ID': 'Haiti',
    'lo-LA': 'ເຮຕີ',
    'hi-IN': 'हैती'
  },
  {
    shortName: 'HU',
    tel: '36',
    name: '匈牙利',
    'zh-CN': '匈牙利',
    en: 'Hungary',
    'en-US': 'Hungary',
    'zh-HK': '匈牙利',
    'zh-TW': '匈牙利',
    'km-KH': 'ហុងគ្រី',
    'vi-VN': 'Hungary',
    'th-TH': 'ฮังการี',
    'ms-MY': 'Hungary',
    'ja-JP': 'ハンガリー',
    'ko-KR': '헝가리',
    'id-ID': 'Hongaria',
    'lo-LA': 'ຮັງກາຣີ',
    'hi-IN': 'हंगरी'
  },
  {
    shortName: 'IE',
    tel: '353',
    name: '爱尔兰',
    'zh-CN': '爱尔兰',
    en: 'Ireland',
    'en-US': 'Ireland',
    'zh-HK': '愛爾蘭',
    'zh-TW': '愛爾蘭',
    'km-KH': 'អៀរឡង់',
    'vi-VN': 'Ireland',
    'th-TH': 'ไอร์แลนด์',
    'ms-MY': 'Ireland',
    'ja-JP': 'アイルランド',
    'ko-KR': '아일랜드',
    'id-ID': 'Irlandia',
    'lo-LA': 'ໄອແລນ',
    'hi-IN': 'आयरलैंड'
  },
  {
    shortName: 'IL',
    tel: '972',
    name: '以色列',
    'zh-CN': '以色列',
    en: 'Israel',
    'en-US': 'Israel',
    'zh-HK': '以色列',
    'zh-TW': '以色列',
    'km-KH': 'អ៊ីស្រាអែល',
    'vi-VN': 'Israel',
    'th-TH': 'อิสราเอล',
    'ms-MY': 'Israel',
    'ja-JP': 'イスラエル',
    'ko-KR': '이스라엘',
    'id-ID': 'Israel',
    'lo-LA': 'ອິດສະຣາເອນ',
    'hi-IN': 'इजराइल'
  },
  {
    shortName: 'IM',
    tel: '44',
    name: '马恩岛',
    'zh-CN': '马恩岛',
    en: 'Isle of Man',
    'en-US': 'Isle of Man',
    'zh-HK': '馬恩島',
    'zh-TW': '馬恩島',
    'km-KH': 'កោះម៉ាន់',
    'vi-VN': 'Đảo Man',
    'th-TH': 'เกาะแมน',
    'ms-MY': 'Pulau Man',
    'ja-JP': 'マン島',
    'ko-KR': '맨 섬',
    'id-ID': 'Pulau Man',
    'lo-LA': 'Isle of Man',
    'hi-IN': 'मैन द्वीप'
  },
  {
    shortName: 'IO',
    tel: '246',
    name: '英属印度洋领地',
    'zh-CN': '英属印度洋领地',
    en: 'British Indian Ocean Territory',
    'en-US': 'British Indian Ocean Territory',
    'zh-HK': '英屬印度洋領地',
    'zh-TW': '英屬印度洋領地',
    'km-KH': 'ដែនដីមហាសមុទ្រឥណ្ឌាអង់គ្លេស',
    'vi-VN': 'Lãnh thổ Ấn Độ Dương thuộc Anh',
    'th-TH': 'บริติชอินเดียนโอเชียนเทร์ริทอรี',
    'ms-MY': 'Wilayah Lautan Hindi British',
    'ja-JP': '英領インド洋領土',
    'ko-KR': '영국령 인도양 지역',
    'id-ID': 'Wilayah Britania di Samudra Hindia',
    'lo-LA': 'ດິນແດນມະຫາສະໝຸດອິນເດຍຂອງອັງກິດ',
    'hi-IN': 'ब्रिटिश हिंद महासागर क्षेत्र'
  },
  {
    shortName: 'IQ',
    tel: '964',
    name: '伊拉克',
    'zh-CN': '伊拉克',
    en: 'Iraq',
    'en-US': 'Iraq',
    'zh-HK': '伊拉克',
    'zh-TW': '伊拉克',
    'km-KH': 'អ៊ីរ៉ាក់',
    'vi-VN': 'Irắc',
    'th-TH': 'อิรัก',
    'ms-MY': 'Iraq',
    'ja-JP': 'イラク',
    'ko-KR': '이라크',
    'id-ID': 'Irak',
    'lo-LA': 'ອີຣັກ',
    'hi-IN': 'इराक'
  },
  {
    shortName: 'IR',
    tel: '98',
    name: '伊朗',
    'zh-CN': '伊朗',
    en: 'Iran',
    'en-US': 'Iran',
    'zh-HK': '伊朗',
    'zh-TW': '伊朗',
    'km-KH': 'អ៊ីរ៉ង់',
    'vi-VN': 'Iran',
    'th-TH': 'อิหร่าน',
    'ms-MY': 'Iran',
    'ja-JP': 'イラン',
    'ko-KR': '이란',
    'id-ID': 'Iran',
    'lo-LA': 'ອີຣ່ານ',
    'hi-IN': 'ईरान'
  },
  {
    shortName: 'IS',
    tel: '354',
    name: '冰岛',
    'zh-CN': '冰岛',
    en: 'Iceland',
    'en-US': 'Iceland',
    'zh-HK': '冰島',
    'zh-TW': '冰島',
    'km-KH': 'អ៊ីស្លង់',
    'vi-VN': 'Iceland',
    'th-TH': 'ไอซ์แลนด์',
    'ms-MY': 'Iceland',
    'ja-JP': 'アイスランド',
    'ko-KR': '아이슬란드',
    'id-ID': 'Islandia',
    'lo-LA': 'ໄອສແລນ',
    'hi-IN': 'आइसलैंड'
  },
  {
    shortName: 'IT',
    tel: '39',
    name: '意大利',
    'zh-CN': '意大利',
    en: 'Italy',
    'en-US': 'Italy',
    'zh-HK': '義大利',
    'zh-TW': '義大利',
    'km-KH': 'ប្រទេសអ៊ីតាលី',
    'vi-VN': 'Ý',
    'th-TH': 'อิตาลี',
    'ms-MY': 'Itali',
    'ja-JP': 'イタリア',
    'ko-KR': '이탈리아',
    'id-ID': 'Italia',
    'lo-LA': 'ອີຕາລີ',
    'hi-IN': 'इटली'
  },
  {
    shortName: 'JE',
    tel: '44',
    name: '泽西岛',
    'zh-CN': '泽西岛',
    en: 'Jersey',
    'en-US': 'Jersey',
    'zh-HK': '澤西島',
    'zh-TW': '澤西島',
    'km-KH': 'ជឺស៊ី',
    'vi-VN': 'áo đấu',
    'th-TH': 'เจอร์ซีย์',
    'ms-MY': 'Jersi',
    'ja-JP': 'ジャージー',
    'ko-KR': '저지',
    'id-ID': 'baju kaos',
    'lo-LA': 'ເຈີຊີ',
    'hi-IN': 'जर्सी'
  },
  {
    shortName: 'JM',
    tel: '1876',
    name: '牙买加',
    'zh-CN': '牙买加',
    en: 'Jamaica',
    'en-US': 'Jamaica',
    'zh-HK': '牙買加',
    'zh-TW': '牙買加',
    'km-KH': 'ហ្សាម៉ាអ៊ីក',
    'vi-VN': 'Jamaica',
    'th-TH': 'จาเมกา',
    'ms-MY': 'Jamaica',
    'ja-JP': 'ジャマイカ',
    'ko-KR': '자메이카',
    'id-ID': 'Jamaika',
    'lo-LA': 'ຈາໄມກາ',
    'hi-IN': 'जमैका'
  },
  {
    shortName: 'JO',
    tel: '962',
    name: '约旦',
    'zh-CN': '约旦',
    en: 'Jordan',
    'en-US': 'Jordan',
    'zh-HK': '約旦',
    'zh-TW': '約旦',
    'km-KH': 'ហ្ស៊កដានី/ជ័រឌែន',
    'vi-VN': 'Jordan',
    'th-TH': 'จอร์แดน',
    'ms-MY': 'Jordan',
    'ja-JP': 'ヨルダン',
    'ko-KR': '요르단',
    'id-ID': 'Yordania',
    'lo-LA': 'ຈໍແດນ',
    'hi-IN': 'जॉर्डन'
  },
  {
    shortName: 'JP',
    tel: '81',
    name: '日本',
    'zh-CN': '日本',
    en: 'Japan',
    'en-US': 'Japan',
    'zh-HK': '日本',
    'zh-TW': '日本',
    'km-KH': 'ប្រទេសជប៉ុន',
    'vi-VN': 'Nhật Bản',
    'th-TH': 'ญี่ปุ่น',
    'ms-MY': 'Jepun',
    'ja-JP': '日本',
    'ko-KR': '일본',
    'id-ID': 'Jepang',
    'lo-LA': 'ຍີ່ປຸ່ນ',
    'hi-IN': 'जापान'
  },
  {
    shortName: 'KE',
    tel: '254',
    name: '肯尼亚',
    'zh-CN': '肯尼亚',
    en: 'Kenya',
    'en-US': 'Kenya',
    'zh-HK': '肯亞',
    'zh-TW': '肯亞',
    'km-KH': 'ប្រទេសកេនយ៉ា',
    'vi-VN': 'Kenya',
    'th-TH': 'เคนยา',
    'ms-MY': 'Kenya',
    'ja-JP': 'ケニア',
    'ko-KR': '케냐',
    'id-ID': 'Kenya',
    'lo-LA': 'ເຄນຢາ',
    'hi-IN': 'केन्या'
  },
  {
    shortName: 'KG',
    tel: '996',
    name: '吉尔吉斯斯坦',
    'zh-CN': '吉尔吉斯斯坦',
    en: 'Kyrgyzstan',
    'en-US': 'Kyrgyzstan',
    'zh-HK': '吉爾吉斯斯坦',
    'zh-TW': '吉爾吉斯斯坦',
    'km-KH': 'កៀហ្ស៊ីស៊ីស្ថាន',
    'vi-VN': 'Kyrgyzstan',
    'th-TH': 'คีร์กีซสถาน',
    'ms-MY': 'Kyrgyzstan',
    'ja-JP': 'キルギス',
    'ko-KR': '키르기스스탄',
    'id-ID': 'Kirgistan',
    'lo-LA': 'ກີຈີສຖານ',
    'hi-IN': 'किर्गिज़स्तान'
  },
  {
    shortName: 'KI',
    tel: '686',
    name: '基里巴斯',
    'zh-CN': '基里巴斯',
    en: 'Kiribati',
    'en-US': 'Kiribati',
    'zh-HK': '吉里巴斯',
    'zh-TW': '吉里巴斯',
    'km-KH': 'គីរីបាទី',
    'vi-VN': 'Kiribati',
    'th-TH': 'คิริบาส',
    'ms-MY': 'Kiribati',
    'ja-JP': 'キリバス',
    'ko-KR': '키리바시',
    'id-ID': 'Kiribati',
    'lo-LA': 'ກີຣິບາຕີ',
    'hi-IN': 'किरिबाती'
  },
  {
    shortName: 'KM',
    tel: '269',
    name: '科摩罗',
    'zh-CN': '科摩罗',
    en: 'Comoros',
    'en-US': 'Comoros',
    'zh-HK': '葛摩',
    'zh-TW': '葛摩',
    'km-KH': 'កូម៉ូរ៉ូ',
    'vi-VN': 'Comoros',
    'th-TH': 'คอโมโรส',
    'ms-MY': 'Comoros',
    'ja-JP': 'コモロ',
    'ko-KR': '코모로',
    'id-ID': 'Komoro',
    'lo-LA': 'ໂຄໂມໂຣສ',
    'hi-IN': 'कोमोरोस'
  },
  {
    shortName: 'KN',
    tel: '1869',
    name: '圣基茨和尼维斯',
    'zh-CN': '圣基茨和尼维斯',
    en: 'St. Kitts & Nevis',
    'en-US': 'St. Kitts & Nevis',
    'zh-HK': '聖克里斯多福及尼維斯',
    'zh-TW': '聖克里斯多福及尼維斯',
    'km-KH': 'សាំងឃីត និងណេវីស',
    'vi-VN': 'St.Kitts & Nevis',
    'th-TH': 'เซนต์คิตส์และเนวิส',
    'ms-MY': 'St. Kitts & Nevis',
    'ja-JP': 'セントクリストファー・ネイビス',
    'ko-KR': '세인트 키츠 네비스',
    'id-ID': 'St.Kitts & Nevis',
    'lo-LA': 'ເຊນຄັສ ແລະ ເນວິສ',
    'hi-IN': 'सेंट किट्स और नेविस'
  },
  {
    shortName: 'KP',
    tel: '850',
    name: '朝鲜',
    'zh-CN': '朝鲜',
    en: 'North Korea',
    'en-US': 'North Korea',
    'zh-HK': '北韓',
    'zh-TW': '北韓',
    'km-KH': 'កូរ៉េខាងជើង',
    'vi-VN': 'Bắc Triều Tiên',
    'th-TH': 'เกาหลีเหนือ',
    'ms-MY': 'Korea Utara',
    'ja-JP': '北朝鮮',
    'ko-KR': '북한',
    'id-ID': 'Korea Utara',
    'lo-LA': 'ເກົາຫຼີເໜືອ',
    'hi-IN': 'उत्तर कोरिया'
  },
  {
    shortName: 'KR',
    tel: '82',
    name: '韩国',
    'zh-CN': '韩国',
    en: 'South Korea',
    'en-US': 'South Korea',
    'zh-HK': '韓國',
    'zh-TW': '韓國',
    'km-KH': 'កូរ៉េខាងត្បូង',
    'vi-VN': 'Hàn Quốc',
    'th-TH': 'เกาหลีใต้',
    'ms-MY': 'Korea Selatan',
    'ja-JP': '韓国',
    'ko-KR': '대한민국',
    'id-ID': 'Korea Selatan',
    'lo-LA': 'ເກົາຫຼີ',
    'hi-IN': 'दक्षिण कोरिया'
  },
  {
    shortName: 'KW',
    tel: '965',
    name: '科威特',
    'zh-CN': '科威特',
    en: 'Kuwait',
    'en-US': 'Kuwait',
    'zh-HK': '科威特',
    'zh-TW': '科威特',
    'km-KH': 'គុយវ៉ែត',
    'vi-VN': 'Cô-oét',
    'th-TH': 'คูเวต',
    'ms-MY': 'Kuwait',
    'ja-JP': 'クウェート',
    'ko-KR': '쿠웨이트',
    'id-ID': 'Kuwait',
    'lo-LA': 'ຄູເວດ',
    'hi-IN': 'कुवैट'
  },
  {
    shortName: 'KY',
    tel: '345',
    name: '开曼群岛',
    'zh-CN': '开曼群岛',
    en: 'Cayman Islands',
    'en-US': 'Cayman Islands',
    'zh-HK': '開曼群島',
    'zh-TW': '開曼群島',
    'km-KH': 'កោះកៃម៉ាន',
    'vi-VN': 'Quần đảo Cayman',
    'th-TH': 'หมู่เกาะเคย์แมน',
    'ms-MY': 'Kepulauan Cayman',
    'ja-JP': 'ケイマン諸島',
    'ko-KR': '케이맨 제도',
    'id-ID': 'Kepulauan Cayman',
    'lo-LA': 'ໝູ່ເກາະເຄແມນ',
    'hi-IN': 'केमन द्वीपसमूह'
  },
  {
    shortName: 'KZ',
    tel: '7',
    name: '哈萨克斯坦',
    'zh-CN': '哈萨克斯坦',
    en: 'Kazakhstan',
    'en-US': 'Kazakhstan',
    'zh-HK': '哈薩克',
    'zh-TW': '哈薩克',
    'km-KH': 'កាហ្សាក់ស្ថាន',
    'vi-VN': 'Kazakhstan',
    'th-TH': 'คาซัคสถาน',
    'ms-MY': 'Kazakhstan',
    'ja-JP': 'カザフスタン',
    'ko-KR': '카자흐스탄',
    'id-ID': 'Kazakstan',
    'lo-LA': 'ຄາຊັກສະຖານ',
    'hi-IN': 'कजाखस्तान'
  },
  {
    shortName: 'LA',
    tel: '856',
    name: '老挝',
    'zh-CN': '老挝',
    en: 'Laos',
    'en-US': 'Laos',
    'zh-HK': '寮國',
    'zh-TW': '寮國',
    'km-KH': 'ប្រទេសឡាវ',
    'vi-VN': 'Lào',
    'th-TH': 'ลาว',
    'ms-MY': 'Laos',
    'ja-JP': 'ラオス',
    'ko-KR': '라오스',
    'id-ID': 'Laos',
    'lo-LA': 'ປະເທດລາວ',
    'hi-IN': 'लाओस'
  },
  {
    shortName: 'LB',
    tel: '961',
    name: '黎巴嫩',
    'zh-CN': '黎巴嫩',
    en: 'Lebanon',
    'en-US': 'Lebanon',
    'zh-HK': '黎巴嫩',
    'zh-TW': '黎巴嫩',
    'km-KH': 'ប្រទេសលីបង់',
    'vi-VN': 'Liban',
    'th-TH': 'เลบานอน',
    'ms-MY': 'Lubnan',
    'ja-JP': 'レバノン',
    'ko-KR': '레바논',
    'id-ID': 'Libanon',
    'lo-LA': 'ເລບານອນ',
    'hi-IN': 'लेबनान'
  },
  {
    shortName: 'LC',
    tel: '1758',
    name: '圣卢西亚',
    'zh-CN': '圣卢西亚',
    en: 'St. Lucia',
    'en-US': 'St. Lucia',
    'zh-HK': '聖露西亞',
    'zh-TW': '聖露西亞',
    'km-KH': 'ផ្លូវ Lucia',
    'vi-VN': 'Thánh Lucia',
    'th-TH': 'เซนต์ลูเซีย',
    'ms-MY': 'St Lucia',
    'ja-JP': 'セントルシア',
    'ko-KR': '세인트루시아',
    'id-ID': 'St.Lucia',
    'lo-LA': 'ເຊນລູເຊຍ',
    'hi-IN': 'सेंट लूसिया'
  },
  {
    shortName: 'LI',
    tel: '423',
    name: '列支敦士登',
    'zh-CN': '列支敦士登',
    en: 'Liechtenstein',
    'en-US': 'Liechtenstein',
    'zh-HK': '列支敦斯登',
    'zh-TW': '列支敦斯登',
    'km-KH': 'លីចតេនស្ទីន',
    'vi-VN': 'Liechtenstein',
    'th-TH': 'ลิกเตนสไตน์',
    'ms-MY': 'Liechtenstein',
    'ja-JP': 'リヒテンシュタイン',
    'ko-KR': '리히텐슈타인',
    'id-ID': 'Liechtenstein',
    'lo-LA': 'ລິກເທນສະໄຕ',
    'hi-IN': 'लिकटेंस्टाइन'
  },
  {
    shortName: 'LK',
    tel: '94',
    name: '斯里兰卡',
    'zh-CN': '斯里兰卡',
    en: 'Sri Lanka',
    'en-US': 'Sri Lanka',
    'zh-HK': '斯里蘭卡',
    'zh-TW': '斯里蘭卡',
    'km-KH': 'ប្រទេសស្រីលង្កា',
    'vi-VN': 'Sri Lanka',
    'th-TH': 'ศรีลังกา',
    'ms-MY': 'Sri Lanka',
    'ja-JP': 'スリランカ',
    'ko-KR': '스리랑카',
    'id-ID': 'Sri Lanka',
    'lo-LA': 'ສີລັງກາ',
    'hi-IN': 'श्रीलंका'
  },
  {
    shortName: 'LR',
    tel: '231',
    name: '利比里亚',
    'zh-CN': '利比里亚',
    en: 'Liberia',
    'en-US': 'Liberia',
    'zh-HK': '賴比瑞亞',
    'zh-TW': '賴比瑞亞',
    'km-KH': 'ប្រទេសលីបេរីយ៉ា',
    'vi-VN': 'Liberia',
    'th-TH': 'ไลบีเรีย',
    'ms-MY': 'Liberia',
    'ja-JP': 'リベリア',
    'ko-KR': '라이베리아',
    'id-ID': 'Liberia',
    'lo-LA': 'ປະເທດໄລບີເລຍ',
    'hi-IN': 'लाइबेरिया'
  },
  {
    shortName: 'LS',
    tel: '266',
    name: '莱索托',
    'zh-CN': '莱索托',
    en: 'Lesotho',
    'en-US': 'Lesotho',
    'zh-HK': '賴索托',
    'zh-TW': '賴索托',
    'km-KH': 'ឡេសូតូ',
    'vi-VN': 'Lesotho',
    'th-TH': 'เลโซโท',
    'ms-MY': 'Lesotho',
    'ja-JP': 'レソト',
    'ko-KR': '레소토',
    'id-ID': 'Lesoto',
    'lo-LA': 'ເລໂຊໂທ',
    'hi-IN': 'लिसोटो'
  },
  {
    shortName: 'LT',
    tel: '370',
    name: '立陶宛',
    'zh-CN': '立陶宛',
    en: 'Lithuania',
    'en-US': 'Lithuania',
    'zh-HK': '立陶宛',
    'zh-TW': '立陶宛',
    'km-KH': 'លីទុយអានី',
    'vi-VN': 'Litva',
    'th-TH': 'ลิทัวเนีย',
    'ms-MY': 'Lithuania',
    'ja-JP': 'リトアニア',
    'ko-KR': '리투아니아',
    'id-ID': 'Lithuania',
    'lo-LA': 'ລິທົວເນຍ',
    'hi-IN': 'लिथुआनिया'
  },
  {
    shortName: 'LU',
    tel: '352',
    name: '卢森堡',
    'zh-CN': '卢森堡',
    en: 'Luxembourg',
    'en-US': 'Luxembourg',
    'zh-HK': '盧森堡',
    'zh-TW': '盧森堡',
    'km-KH': 'លុចសំបួរ',
    'vi-VN': 'Luxembourg',
    'th-TH': 'ลักเซมเบิร์ก',
    'ms-MY': 'Luxembourg',
    'ja-JP': 'ルクセンブルク',
    'ko-KR': '룩셈부르크',
    'id-ID': 'Luksemburg',
    'lo-LA': 'ລັກເຊມເບີກ',
    'hi-IN': 'लक्समबर्ग'
  },
  {
    shortName: 'LV',
    tel: '371',
    name: '拉脱维亚',
    'zh-CN': '拉脱维亚',
    en: 'Latvia',
    'en-US': 'Latvia',
    'zh-HK': '拉脫維亞',
    'zh-TW': '拉脫維亞',
    'km-KH': 'ឡាតវី',
    'vi-VN': 'Latvia',
    'th-TH': 'ลัตเวีย',
    'ms-MY': 'Latvia',
    'ja-JP': 'ラトビア',
    'ko-KR': '라트비아',
    'id-ID': 'Latvia',
    'lo-LA': 'ລັດເວຍ',
    'hi-IN': 'लातविया'
  },
  {
    shortName: 'LY',
    tel: '218',
    name: '利比亚',
    'zh-CN': '利比亚',
    en: 'Libya',
    'en-US': 'Libya',
    'zh-HK': '利比亞',
    'zh-TW': '利比亞',
    'km-KH': 'ប្រទេសលីប៊ី',
    'vi-VN': 'Lybia',
    'th-TH': 'ลิเบีย',
    'ms-MY': 'Libya',
    'ja-JP': 'リビア',
    'ko-KR': '리비아',
    'id-ID': 'Libya',
    'lo-LA': 'ລີເບຍ',
    'hi-IN': 'लीबिया'
  },
  {
    shortName: 'MA',
    tel: '212',
    name: '摩洛哥',
    'zh-CN': '摩洛哥',
    en: 'Morocco',
    'en-US': 'Morocco',
    'zh-HK': '摩洛哥',
    'zh-TW': '摩洛哥',
    'km-KH': 'ម៉ារ៉ុកូ',
    'vi-VN': 'Ma-rốc',
    'th-TH': 'โมร็อกโก',
    'ms-MY': 'Maghribi',
    'ja-JP': 'モロッコ',
    'ko-KR': '모로코 가죽',
    'id-ID': 'Maroko',
    'lo-LA': 'ໂມຣັອກໂຄ',
    'hi-IN': 'मोरक्को'
  },
  {
    shortName: 'MC',
    tel: '377',
    name: '摩纳哥',
    'zh-CN': '摩纳哥',
    en: 'Monaco',
    'en-US': 'Monaco',
    'zh-HK': '摩納哥',
    'zh-TW': '摩納哥',
    'km-KH': 'ម៉ូណាកូ',
    'vi-VN': 'Monaco',
    'th-TH': 'โมนาโก',
    'ms-MY': 'Monaco',
    'ja-JP': 'モナコ',
    'ko-KR': '모나코',
    'id-ID': 'Monako',
    'lo-LA': 'ໂມນາໂກ',
    'hi-IN': 'मोनाको'
  },
  {
    shortName: 'MD',
    tel: '373',
    name: '摩尔多瓦',
    'zh-CN': '摩尔多瓦',
    en: 'Moldova',
    'en-US': 'Moldova',
    'zh-HK': '摩爾多瓦',
    'zh-TW': '摩爾多瓦',
    'km-KH': 'ម៉ុលដាវី',
    'vi-VN': 'Moldavia',
    'th-TH': 'มอลดาเวีย',
    'ms-MY': 'Moldavia',
    'ja-JP': 'モルダビア',
    'ko-KR': '몰다비아',
    'id-ID': 'Moldova',
    'lo-LA': 'ມອນດາເວຍ',
    'hi-IN': 'मोल्डाविया'
  },
  {
    shortName: 'ME',
    tel: '382',
    name: '黑山',
    'zh-CN': '黑山',
    en: 'Montenegro',
    'en-US': 'Montenegro',
    'zh-HK': '蒙特內哥羅',
    'zh-TW': '蒙特內哥羅',
    'km-KH': 'ម៉ុងតេណេហ្គ្រោ',
    'vi-VN': 'Montenegro',
    'th-TH': 'มอนเตเนโกร',
    'ms-MY': 'Montenegro',
    'ja-JP': 'モンテネグロ',
    'ko-KR': '몬테네그로',
    'id-ID': 'Montenegro',
    'lo-LA': 'ມອນເຕເນໂກຣ',
    'hi-IN': 'मोंटेनेग्रो'
  },
  {
    shortName: 'MF',
    tel: '590',
    name: '法属圣马丁',
    'zh-CN': '法属圣马丁',
    en: 'St. Martin',
    'en-US': 'St. Martin',
    'zh-HK': '法屬聖馬丁',
    'zh-TW': '法屬聖馬丁',
    'km-KH': 'ផ្លូវសាំងម៉ាទីន',
    'vi-VN': 'Thánh Martin',
    'th-TH': 'เซนต์มาร์ติน',
    'ms-MY': 'St. Martin',
    'ja-JP': 'セント・マーティン',
    'ko-KR': '세인트 마틴',
    'id-ID': 'St.Martin',
    'lo-LA': 'ເຊນ ມາຕິນ',
    'hi-IN': 'सेंट मार्टिन'
  },
  {
    shortName: 'MG',
    tel: '261',
    name: '马达加斯加',
    'zh-CN': '马达加斯加',
    en: 'Madagascar',
    'en-US': 'Madagascar',
    'zh-HK': '馬達加斯加',
    'zh-TW': '馬達加斯加',
    'km-KH': 'ម៉ាដាហ្គាស្ការ',
    'vi-VN': 'Madagascar',
    'th-TH': 'มาดากัสการ์',
    'ms-MY': 'Madagascar',
    'ja-JP': 'マダガスカル',
    'ko-KR': '마다가스카르',
    'id-ID': 'Madagaskar',
    'lo-LA': 'ມາດາກັສກາ',
    'hi-IN': 'मेडागास्कर'
  },
  {
    shortName: 'MH',
    tel: '692',
    name: '马绍尔群岛',
    'zh-CN': '马绍尔群岛',
    en: 'Marshall Islands',
    'en-US': 'Marshall Islands',
    'zh-HK': '馬紹爾群島',
    'zh-TW': '馬紹爾群島',
    'km-KH': 'កោះ Marshall',
    'vi-VN': 'Quần đảo Marshall',
    'th-TH': 'หมู่เกาะมาร์แชลล์',
    'ms-MY': 'Kepulauan Marshall',
    'ja-JP': 'マーシャル諸島',
    'ko-KR': '마샬 군도',
    'id-ID': 'Kepulauan Marshall',
    'lo-LA': 'ໝູ່ເກາະມາແຊລ',
    'hi-IN': 'मार्शल द्वीपसमूह'
  },
  {
    shortName: 'MK',
    tel: '389',
    name: '马其顿',
    'zh-CN': '马其顿',
    en: 'Macedonia',
    'en-US': 'Macedonia',
    'zh-HK': '馬其頓',
    'zh-TW': '馬其頓',
    'km-KH': 'ម៉ាសេដូនៀ',
    'vi-VN': 'Macedonia',
    'th-TH': 'มาซิโดเนีย',
    'ms-MY': 'Macedonia',
    'ja-JP': 'マケドニア',
    'ko-KR': '마케도니아',
    'id-ID': 'Makedonia',
    'lo-LA': 'ມາເຊໂດເນຍ',
    'hi-IN': 'मैसेडोनिया'
  },
  {
    shortName: 'ML',
    tel: '223',
    name: '马里',
    'zh-CN': '马里',
    en: 'Mali',
    'en-US': 'Mali',
    'zh-HK': '馬裡',
    'zh-TW': '馬裡',
    'km-KH': 'ម៉ាលី',
    'vi-VN': 'Họ đã có',
    'th-TH': 'พวกเขามี',
    'ms-MY': 'Mereka telah',
    'ja-JP': '彼らは持っていました',
    'ko-KR': '그들은 가지고 있었다',
    'id-ID': 'Mereka punya',
    'lo-LA': 'ພວກເຂົາເຈົ້າໄດ້',
    'hi-IN': 'वे थे'
  },
  {
    shortName: 'MM',
    tel: '95',
    name: '缅甸',
    'zh-CN': '缅甸',
    en: 'Myanmar (Burma)',
    'en-US': 'Myanmar (Burma)',
    'zh-HK': '緬甸',
    'zh-TW': '緬甸',
    'km-KH': 'មីយ៉ាន់ម៉ា (ភូមា)',
    'vi-VN': 'Myanmar (Miến Điện)',
    'th-TH': 'เมียนมาร์ (พม่า)',
    'ms-MY': 'Myanmar (Burma)',
    'ja-JP': 'ミャンマー (ビルマ)',
    'ko-KR': '미얀마(버마)',
    'id-ID': 'Myanmar (Burma)',
    'lo-LA': 'ມຽນມາ (ມຽນມາ)',
    'hi-IN': 'म्यांमार (बर्मा)'
  },
  {
    shortName: 'MN',
    tel: '976',
    name: '蒙古',
    'zh-CN': '蒙古',
    en: 'Mongolia',
    'en-US': 'Mongolia',
    'zh-HK': '蒙古',
    'zh-TW': '蒙古',
    'km-KH': 'ម៉ុងហ្គោលី',
    'vi-VN': 'Mông Cổ',
    'th-TH': 'มองโกเลีย',
    'ms-MY': 'Mongolia',
    'ja-JP': 'モンゴル',
    'ko-KR': '몽골리아',
    'id-ID': 'Mongolia',
    'lo-LA': 'ມົງໂກເລຍ',
    'hi-IN': 'मंगोलिया'
  },
  {
    shortName: 'MO',
    tel: '853',
    name: '中国澳门',
    'zh-CN': '中国澳门',
    en: 'Macau',
    'en-US': 'Macau',
    'zh-HK': '中國澳門',
    'zh-TW': '中國澳門',
    'km-KH': 'ម៉ាកាវ',
    'vi-VN': 'Ma Cao',
    'th-TH': 'มาเก๊า',
    'ms-MY': 'Macau',
    'ja-JP': 'マカオ',
    'ko-KR': '마카오',
    'id-ID': 'Makau',
    'lo-LA': 'ມາເກົາ',
    'hi-IN': 'मकाउ'
  },
  {
    shortName: 'MP',
    tel: '1670',
    name: '北马里亚纳群岛',
    'zh-CN': '北马里亚纳群岛',
    en: 'Northern Mariana Islands',
    'en-US': 'Northern Mariana Islands',
    'zh-HK': '北馬裡亞納群島',
    'zh-TW': '北馬裡亞納群島',
    'km-KH': 'កោះម៉ារៀណាខាងជើង',
    'vi-VN': 'Quần đảo Bắc Mariana',
    'th-TH': 'หมู่เกาะนอร์เทิร์นมาเรียนา',
    'ms-MY': 'Kepulauan Mariana Utara',
    'ja-JP': '北マリアナ諸島',
    'ko-KR': '북마리아나 제도',
    'id-ID': 'Kepulauan Mariana Utara',
    'lo-LA': 'ໝູ່ເກາະມາຣີນາເໜືອ',
    'hi-IN': 'उत्तरी मारियाना द्वीप समूह'
  },
  {
    shortName: 'MQ',
    tel: '596',
    name: '马提尼克',
    'zh-CN': '马提尼克',
    en: 'Martinique',
    'en-US': 'Martinique',
    'zh-HK': '馬提尼克',
    'zh-TW': '馬提尼克',
    'km-KH': 'ម៉ាទីនីក',
    'vi-VN': 'Martinique',
    'th-TH': 'มาร์ตินีก',
    'ms-MY': 'Martinique',
    'ja-JP': 'マルティニーク',
    'ko-KR': '마르티니크',
    'id-ID': 'Martinik',
    'lo-LA': 'ມາຕິນິກ',
    'hi-IN': 'मार्टीनिक'
  },
  {
    shortName: 'MR',
    tel: '222',
    name: '毛里塔尼亚',
    'zh-CN': '毛里塔尼亚',
    en: 'Mauritania',
    'en-US': 'Mauritania',
    'zh-HK': '茅利塔尼亞',
    'zh-TW': '茅利塔尼亞',
    'km-KH': 'ម៉ូរីតានី',
    'vi-VN': 'Mauritanie',
    'th-TH': 'มอริเตเนีย',
    'ms-MY': 'Mauritania',
    'ja-JP': 'モーリタニア',
    'ko-KR': '모리타니',
    'id-ID': 'Mauritania',
    'lo-LA': 'ມໍຣິຕາເນຍ',
    'hi-IN': 'मॉरिटानिया'
  },
  {
    shortName: 'MS',
    tel: '1664',
    name: '蒙特塞拉特',
    'zh-CN': '蒙特塞拉特',
    en: 'Montserrat',
    'en-US': 'Montserrat',
    'zh-HK': '蒙特塞拉特',
    'zh-TW': '蒙特塞拉特',
    'km-KH': 'ម៉ុងសឺរ៉ាត់',
    'vi-VN': 'Montserrat',
    'th-TH': 'มอนต์เซอร์รัต',
    'ms-MY': 'Montserrat',
    'ja-JP': 'モントセラト',
    'ko-KR': '몬세라트',
    'id-ID': 'Montserrat',
    'lo-LA': 'Montserrat',
    'hi-IN': 'मोंटेसेराट'
  },
  {
    shortName: 'MT',
    tel: '356',
    name: '马耳他',
    'zh-CN': '马耳他',
    en: 'Malta',
    'en-US': 'Malta',
    'zh-HK': '馬爾他',
    'zh-TW': '馬爾他',
    'km-KH': 'ម៉ាល់តា',
    'vi-VN': 'Malta',
    'th-TH': 'มอลตา',
    'ms-MY': 'Malta',
    'ja-JP': 'マルタ',
    'ko-KR': '몰타',
    'id-ID': 'Malta',
    'lo-LA': 'ມາລຕາ',
    'hi-IN': 'माल्टा'
  },
  {
    shortName: 'MU',
    tel: '230',
    name: '毛里求斯',
    'zh-CN': '毛里求斯',
    en: 'Mauritius',
    'en-US': 'Mauritius',
    'zh-HK': '模里西斯',
    'zh-TW': '模里西斯',
    'km-KH': 'ម៉ូរីស',
    'vi-VN': 'Mô-ri-xơ',
    'th-TH': 'มอริเชียส',
    'ms-MY': 'Mauritius',
    'ja-JP': 'モーリシャス',
    'ko-KR': '모리셔스',
    'id-ID': 'Mauritius',
    'lo-LA': 'ມໍຣິຕິສ',
    'hi-IN': 'मॉरीशस'
  },
  {
    shortName: 'MV',
    tel: '960',
    name: '马尔代夫',
    'zh-CN': '马尔代夫',
    en: 'Maldives',
    'en-US': 'Maldives',
    'zh-HK': '馬爾地夫',
    'zh-TW': '馬爾地夫',
    'km-KH': 'ម៉ាល់ឌីវ',
    'vi-VN': 'Maldives',
    'th-TH': 'มัลดีฟส์',
    'ms-MY': 'Maldives',
    'ja-JP': 'モルディブ',
    'ko-KR': '몰디브',
    'id-ID': 'Maladewa',
    'lo-LA': 'Maldives',
    'hi-IN': 'मालदीव'
  },
  {
    shortName: 'MW',
    tel: '265',
    name: '马拉维',
    'zh-CN': '马拉维',
    en: 'Malawi',
    'en-US': 'Malawi',
    'zh-HK': '馬拉威',
    'zh-TW': '馬拉威',
    'km-KH': 'ម៉ាឡាវី',
    'vi-VN': 'Malawi',
    'th-TH': 'มาลาวี',
    'ms-MY': 'Malawi',
    'ja-JP': 'マラウイ',
    'ko-KR': '말라위',
    'id-ID': 'Malawi',
    'lo-LA': 'ມາລາວີ',
    'hi-IN': 'मलावी'
  },
  {
    shortName: 'MX',
    tel: '52',
    name: '墨西哥',
    'zh-CN': '墨西哥',
    en: 'Mexico',
    'en-US': 'Mexico',
    'zh-HK': '墨西哥',
    'zh-TW': '墨西哥',
    'km-KH': 'ម៉ិកស៊ិក',
    'vi-VN': 'México',
    'th-TH': 'เม็กซิโก',
    'ms-MY': 'Mexico',
    'ja-JP': 'メキシコ',
    'ko-KR': '멕시코',
    'id-ID': 'Meksiko',
    'lo-LA': 'ເມັກຊິໂກ',
    'hi-IN': 'मेक्सिको'
  },
  {
    shortName: 'MZ',
    tel: '258',
    name: '莫桑比克',
    'zh-CN': '莫桑比克',
    en: 'Mozambique',
    'en-US': 'Mozambique',
    'zh-HK': '莫三比克',
    'zh-TW': '莫三比克',
    'km-KH': 'ម៉ូសំប៊ិក',
    'vi-VN': 'Mozambique',
    'th-TH': 'โมซัมบิก',
    'ms-MY': 'Mozambique',
    'ja-JP': 'モザンビーク',
    'ko-KR': '모잠비크',
    'id-ID': 'Mozambik',
    'lo-LA': 'ໂມຊຳບິກ',
    'hi-IN': 'मोज़ाम्बिक'
  },
  {
    shortName: 'NA',
    tel: '264',
    name: '纳米比亚',
    'zh-CN': '纳米比亚',
    en: 'Namibia',
    'en-US': 'Namibia',
    'zh-HK': '納米比亞',
    'zh-TW': '納米比亞',
    'km-KH': 'ណាមីប៊ី',
    'vi-VN': 'Namibia',
    'th-TH': 'นามิเบีย',
    'ms-MY': 'Namibia',
    'ja-JP': 'ナミビア',
    'ko-KR': '나미비아',
    'id-ID': 'Namibia',
    'lo-LA': 'ນາມິເບຍ',
    'hi-IN': 'नामिबिया'
  },
  {
    shortName: 'NC',
    tel: '687',
    name: '新喀里多尼亚',
    'zh-CN': '新喀里多尼亚',
    en: 'New Caledonia',
    'en-US': 'New Caledonia',
    'zh-HK': '新喀裡多尼亞',
    'zh-TW': '新喀裡多尼亞',
    'km-KH': 'កាឡេដូនៀថ្មី',
    'vi-VN': 'Tân Caledonia',
    'th-TH': 'นิวแคลิโดเนีย',
    'ms-MY': 'Kaledonia Baru',
    'ja-JP': 'ニューカレドニア',
    'ko-KR': '뉴칼레도니아',
    'id-ID': 'Kaledonia Baru',
    'lo-LA': 'ນິວຄາລິໂດເນຍ',
    'hi-IN': 'नया केलडोनिया'
  },
  {
    shortName: 'NE',
    tel: '227',
    name: '尼日尔',
    'zh-CN': '尼日尔',
    en: 'Niger',
    'en-US': 'Niger',
    'zh-HK': '尼日',
    'zh-TW': '尼日',
    'km-KH': 'នីហ្សេរីយ៉ា',
    'vi-VN': 'Niger',
    'th-TH': 'ไนเจอร์',
    'ms-MY': 'Niger',
    'ja-JP': 'ニジェール',
    'ko-KR': '니제르',
    'id-ID': 'Nigeria',
    'lo-LA': 'ໄນເຈີ',
    'hi-IN': 'नाइजर'
  },
  {
    shortName: 'NF',
    tel: '672',
    name: '诺福克岛',
    'zh-CN': '诺福克岛',
    en: 'Norfolk Island',
    'en-US': 'Norfolk Island',
    'zh-HK': '諾福克島',
    'zh-TW': '諾福克島',
    'km-KH': 'កោះណ័រហ្វក់',
    'vi-VN': 'Đảo Norfolk',
    'th-TH': 'เกาะนอร์ฟอล์ก',
    'ms-MY': 'Pulau Norfolk',
    'ja-JP': 'ノーフォーク島',
    'ko-KR': '노퍽 섬',
    'id-ID': 'Pulau Norfolk',
    'lo-LA': 'ເກາະ Norfolk',
    'hi-IN': 'नॉरफ़ॉक द्वीप'
  },
  {
    shortName: 'NG',
    tel: '234',
    name: '尼日利亚',
    'zh-CN': '尼日利亚',
    en: 'Nigeria',
    'en-US': 'Nigeria',
    'zh-HK': '奈及利亞',
    'zh-TW': '奈及利亞',
    'km-KH': 'នីហ្សេរីយ៉ា',
    'vi-VN': 'Nigeria',
    'th-TH': 'ไนจีเรีย',
    'ms-MY': 'Nigeria',
    'ja-JP': 'ナイジェリア',
    'ko-KR': '나이지리아',
    'id-ID': 'Nigeria',
    'lo-LA': 'ໄນຈີເຣຍ',
    'hi-IN': 'नाइजीरिया'
  },
  {
    shortName: 'NI',
    tel: '505',
    name: '尼加拉瓜',
    'zh-CN': '尼加拉瓜',
    en: 'Nicaragua',
    'en-US': 'Nicaragua',
    'zh-HK': '尼加拉瓜',
    'zh-TW': '尼加拉瓜',
    'km-KH': 'នីការ៉ាហ្គា',
    'vi-VN': 'Nicaragua',
    'th-TH': 'นิการากัว',
    'ms-MY': 'Nicaragua',
    'ja-JP': 'ニカラグア',
    'ko-KR': '니카라과',
    'id-ID': 'Nikaragua',
    'lo-LA': 'ນິກາຣາກົວ',
    'hi-IN': 'निकारागुआ'
  },
  {
    shortName: 'NL',
    tel: '31',
    name: '荷兰',
    'zh-CN': '荷兰',
    en: 'Netherlands',
    'en-US': 'Netherlands',
    'zh-HK': '荷蘭',
    'zh-TW': '荷蘭',
    'km-KH': 'ហូឡង់',
    'vi-VN': 'Hà Lan',
    'th-TH': 'เนเธอร์แลนด์',
    'ms-MY': 'Belanda',
    'ja-JP': 'オランダ',
    'ko-KR': '네덜란드',
    'id-ID': 'Belanda',
    'lo-LA': 'ເນເທີແລນ',
    'hi-IN': 'नीदरलैंड'
  },
  {
    shortName: 'NO',
    tel: '47',
    name: '挪威',
    'zh-CN': '挪威',
    en: 'Norway',
    'en-US': 'Norway',
    'zh-HK': '挪威',
    'zh-TW': '挪威',
    'km-KH': 'ប្រទេសន័រវេស',
    'vi-VN': 'Na Uy',
    'th-TH': 'นอร์เวย์',
    'ms-MY': 'Norway',
    'ja-JP': 'ノルウェー',
    'ko-KR': '노르웨이',
    'id-ID': 'Norwegia',
    'lo-LA': 'ນໍເວ',
    'hi-IN': 'नॉर्वे'
  },
  {
    shortName: 'NP',
    tel: '977',
    name: '尼泊尔',
    'zh-CN': '尼泊尔',
    en: 'Nepal',
    'en-US': 'Nepal',
    'zh-HK': '尼泊爾',
    'zh-TW': '尼泊爾',
    'km-KH': 'នេប៉ាល់',
    'vi-VN': 'Nepal',
    'th-TH': 'เนปาล',
    'ms-MY': 'Nepal',
    'ja-JP': 'ネパール',
    'ko-KR': '네팔',
    'id-ID': 'Nepal',
    'lo-LA': 'ເນປານ',
    'hi-IN': 'नेपाल'
  },
  {
    shortName: 'NR',
    tel: '674',
    name: '瑙鲁',
    'zh-CN': '瑙鲁',
    en: 'Nauru',
    'en-US': 'Nauru',
    'zh-HK': '諾魯',
    'zh-TW': '諾魯',
    'km-KH': 'ណៅរូ',
    'vi-VN': 'Nauru',
    'th-TH': 'นาอูรู',
    'ms-MY': 'Nauru',
    'ja-JP': 'ナウル',
    'ko-KR': '나우루',
    'id-ID': 'Nauru',
    'lo-LA': 'ນາອູຣູ',
    'hi-IN': 'नाउरू'
  },
  {
    shortName: 'NU',
    tel: '683',
    name: '纽埃',
    'zh-CN': '纽埃',
    en: 'Niue',
    'en-US': 'Niue',
    'zh-HK': '紐埃',
    'zh-TW': '紐埃',
    'km-KH': 'នីយូ',
    'vi-VN': 'Niue',
    'th-TH': 'นีอูเอ',
    'ms-MY': 'Niue',
    'ja-JP': 'ニウエ',
    'ko-KR': '니우에',
    'id-ID': 'Niue',
    'lo-LA': 'ນີວ',
    'hi-IN': 'नियू'
  },
  {
    shortName: 'NZ',
    tel: '64',
    name: '新西兰',
    'zh-CN': '新西兰',
    en: 'New Zealand',
    'en-US': 'New Zealand',
    'zh-HK': '紐西蘭',
    'zh-TW': '紐西蘭',
    'km-KH': 'នូវែលសេឡង់',
    'vi-VN': 'New Zealand',
    'th-TH': 'นิวซีแลนด์',
    'ms-MY': 'New Zealand',
    'ja-JP': 'ニュージーランド',
    'ko-KR': '뉴질랜드',
    'id-ID': 'Selandia Baru',
    'lo-LA': 'ນິວຊີແລນ',
    'hi-IN': 'न्यूज़ीलैंड'
  },
  {
    shortName: 'OM',
    tel: '968',
    name: '阿曼',
    'zh-CN': '阿曼',
    en: 'Oman',
    'en-US': 'Oman',
    'zh-HK': '阿曼',
    'zh-TW': '阿曼',
    'km-KH': 'ប្រទេសអូមែន',
    'vi-VN': 'của riêng tôi',
    'th-TH': 'ของฉันเอง',
    'ms-MY': 'saya sendiri',
    'ja-JP': '私自身の',
    'ko-KR': '내 자신의',
    'id-ID': 'Milik saya',
    'lo-LA': 'ຂອງຂ້ອຍເອງ',
    'hi-IN': 'अपने मन'
  },
  {
    shortName: 'PA',
    tel: '507',
    name: '巴拿马',
    'zh-CN': '巴拿马',
    en: 'Panama',
    'en-US': 'Panama',
    'zh-HK': '巴拿馬',
    'zh-TW': '巴拿馬',
    'km-KH': 'ប៉ាណាម៉ា',
    'vi-VN': 'Panama',
    'th-TH': 'ปานามา',
    'ms-MY': 'Panama',
    'ja-JP': 'パナマ',
    'ko-KR': '파나마',
    'id-ID': 'Panama',
    'lo-LA': 'ປານາມາ',
    'hi-IN': 'पनामा'
  },
  {
    shortName: 'PE',
    tel: '51',
    name: '秘鲁',
    'zh-CN': '秘鲁',
    en: 'Peru',
    'en-US': 'Peru',
    'zh-HK': '秘魯',
    'zh-TW': '秘魯',
    'km-KH': 'ប្រទេសប៉េរូ',
    'vi-VN': 'Peru',
    'th-TH': 'เปรู',
    'ms-MY': 'Peru',
    'ja-JP': 'ペルー',
    'ko-KR': '페루',
    'id-ID': 'Peru',
    'lo-LA': 'ເປຣູ',
    'hi-IN': 'पेरू'
  },
  {
    shortName: 'PF',
    tel: '689',
    name: '法属波利尼西亚',
    'zh-CN': '法属波利尼西亚',
    en: 'French Polynesia',
    'en-US': 'French Polynesia',
    'zh-HK': '法屬玻里尼西亞',
    'zh-TW': '法屬玻里尼西亞',
    'km-KH': 'ប៉ូលីនេស៊ីបារាំង',
    'vi-VN': 'Polynesia thuộc Pháp',
    'th-TH': 'เฟรนช์โปลินีเซีย',
    'ms-MY': 'Polinesia Perancis',
    'ja-JP': 'フランス領ポリネシア',
    'ko-KR': '프랑스령 폴리네시아',
    'id-ID': 'Polinesia Perancis',
    'lo-LA': 'ຝຣັ່ງໂພລີນີເຊຍ',
    'hi-IN': 'फ़्रेंच पोलिनेशिया'
  },
  {
    shortName: 'PG',
    tel: '675',
    name: '巴布亚新几内亚',
    'zh-CN': '巴布亚新几内亚',
    en: 'Papua New Guinea',
    'en-US': 'Papua New Guinea',
    'zh-HK': '巴布亞紐幾內亞',
    'zh-TW': '巴布亞紐幾內亞',
    'km-KH': 'ប៉ាពួញូហ្គីណេ',
    'vi-VN': 'Papua New Guinea',
    'th-TH': 'ปาปัวนิวกินี',
    'ms-MY': 'Papua New Guinea',
    'ja-JP': 'パプアニューギニア',
    'ko-KR': '파푸아뉴기니',
    'id-ID': 'Papua Nugini',
    'lo-LA': 'ປາປົວນິວກີນີ',
    'hi-IN': 'पापुआ न्यू गिनी'
  },
  {
    shortName: 'PH',
    tel: '63',
    name: '菲律宾',
    'zh-CN': '菲律宾',
    en: 'Philippines',
    'en-US': 'Philippines',
    'zh-HK': '菲律賓',
    'zh-TW': '菲律賓',
    'km-KH': 'ហ្វីលីពីន',
    'vi-VN': 'Philippin',
    'th-TH': 'ฟิลิปปินส์',
    'ms-MY': 'Filipina',
    'ja-JP': 'フィリピン',
    'ko-KR': '필리핀 제도',
    'id-ID': 'Filipina',
    'lo-LA': 'ຟີລິບປິນ',
    'hi-IN': 'फिलिपींस'
  },
  {
    shortName: 'PK',
    tel: '92',
    name: '巴基斯坦',
    'zh-CN': '巴基斯坦',
    en: 'Pakistan',
    'en-US': 'Pakistan',
    'zh-HK': '巴基斯坦',
    'zh-TW': '巴基斯坦',
    'km-KH': 'ប៉ាគីស្ថាន',
    'vi-VN': 'Pakistan',
    'th-TH': 'ปากีสถาน',
    'ms-MY': 'Pakistan',
    'ja-JP': 'パキスタン',
    'ko-KR': '파키스탄',
    'id-ID': 'Pakistan',
    'lo-LA': 'ປາກິສຖານ',
    'hi-IN': 'पाकिस्तान'
  },
  {
    shortName: 'PL',
    tel: '48',
    name: '波兰',
    'zh-CN': '波兰',
    en: 'Poland',
    'en-US': 'Poland',
    'zh-HK': '波蘭',
    'zh-TW': '波蘭',
    'km-KH': 'ប៉ូឡូញ',
    'vi-VN': 'Ba Lan',
    'th-TH': 'โปแลนด์',
    'ms-MY': 'Poland',
    'ja-JP': 'ポーランド',
    'ko-KR': '폴란드',
    'id-ID': 'Polandia',
    'lo-LA': 'ໂປແລນ',
    'hi-IN': 'पोलैंड'
  },
  {
    shortName: 'PM',
    tel: '508',
    name: '圣皮埃尔和密克隆群岛',
    'zh-CN': '圣皮埃尔和密克隆群岛',
    en: 'St. Pierre & Miquelon',
    'en-US': 'St. Pierre & Miquelon',
    'zh-HK': '聖皮埃爾和密克隆群島',
    'zh-TW': '聖皮埃爾和密克隆群島',
    'km-KH': 'ផ្លូវ ផៀរ និងមីខ្វលន់',
    'vi-VN': 'Thánh Pierre & Miquelon',
    'th-TH': 'แซงปีแยร์และมีเกอลง',
    'ms-MY': 'St. Pierre & Miquelon',
    'ja-JP': 'サンピエール島・ミクロン島',
    'ko-KR': '생 피에르 앤 미클롱',
    'id-ID': 'St.Pierre & Miquelon',
    'lo-LA': 'ເຊນ Pierre & Miquelon',
    'hi-IN': 'सेंट पियरे और मिकेलॉन'
  },
  {
    shortName: 'PN',
    tel: '872',
    name: '皮特凯恩群岛',
    'zh-CN': '皮特凯恩群岛',
    en: 'Pitcairn Islands',
    'en-US': 'Pitcairn Islands',
    'zh-HK': '皮特凱恩群島',
    'zh-TW': '皮特凱恩群島',
    'km-KH': 'កោះ ភីតខែន',
    'vi-VN': 'Quần đảo Pitcairn',
    'th-TH': 'หมู่เกาะพิตแคร์น',
    'ms-MY': 'Kepulauan Pitcairn',
    'ja-JP': 'ピトケアン諸島',
    'ko-KR': '핏케언 제도',
    'id-ID': 'Kepulauan Pitcairn',
    'lo-LA': 'ໝູ່ເກາະ Pitcairn',
    'hi-IN': 'पिटकेर्न द्वीप'
  },
  {
    shortName: 'PR',
    tel: '1939',
    name: '波多黎各',
    'zh-CN': '波多黎各',
    en: 'Puerto Rico',
    'en-US': 'Puerto Rico',
    'zh-HK': '波多黎各',
    'zh-TW': '波多黎各',
    'km-KH': 'ព័រតូរីកូ',
    'vi-VN': 'Puerto Rico',
    'th-TH': 'เปอร์โตริโก',
    'ms-MY': 'Puerto Rico',
    'ja-JP': 'プエルトリコ',
    'ko-KR': '푸에르토리코',
    'id-ID': 'Puerto Riko',
    'lo-LA': 'ເປີໂຕຣິໂກ',
    'hi-IN': 'प्यूर्टो रिको'
  },
  {
    shortName: 'PS',
    tel: '970',
    name: '巴勒斯坦领土',
    'zh-CN': '巴勒斯坦领土',
    en: 'Palestinian Territories',
    'en-US': 'Palestinian Territories',
    'zh-HK': '巴勒斯坦領土',
    'zh-TW': '巴勒斯坦領土',
    'km-KH': 'ដែនដីប៉ាឡេស្ទីន',
    'vi-VN': 'Lãnh thổ Palestine',
    'th-TH': 'ดินแดนปาเลสไตน์',
    'ms-MY': 'Wilayah Palestin',
    'ja-JP': 'パレスチナ自治区',
    'ko-KR': '팔레스타인 영토',
    'id-ID': 'Wilayah Palestina',
    'lo-LA': 'ອານາເຂດຂອງ Palestinian',
    'hi-IN': 'फ़िलिस्तीनी क्षेत्र'
  },
  {
    shortName: 'PT',
    tel: '351',
    name: '葡萄牙',
    'zh-CN': '葡萄牙',
    en: 'Portugal',
    'en-US': 'Portugal',
    'zh-HK': '葡萄牙',
    'zh-TW': '葡萄牙',
    'km-KH': 'ព័រទុយហ្គាល់',
    'vi-VN': 'Bồ Đào Nha',
    'th-TH': 'โปรตุเกส',
    'ms-MY': 'Portugal',
    'ja-JP': 'ポルトガル',
    'ko-KR': '포르투갈',
    'id-ID': 'Portugal',
    'lo-LA': 'ປອກຕຸຍການ',
    'hi-IN': 'पुर्तगाल'
  },
  {
    shortName: 'PW',
    tel: '680',
    name: '帕劳',
    'zh-CN': '帕劳',
    en: 'Palau',
    'en-US': 'Palau',
    'zh-HK': '帛琉',
    'zh-TW': '帛琉',
    'km-KH': 'ផាល័រ',
    'vi-VN': 'cung điện',
    'th-TH': 'พระราชวัง',
    'ms-MY': 'istana',
    'ja-JP': '宮殿',
    'ko-KR': '궁전',
    'id-ID': 'istana',
    'lo-LA': 'ພະລາຊະວັງ',
    'hi-IN': 'पैलेस'
  },
  {
    shortName: 'PY',
    tel: '595',
    name: '巴拉圭',
    'zh-CN': '巴拉圭',
    en: 'Paraguay',
    'en-US': 'Paraguay',
    'zh-HK': '巴拉圭',
    'zh-TW': '巴拉圭',
    'km-KH': 'ប៉ារ៉ាហ្គាយ',
    'vi-VN': 'Paraguay',
    'th-TH': 'ปารากวัย',
    'ms-MY': 'Paraguay',
    'ja-JP': 'パラグアイ',
    'ko-KR': '파라과이',
    'id-ID': 'Paraguay',
    'lo-LA': 'ປາຣາກວາຍ',
    'hi-IN': 'परागुआ'
  },
  {
    shortName: 'QA',
    tel: '974',
    name: '卡塔尔',
    'zh-CN': '卡塔尔',
    en: 'Qatar',
    'en-US': 'Qatar',
    'zh-HK': '卡達',
    'zh-TW': '卡達',
    'km-KH': 'កាតា',
    'vi-VN': 'Qatar',
    'th-TH': 'กาตาร์',
    'ms-MY': 'Qatar',
    'ja-JP': 'カタール',
    'ko-KR': '카타르',
    'id-ID': 'Qatar',
    'lo-LA': 'ກາຕາ',
    'hi-IN': 'कतर'
  },
  {
    shortName: 'RE',
    tel: '262',
    name: '留尼汪',
    'zh-CN': '留尼汪',
    en: 'Réunion',
    'en-US': 'Réunion',
    'zh-HK': '留尼汪',
    'zh-TW': '留尼汪',
    'km-KH': 'រ៉េណាន',
    'vi-VN': 'Cuộc họp',
    'th-TH': 'การประชุม',
    'ms-MY': 'Mesyuarat',
    'ja-JP': 'ミーティング',
    'ko-KR': '회의',
    'id-ID': 'Pertemuan',
    'lo-LA': 'ກອງປະຊຸມ',
    'hi-IN': 'बैठक'
  },
  {
    shortName: 'RO',
    tel: '40',
    name: '罗马尼亚',
    'zh-CN': '罗马尼亚',
    en: 'Romania',
    'en-US': 'Romania',
    'zh-HK': '羅馬尼亞',
    'zh-TW': '羅馬尼亞',
    'km-KH': 'រូម៉ានី',
    'vi-VN': 'Rumani',
    'th-TH': 'โรมาเนีย',
    'ms-MY': 'Romania',
    'ja-JP': 'ルーマニア',
    'ko-KR': '루마니아',
    'id-ID': 'Rumania',
    'lo-LA': 'ໂຣມາເນຍ',
    'hi-IN': 'रोमानिया'
  },
  {
    shortName: 'RS',
    tel: '381',
    name: '塞尔维亚',
    'zh-CN': '塞尔维亚',
    en: 'Serbia',
    'en-US': 'Serbia',
    'zh-HK': '塞爾維亞',
    'zh-TW': '塞爾維亞',
    'km-KH': 'ស៊ែប៊ី',
    'vi-VN': 'Serbia',
    'th-TH': 'เซอร์เบีย',
    'ms-MY': 'Serbia',
    'ja-JP': 'セルビア',
    'ko-KR': '세르비아',
    'id-ID': 'Serbia',
    'lo-LA': 'ເຊີເບຍ',
    'hi-IN': 'सर्बिया'
  },
  {
    shortName: 'RU',
    tel: '7',
    name: '俄罗斯',
    'zh-CN': '俄罗斯',
    en: 'Russia',
    'en-US': 'Russia',
    'zh-HK': '俄羅斯',
    'zh-TW': '俄羅斯',
    'km-KH': 'រុស្សី',
    'vi-VN': 'Nga',
    'th-TH': 'รัสเซีย',
    'ms-MY': 'Rusia',
    'ja-JP': 'ロシア',
    'ko-KR': '러시아 제국',
    'id-ID': 'Rusia',
    'lo-LA': 'ລັດເຊຍ',
    'hi-IN': 'रूस'
  },
  {
    shortName: 'RW',
    tel: '250',
    name: '卢旺达',
    'zh-CN': '卢旺达',
    en: 'Rwanda',
    'en-US': 'Rwanda',
    'zh-HK': '盧安達',
    'zh-TW': '盧安達',
    'km-KH': 'រវ៉ាន់ដា',
    'vi-VN': 'Rwanda',
    'th-TH': 'รวันดา',
    'ms-MY': 'Rwanda',
    'ja-JP': 'ルワンダ',
    'ko-KR': '르완다',
    'id-ID': 'Rwanda',
    'lo-LA': 'ຣວັນດາ',
    'hi-IN': 'रवांडा'
  },
  {
    shortName: 'SA',
    tel: '966',
    name: '沙特阿拉伯',
    'zh-CN': '沙特阿拉伯',
    en: 'Saudi Arabia',
    'en-US': 'Saudi Arabia',
    'zh-HK': '沙烏地阿拉伯',
    'zh-TW': '沙烏地阿拉伯',
    'km-KH': 'អារ៉ាប៊ីសាអូឌីត',
    'vi-VN': 'Ả Rập Saudi',
    'th-TH': 'ซาอุดีอาระเบีย',
    'ms-MY': 'Arab Saudi',
    'ja-JP': 'サウジアラビア',
    'ko-KR': '사우디아라비아',
    'id-ID': 'Arab Saudi',
    'lo-LA': 'ຊາອຸດີອາຣາເບຍ',
    'hi-IN': 'सऊदी अरब'
  },
  {
    shortName: 'SB',
    tel: '677',
    name: '所罗门群岛',
    'zh-CN': '所罗门群岛',
    en: 'Solomon Islands',
    'en-US': 'Solomon Islands',
    'zh-HK': '索羅門群島',
    'zh-TW': '索羅門群島',
    'km-KH': 'កោះសាឡូម៉ូន',
    'vi-VN': 'Quần đảo Solomon',
    'th-TH': 'หมู่เกาะโซโลมอน',
    'ms-MY': 'Kepulauan Solomon',
    'ja-JP': 'ソロモン諸島',
    'ko-KR': '솔로몬 제도',
    'id-ID': 'Kepulauan Solomon',
    'lo-LA': 'ໝູ່ເກາະໂຊໂລມອນ',
    'hi-IN': 'सोलोमन द्वीप'
  },
  {
    shortName: 'SC',
    tel: '248',
    name: '塞舌尔',
    'zh-CN': '塞舌尔',
    en: 'Seychelles',
    'en-US': 'Seychelles',
    'zh-HK': '塞席爾',
    'zh-TW': '塞席爾',
    'km-KH': 'សីស្ហែល',
    'vi-VN': 'Seychelles',
    'th-TH': 'เซเชลส์',
    'ms-MY': 'Seychelles',
    'ja-JP': 'セーシェル',
    'ko-KR': '세이셸',
    'id-ID': 'Seychelles',
    'lo-LA': 'Seychelles',
    'hi-IN': 'सेशल्स'
  },
  {
    shortName: 'SD',
    tel: '249',
    name: '苏丹',
    'zh-CN': '苏丹',
    en: 'Sudan',
    'en-US': 'Sudan',
    'zh-HK': '蘇丹',
    'zh-TW': '蘇丹',
    'km-KH': 'ស៊ូដង់',
    'vi-VN': 'Sudan',
    'th-TH': 'ซูดาน',
    'ms-MY': 'Sudan',
    'ja-JP': 'スーダン',
    'ko-KR': '수단',
    'id-ID': 'Sudan',
    'lo-LA': 'ຊູດານ',
    'hi-IN': 'सूडान'
  },
  {
    shortName: 'SE',
    tel: '46',
    name: '瑞典',
    'zh-CN': '瑞典',
    en: 'Sweden',
    'en-US': 'Sweden',
    'zh-HK': '瑞典',
    'zh-TW': '瑞典',
    'km-KH': 'ស៊ុយអែត',
    'vi-VN': 'Thụy Điển',
    'th-TH': 'สวีเดน',
    'ms-MY': 'Sweden',
    'ja-JP': 'スウェーデン',
    'ko-KR': '스웨덴',
    'id-ID': 'Swedia',
    'lo-LA': 'ສວີເດນ',
    'hi-IN': 'स्वीडन'
  },
  {
    shortName: 'SH',
    tel: '290',
    name: '圣赫勒拿',
    'zh-CN': '圣赫勒拿',
    en: 'St. Helena',
    'en-US': 'St. Helena',
    'zh-HK': '聖赫勒拿',
    'zh-TW': '聖赫勒拿',
    'km-KH': 'ផ្លូវហេលេណា',
    'vi-VN': 'Thánh Helena',
    'th-TH': 'เซนต์เฮเลน่า',
    'ms-MY': 'St Helena',
    'ja-JP': 'セントヘレナ',
    'ko-KR': '세인트 헬레나',
    'id-ID': 'St.Helena',
    'lo-LA': 'ເຊນ ເຮເລນາ',
    'hi-IN': 'सेंट हेलेना'
  },
  {
    shortName: 'SI',
    tel: '386',
    name: '斯洛文尼亚',
    'zh-CN': '斯洛文尼亚',
    en: 'Slovenia',
    'en-US': 'Slovenia',
    'zh-HK': '斯洛維尼亞',
    'zh-TW': '斯洛維尼亞',
    'km-KH': 'ស្លូវេនី',
    'vi-VN': 'Slovenia',
    'th-TH': 'สโลวีเนีย',
    'ms-MY': 'Slovenia',
    'ja-JP': 'スロベニア',
    'ko-KR': '슬로베니아',
    'id-ID': 'Slovenia',
    'lo-LA': 'ສະໂລເວເນຍ',
    'hi-IN': 'स्लोवेनिया'
  },
  {
    shortName: 'SJ',
    tel: '47',
    name: '斯瓦尔巴和扬马延',
    'zh-CN': '斯瓦尔巴和扬马延',
    en: 'Svalbard & Jan Mayen',
    'en-US': 'Svalbard & Jan Mayen',
    'zh-HK': '斯瓦爾巴和揚馬延',
    'zh-TW': '斯瓦爾巴和揚馬延',
    'km-KH': 'ស្វាលបឿត និង ជែន ម៉ាយ៉េន',
    'vi-VN': 'Svalbard & Jan Mayen',
    'th-TH': 'สฟาลบาร์และยานไมเอน',
    'ms-MY': 'Svalbard & Jan Mayen',
    'ja-JP': 'スバールバル諸島とヤンマイエン島',
    'ko-KR': '스발바르 & 얀 마옌',
    'id-ID': 'Svalbard dan Jan Mayen',
    'lo-LA': 'Svalbard & Jan Mayen',
    'hi-IN': 'स्वालबार्ड और जान मायेन'
  },
  {
    shortName: 'SK',
    tel: '421',
    name: '斯洛伐克',
    'zh-CN': '斯洛伐克',
    en: 'Slovakia',
    'en-US': 'Slovakia',
    'zh-HK': '斯洛伐克',
    'zh-TW': '斯洛伐克',
    'km-KH': 'ស្លូវ៉ាគី',
    'vi-VN': 'Slovakia',
    'th-TH': 'สโลวาเกีย',
    'ms-MY': 'Slovakia',
    'ja-JP': 'スロバキア',
    'ko-KR': '슬로바키아',
    'id-ID': 'Slowakia',
    'lo-LA': 'ສະໂລວາເກຍ',
    'hi-IN': 'स्लोवाकिया'
  },
  {
    shortName: 'SL',
    tel: '232',
    name: '塞拉利昂',
    'zh-CN': '塞拉利昂',
    en: 'Sierra Leone',
    'en-US': 'Sierra Leone',
    'zh-HK': '獅子山',
    'zh-TW': '獅子山',
    'km-KH': 'សៀរ៉ាឡេអូន',
    'vi-VN': 'Sierra Leone',
    'th-TH': 'เซียร์ราลีโอน',
    'ms-MY': 'Sierra Leone',
    'ja-JP': 'シエラレオネ',
    'ko-KR': '시에라리온',
    'id-ID': 'Sierra Leone',
    'lo-LA': 'ເຊຍຣາລີໂອນ',
    'hi-IN': 'सेरा लिओन'
  },
  {
    shortName: 'SM',
    tel: '378',
    name: '圣马力诺',
    'zh-CN': '圣马力诺',
    en: 'San Marino',
    'en-US': 'San Marino',
    'zh-HK': '聖馬利諾',
    'zh-TW': '聖馬利諾',
    'km-KH': 'សាន់ម៉ារីណូ',
    'vi-VN': 'San Marino',
    'th-TH': 'ซานมารีโน',
    'ms-MY': 'San Marino',
    'ja-JP': 'サンマリノ',
    'ko-KR': '산마리노',
    'id-ID': 'San Marino',
    'lo-LA': 'ຊານມາຣິໂນ',
    'hi-IN': 'सैन मारिनो'
  },
  {
    shortName: 'SN',
    tel: '221',
    name: '塞内加尔',
    'zh-CN': '塞内加尔',
    en: 'Senegal',
    'en-US': 'Senegal',
    'zh-HK': '塞內加爾',
    'zh-TW': '塞內加爾',
    'km-KH': 'សេណេហ្គាល់',
    'vi-VN': 'Sénégal',
    'th-TH': 'เซเนกัล',
    'ms-MY': 'Senegal',
    'ja-JP': 'セネガル',
    'ko-KR': '세네갈',
    'id-ID': 'Senegal',
    'lo-LA': 'ເຊເນການ',
    'hi-IN': 'सेनेगल'
  },
  {
    shortName: 'SO',
    tel: '252',
    name: '索马里',
    'zh-CN': '索马里',
    en: 'Somalia',
    'en-US': 'Somalia',
    'zh-HK': '索馬利亞',
    'zh-TW': '索馬利亞',
    'km-KH': 'សូម៉ាលី',
    'vi-VN': 'Somali',
    'th-TH': 'โซมาเลีย',
    'ms-MY': 'Somalia',
    'ja-JP': 'ソマリア',
    'ko-KR': '소말리아',
    'id-ID': 'Somalia',
    'lo-LA': 'ໂຊມາເລຍ',
    'hi-IN': 'सोमालिया'
  },
  {
    shortName: 'SR',
    tel: '597',
    name: '苏里南',
    'zh-CN': '苏里南',
    en: 'Suriname',
    'en-US': 'Suriname',
    'zh-HK': '蘇利南',
    'zh-TW': '蘇利南',
    'km-KH': 'ស៊ូរីណាម',
    'vi-VN': 'Suriname',
    'th-TH': 'ซูรินาเม',
    'ms-MY': 'Suriname',
    'ja-JP': 'スリナム',
    'ko-KR': '수리남',
    'id-ID': 'Suriname',
    'lo-LA': 'Suriname',
    'hi-IN': 'सूरीनाम'
  },
  {
    shortName: 'SS',
    tel: '211',
    name: '南苏丹',
    'zh-CN': '南苏丹',
    en: 'South Sudan',
    'en-US': 'South Sudan',
    'zh-HK': '南蘇丹',
    'zh-TW': '南蘇丹',
    'km-KH': 'ស៊ូដង់ខាងត្បូង',
    'vi-VN': 'Nam Sudan',
    'th-TH': 'ซูดานใต้',
    'ms-MY': 'Sudan Selatan',
    'ja-JP': '南スーダン',
    'ko-KR': '남수단',
    'id-ID': 'Sudan Selatan',
    'lo-LA': 'ຊູດານໃຕ້',
    'hi-IN': 'दक्षिण सूडान'
  },
  {
    shortName: 'ST',
    tel: '239',
    name: '圣多美和普林西比',
    'zh-CN': '圣多美和普林西比',
    en: 'São Tomé & Príncipe',
    'en-US': 'São Tomé & Príncipe',
    'zh-HK': '聖多美和普林西比',
    'zh-TW': '聖多美和普林西比',
    'km-KH': 'សៅតូមេ & ព្រីនស៊ីប',
    'vi-VN': 'São Tomé & Príncipe',
    'th-TH': 'เซาตูเมและปรินซิปี',
    'ms-MY': 'São Tomé & Príncipe',
    'ja-JP': 'サントメ・プリンシペ',
    'ko-KR': '상투메 프린시페',
    'id-ID': 'Sao Tomé & Principe',
    'lo-LA': 'ເຊົາໂຕເມ ແລະພຣິນຊີປີ',
    'hi-IN': 'साओ टोमे और प्रिंसिपे'
  },
  {
    shortName: 'SV',
    tel: '503',
    name: '萨尔瓦多',
    'zh-CN': '萨尔瓦多',
    en: 'El Salvador',
    'en-US': 'El Salvador',
    'zh-HK': '薩爾瓦多',
    'zh-TW': '薩爾瓦多',
    'km-KH': 'អែលសាល់វ៉ាឌ័រ',
    'vi-VN': 'El Salvador',
    'th-TH': 'เอลซัลวาดอร์',
    'ms-MY': 'El Salvador',
    'ja-JP': 'エルサルバドル',
    'ko-KR': '엘살바도르',
    'id-ID': 'El Salvador',
    'lo-LA': 'El Salvador',
    'hi-IN': 'अल साल्वाडोर'
  },
  {
    shortName: 'SX',
    tel: '1721',
    name: '荷属圣马丁',
    'zh-CN': '荷属圣马丁',
    en: 'Sint Maarten',
    'en-US': 'Sint Maarten',
    'zh-HK': '荷屬聖馬丁',
    'zh-TW': '荷屬聖馬丁',
    'km-KH': 'ស៊ីន ម៉ារិន',
    'vi-VN': 'Sint Maarten',
    'th-TH': 'ซินต์มาร์เท่น',
    'ms-MY': 'Sint Maarten',
    'ja-JP': 'シント・マールテン島',
    'ko-KR': '신트마르턴',
    'id-ID': 'Sint Maarten',
    'lo-LA': 'Sint Maarten',
    'hi-IN': 'सिंट मार्टेन'
  },
  {
    shortName: 'SY',
    tel: '963',
    name: '叙利亚',
    'zh-CN': '叙利亚',
    en: 'Syria',
    'en-US': 'Syria',
    'zh-HK': '敘利亞',
    'zh-TW': '敘利亞',
    'km-KH': 'ស៊ីរី',
    'vi-VN': 'Syria',
    'th-TH': 'ซีเรีย',
    'ms-MY': 'Syria',
    'ja-JP': 'シリア',
    'ko-KR': '시리아',
    'id-ID': 'Suriah',
    'lo-LA': 'ຊີ​ເຣຍ',
    'hi-IN': 'सीरिया'
  },
  {
    shortName: 'SZ',
    tel: '268',
    name: '斯威士兰',
    'zh-CN': '斯威士兰',
    en: 'Swaziland',
    'en-US': 'Swaziland',
    'zh-HK': '史瓦濟蘭',
    'zh-TW': '史瓦濟蘭',
    'km-KH': 'ស្វាស៊ីឡង់',
    'vi-VN': 'Swaziland',
    'th-TH': 'สวาซิแลนด์',
    'ms-MY': 'Swaziland',
    'ja-JP': 'スワジランド',
    'ko-KR': '스와질란드',
    'id-ID': 'Swaziland',
    'lo-LA': 'ສະວາຊິແລນ',
    'hi-IN': 'स्वाजीलैंड'
  },
  {
    shortName: 'TA',
    tel: '290',
    name: '特里斯坦-达库尼亚群岛',
    'zh-CN': '特里斯坦-达库尼亚群岛',
    en: 'Tristan da Cunha',
    'en-US': 'Tristan da Cunha',
    'zh-HK': '特里斯坦-達庫尼亞群島',
    'zh-TW': '特里斯坦-達庫尼亞群島',
    'km-KH': 'ទ្រីស្ថាន ដាខុនញ៉ា',
    'vi-VN': 'Tristan da Cunha',
    'th-TH': 'ทริสตัน ดา กุนยา',
    'ms-MY': 'Tristan da Cunha',
    'ja-JP': 'トリスタン ダ クーニャ',
    'ko-KR': '트리스탄 다 쿠냐',
    'id-ID': 'Tristan da Cunha',
    'lo-LA': 'Tristan da Cunha',
    'hi-IN': 'ट्रिस्टन दा कुन्हा'
  },
  {
    shortName: 'TC',
    tel: '1649',
    name: '特克斯和凯科斯群岛',
    'zh-CN': '特克斯和凯科斯群岛',
    en: 'Turks & Caicos Islands',
    'en-US': 'Turks & Caicos Islands',
    'zh-HK': '特克斯和凱科斯群島',
    'zh-TW': '特克斯和凱科斯群島',
    'km-KH': 'កោះ ធើក & ខៃខុស',
    'vi-VN': 'Quần đảo Turks & Caicos',
    'th-TH': 'หมู่เกาะเติกส์และหมู่เกาะเคคอส',
    'ms-MY': 'Kepulauan Turks & Caicos',
    'ja-JP': 'タークス・カイコス諸島',
    'ko-KR': '터크스 케이커스 제도',
    'id-ID': 'Kepulauan Turks & Caicos',
    'lo-LA': 'ໝູ່ເກາະ Turks & Caicos',
    'hi-IN': 'तुर्क और कैकोस द्वीप समूह'
  },
  {
    shortName: 'TD',
    tel: '235',
    name: '乍得',
    'zh-CN': '乍得',
    en: 'Chad',
    'en-US': 'Chad',
    'zh-HK': '查德',
    'zh-TW': '查德',
    'km-KH': 'ឆាដ',
    'vi-VN': 'Tchad',
    'th-TH': 'ชาด',
    'ms-MY': 'Chad',
    'ja-JP': 'チャド',
    'ko-KR': '차드',
    'id-ID': 'anak',
    'lo-LA': 'ຊາດ',
    'hi-IN': 'काग़ज़ का टुकड़ा'
  },
  {
    shortName: 'TG',
    tel: '228',
    name: '多哥',
    'zh-CN': '多哥',
    en: 'Togo',
    'en-US': 'Togo',
    'zh-HK': '多哥',
    'zh-TW': '多哥',
    'km-KH': 'តូហ្គោ',
    'vi-VN': 'Togo',
    'th-TH': 'โตโก',
    'ms-MY': 'Togo',
    'ja-JP': '持ち帰り',
    'ko-KR': '토고',
    'id-ID': 'Ke sana',
    'lo-LA': 'ໂຕໂກ',
    'hi-IN': 'चल देना'
  },
  {
    shortName: 'TH',
    tel: '66',
    name: '泰国',
    'zh-CN': '泰国',
    en: 'Thailand',
    'en-US': 'Thailand',
    'zh-HK': '泰國',
    'zh-TW': '泰國',
    'km-KH': 'ប្រទេសថៃ',
    'vi-VN': 'Thái Lan',
    'th-TH': 'ประเทศไทย',
    'ms-MY': 'Thailand',
    'ja-JP': 'タイ',
    'ko-KR': '태국',
    'id-ID': 'Thailand',
    'lo-LA': 'ປະເທດໄທ',
    'hi-IN': 'थाईलैंड'
  },
  {
    shortName: 'TJ',
    tel: '992',
    name: '塔吉克斯坦',
    'zh-CN': '塔吉克斯坦',
    en: 'Tajikistan',
    'en-US': 'Tajikistan',
    'zh-HK': '塔吉克',
    'zh-TW': '塔吉克',
    'km-KH': 'តាជីគីស្ថាន',
    'vi-VN': 'Tajikistan',
    'th-TH': 'ทาจิกิสถาน',
    'ms-MY': 'Tajikistan',
    'ja-JP': 'タジキスタン',
    'ko-KR': '타지키스탄',
    'id-ID': 'Tajikistan',
    'lo-LA': 'ທາຈິກິດສະຖານ',
    'hi-IN': 'तजाकिस्तान'
  },
  {
    shortName: 'TK',
    tel: '690',
    name: '托克劳',
    'zh-CN': '托克劳',
    en: 'Tokelau',
    'en-US': 'Tokelau',
    'zh-HK': '托克勞',
    'zh-TW': '托克勞',
    'km-KH': 'តូក្លូ',
    'vi-VN': 'Tokelau',
    'th-TH': 'โตเกเลา',
    'ms-MY': 'Tokelau',
    'ja-JP': 'トケラウ',
    'ko-KR': '토켈라우',
    'id-ID': 'Tokelau',
    'lo-LA': 'Tokelau',
    'hi-IN': 'टोकेलाऊ'
  },
  {
    shortName: 'TL',
    tel: '670',
    name: '东帝汶',
    'zh-CN': '东帝汶',
    en: 'Timor-Leste',
    'en-US': 'Timor-Leste',
    'zh-HK': '東帝汶',
    'zh-TW': '東帝汶',
    'km-KH': 'ទីម័រខាងកើត',
    'vi-VN': 'Timor-Leste',
    'th-TH': 'ติมอร์-เลสเต',
    'ms-MY': 'Timor-Leste',
    'ja-JP': '東ティモール',
    'ko-KR': '동티모르',
    'id-ID': 'Timor-Leste',
    'lo-LA': 'ຕີມໍ-ເລສເຕ',
    'hi-IN': 'तिमोर-लेस्ते'
  },
  {
    shortName: 'TM',
    tel: '993',
    name: '土库曼斯坦',
    'zh-CN': '土库曼斯坦',
    en: 'Turkmenistan',
    'en-US': 'Turkmenistan',
    'zh-HK': '土庫曼',
    'zh-TW': '土庫曼',
    'km-KH': 'តូមិននីស្ថាន',
    'vi-VN': 'Turkmenistan',
    'th-TH': 'เติร์กเมนิสถาน',
    'ms-MY': 'Turkmenistan',
    'ja-JP': 'トルクメニスタン',
    'ko-KR': '투르크메니스탄',
    'id-ID': 'Turkmenistan',
    'lo-LA': 'ເຕີກເມນິສະຖານ',
    'hi-IN': 'तुर्कमेनिस्तान'
  },
  {
    shortName: 'TN',
    tel: '216',
    name: '突尼斯',
    'zh-CN': '突尼斯',
    en: 'Tunisia',
    'en-US': 'Tunisia',
    'zh-HK': '突尼西亞',
    'zh-TW': '突尼西亞',
    'km-KH': 'ទុយនីស៊ី',
    'vi-VN': 'Tunisia',
    'th-TH': 'ตูนิเซีย',
    'ms-MY': 'Tunisia',
    'ja-JP': 'チュニジア',
    'ko-KR': '튀니지',
    'id-ID': 'Tunisia',
    'lo-LA': 'ຕູນິເຊຍ',
    'hi-IN': 'ट्यूनीशिया'
  },
  {
    shortName: 'TO',
    tel: '676',
    name: '汤加',
    'zh-CN': '汤加',
    en: 'Tonga',
    'en-US': 'Tonga',
    'zh-HK': '東加',
    'zh-TW': '東加',
    'km-KH': 'តុងហ្គោ',
    'vi-VN': 'Tới nơi',
    'th-TH': 'มาถึงแล้ว',
    'ms-MY': 'dah sampai',
    'ja-JP': '到着した',
    'ko-KR': '도착했다',
    'id-ID': 'Tiba',
    'lo-LA': 'ມາຮອດແລ້ວ',
    'hi-IN': 'पहुँचा'
  },
  {
    shortName: 'TR',
    tel: '90',
    name: '土耳其',
    'zh-CN': '土耳其',
    en: 'Turkey',
    'en-US': 'Turkey',
    'zh-HK': '土耳其',
    'zh-TW': '土耳其',
    'km-KH': 'តួកគី',
    'vi-VN': 'Thổ Nhĩ Kỳ',
    'th-TH': 'ไก่งวง',
    'ms-MY': 'Turki',
    'ja-JP': '七面鳥',
    'ko-KR': '칠면조',
    'id-ID': 'Turki',
    'lo-LA': 'ຕຸລະກີ',
    'hi-IN': 'टर्की'
  },
  {
    shortName: 'TT',
    tel: '1868',
    name: '特立尼达和多巴哥',
    'zh-CN': '特立尼达和多巴哥',
    en: 'Trinidad & Tobago',
    'en-US': 'Trinidad & Tobago',
    'zh-HK': '千里達及托巴哥',
    'zh-TW': '千里達及托巴哥',
    'km-KH': 'ទ្រីនីដាដ និងតូបាហ្គោ',
    'vi-VN': 'Trinidad & Tobago',
    'th-TH': 'ตรินิแดดและโตเบโก',
    'ms-MY': 'Trinidad & Tobago',
    'ja-JP': 'トリニダード・トバゴ',
    'ko-KR': '트리니다드 토바고',
    'id-ID': 'Trinidad & Tobago',
    'lo-LA': 'Trinidad & Tobago',
    'hi-IN': 'ट्रिनिडाड और टोबैगो'
  },
  {
    shortName: 'TV',
    tel: '688',
    name: '图瓦卢',
    'zh-CN': '图瓦卢',
    en: 'Tuvalu',
    'en-US': 'Tuvalu',
    'zh-HK': '吐瓦魯',
    'zh-TW': '吐瓦魯',
    'km-KH': 'ទូវ៉ាលូ',
    'vi-VN': 'Tuvalu',
    'th-TH': 'ตูวาลู',
    'ms-MY': 'Tuvalu',
    'ja-JP': 'ツバル',
    'ko-KR': '투발루',
    'id-ID': 'Tuvalu',
    'lo-LA': 'ຕູວາລູ',
    'hi-IN': 'तुवालू'
  },
  {
    shortName: 'TW',
    tel: '886',
    name: '中国台湾',
    'zh-CN': '中国台湾',
    en: 'Taiwan',
    'en-US': 'Taiwan',
    'zh-HK': '中國台灣',
    'zh-TW': '中國台灣',
    'km-KH': 'តៃវ៉ាន់',
    'vi-VN': 'Đài Loan',
    'th-TH': 'ไต้หวัน',
    'ms-MY': 'Taiwan',
    'ja-JP': '台湾',
    'ko-KR': '대만',
    'id-ID': 'Taiwan',
    'lo-LA': 'ໄຕ້ຫວັນ',
    'hi-IN': 'ताइवान'
  },
  {
    shortName: 'TZ',
    tel: '255',
    name: '坦桑尼亚',
    'zh-CN': '坦桑尼亚',
    en: 'Tanzania',
    'en-US': 'Tanzania',
    'zh-HK': '坦尚尼亞',
    'zh-TW': '坦尚尼亞',
    'km-KH': 'តង់ហ្សានី',
    'vi-VN': 'Tanzania',
    'th-TH': 'แทนซาเนีย',
    'ms-MY': 'Tanzania',
    'ja-JP': 'タンザニア',
    'ko-KR': '탄자니아',
    'id-ID': 'Tanzania',
    'lo-LA': 'ແທນຊາເນຍ',
    'hi-IN': 'तंजानिया'
  },
  {
    shortName: 'UA',
    tel: '380',
    name: '乌克兰',
    'zh-CN': '乌克兰',
    en: 'Ukraine',
    'en-US': 'Ukraine',
    'zh-HK': '烏克蘭',
    'zh-TW': '烏克蘭',
    'km-KH': 'អ៊ុយក្រែន',
    'vi-VN': 'Ukraina',
    'th-TH': 'ยูเครน',
    'ms-MY': 'Ukraine',
    'ja-JP': 'ウクライナ',
    'ko-KR': '우크라이나',
    'id-ID': 'Ukraina',
    'lo-LA': 'ຢູເຄຣນ',
    'hi-IN': 'यूक्रेन'
  },
  {
    shortName: 'UG',
    tel: '256',
    name: '乌干达',
    'zh-CN': '乌干达',
    en: 'Uganda',
    'en-US': 'Uganda',
    'zh-HK': '烏幹達',
    'zh-TW': '烏幹達',
    'km-KH': 'អ៊ូហ្គង់ដា',
    'vi-VN': 'Uganda',
    'th-TH': 'ยูกันดา',
    'ms-MY': 'Uganda',
    'ja-JP': 'ウガンダ',
    'ko-KR': '우간다',
    'id-ID': 'Uganda',
    'lo-LA': 'ອູກັນດາ',
    'hi-IN': 'युगांडा'
  },
  {
    shortName: 'UM',
    tel: '1',
    name: '美国本土外小岛屿',
    'zh-CN': '美国本土外小岛屿',
    en: 'U.S. Outlying Islands',
    'en-US': 'U.S. Outlying Islands',
    'zh-HK': '美國本土外小島嶼',
    'zh-TW': '美國本土外小島嶼',
    'km-KH': 'កោះដាច់ស្រយាលរបស់សហរដ្ឋអាមេរិក',
    'vi-VN': 'Quần đảo xa xôi của Hoa Kỳ',
    'th-TH': 'หมู่เกาะรอบนอกของสหรัฐอเมริกา',
    'ms-MY': 'Kepulauan Luar A.S',
    'ja-JP': '米国の離島',
    'ko-KR': '미국 외곽 섬',
    'id-ID': 'Kepulauan Terluar AS',
    'lo-LA': 'ໝູ່​ເກາະ Outlying ຂອງ​ສະຫະລັດ',
    'hi-IN': 'यू.एस. बाहरी द्वीप समूह'
  },
  {
    shortName: 'US',
    tel: '1',
    name: '美国',
    'zh-CN': '美国',
    en: 'United States',
    'en-US': 'United States',
    'zh-HK': '美國',
    'zh-TW': '美國',
    'km-KH': 'សហរដ្ឋអាមេរិក',
    'vi-VN': 'Hoa Kỳ',
    'th-TH': 'สหรัฐอเมริกา',
    'ms-MY': 'Amerika Syarikat',
    'ja-JP': '米国',
    'ko-KR': '미국',
    'id-ID': 'Amerika Serikat',
    'lo-LA': 'ສະຫະລັດ',
    'hi-IN': 'संयुक्त राज्य अमेरिका'
  },
  {
    shortName: 'UY',
    tel: '598',
    name: '乌拉圭',
    'zh-CN': '乌拉圭',
    en: 'Uruguay',
    'en-US': 'Uruguay',
    'zh-HK': '烏拉圭',
    'zh-TW': '烏拉圭',
    'km-KH': 'អ៊ុយរូហ្គាយ',
    'vi-VN': 'Uruguay',
    'th-TH': 'อุรุกวัย',
    'ms-MY': 'Uruguay',
    'ja-JP': 'ウルグアイ',
    'ko-KR': '우루과이',
    'id-ID': 'Uruguay',
    'lo-LA': 'ອູຣູກວາຍ',
    'hi-IN': 'उरुग्वे'
  },
  {
    shortName: 'CA',
    tel: '1',
    name: '加拿大',
    'zh-CN': '加拿大',
    en: 'Canada',
    'en-US': 'Canada',
    'zh-HK': '加拿大',
    'zh-TW': '加拿大',
    'km-KH': 'ប្រទេសកាណាដា',
    'vi-VN': 'Canada',
    'th-TH': 'แคนาดา',
    'ms-MY': 'Kanada',
    'ja-JP': 'カナダ',
    'ko-KR': '캐나다',
    'id-ID': 'Kanada',
    'lo-LA': 'ການາດາ',
    'hi-IN': 'कनाडा'
  },
  {
    shortName: 'UZ',
    tel: '998',
    name: '乌兹别克斯坦',
    'zh-CN': '乌兹别克斯坦',
    en: 'Uzbekistan',
    'en-US': 'Uzbekistan',
    'zh-HK': '烏茲別克',
    'zh-TW': '烏茲別克',
    'km-KH': 'អ៊ូសបេគីស្ថាន',
    'vi-VN': 'Uzbekistan',
    'th-TH': 'อุซเบกิสถาน',
    'ms-MY': 'Uzbekistan',
    'ja-JP': 'ウズベキスタン',
    'ko-KR': '우즈베키스탄',
    'id-ID': 'Uzbekistan',
    'lo-LA': 'ອຸສເບກິສຖານ',
    'hi-IN': 'उज़्बेकिस्तान'
  },
  {
    shortName: 'VA',
    tel: '379',
    name: '梵蒂冈',
    'zh-CN': '梵蒂冈',
    en: 'Vatican City',
    'en-US': 'Vatican City',
    'zh-HK': '梵蒂岡',
    'zh-TW': '梵蒂岡',
    'km-KH': 'ទីក្រុងវ៉ាទីកង់',
    'vi-VN': 'Thành phố Vatican',
    'th-TH': 'นครวาติกัน',
    'ms-MY': 'Kota Vatican',
    'ja-JP': 'バチカン市国',
    'ko-KR': '바티칸 시국',
    'id-ID': 'Kota Vatikan',
    'lo-LA': 'ນະຄອນວາຕິກັນ',
    'hi-IN': 'वेटिकन सिटी'
  },
  {
    shortName: 'VC',
    tel: '1784',
    name: '圣文森特和格林纳丁斯',
    'zh-CN': '圣文森特和格林纳丁斯',
    en: 'St. Vincent & Grenadines',
    'en-US': 'St. Vincent & Grenadines',
    'zh-HK': '聖文森及格瑞那丁',
    'zh-TW': '聖文森及格瑞那丁',
    'km-KH': 'ផ្លូវសាំងវ៉ាំងសង់ និងហ្គ្រីណាឌីន',
    'vi-VN': 'Thánh Vincent & Grenadines',
    'th-TH': 'เซนต์วินเซนต์และเกรนาดีนส์',
    'ms-MY': 'St. Vincent & Grenadines',
    'ja-JP': 'セントビンセントおよびグレナディーン諸島',
    'ko-KR': '세인트 빈센트 그레나딘',
    'id-ID': 'St.Vincent & Grenadines',
    'lo-LA': 'ເຊນ ວິນເຊັນ ແລະ ເກນາດີນ',
    'hi-IN': 'सेंट विंसेंट और ग्रेनेडाइंस'
  },
  {
    shortName: 'VE',
    tel: '58',
    name: '委内瑞拉',
    'zh-CN': '委内瑞拉',
    en: 'Venezuela',
    'en-US': 'Venezuela',
    'zh-HK': '委內瑞拉',
    'zh-TW': '委內瑞拉',
    'km-KH': 'វ៉េណេស៊ុយអេឡា',
    'vi-VN': 'Venezuela',
    'th-TH': 'เวเนซุเอลา',
    'ms-MY': 'Venezuela',
    'ja-JP': 'ベネズエラ',
    'ko-KR': '베네수엘라',
    'id-ID': 'Venezuela',
    'lo-LA': 'ເວເນຊູເອລາ',
    'hi-IN': 'वेनेज़ुएला'
  },
  {
    shortName: 'VG',
    tel: '1284',
    name: '英属维尔京群岛',
    'zh-CN': '英属维尔京群岛',
    en: 'British Virgin Islands',
    'en-US': 'British Virgin Islands',
    'zh-HK': '英屬維京群島',
    'zh-TW': '英屬維京群島',
    'km-KH': 'កោះប្រីធីសវឺជីន',
    'vi-VN': 'Quần đảo Virgin thuộc Anh',
    'th-TH': 'หมู่เกาะบริติชเวอร์จิน',
    'ms-MY': 'Kepulauan Virgin British',
    'ja-JP': 'イギリス領ヴァージン諸島',
    'ko-KR': '영국령 버진아일랜드',
    'id-ID': 'Kepulauan Virgin Britania Raya',
    'lo-LA': 'ຫມູ່ເກາະ British Virgin',
    'hi-IN': 'ब्रिटिश वर्जिन आइसलैण्ड्स'
  },
  {
    shortName: 'VI',
    tel: '1340',
    name: '美属维尔京群岛',
    'zh-CN': '美属维尔京群岛',
    en: 'U.S. Virgin Islands',
    'en-US': 'U.S. Virgin Islands',
    'zh-HK': '美屬維京群島',
    'zh-TW': '美屬維京群島',
    'km-KH': 'កោះវឺជីនរបស់សហរដ្ឋអាមេរិក',
    'vi-VN': 'Quần đảo Virgin thuộc Hoa Kỳ',
    'th-TH': 'หมู่เกาะเวอร์จินของสหรัฐอเมริกา',
    'ms-MY': 'Kepulauan Virgin A.S',
    'ja-JP': 'アメリカ領ヴァージン諸島',
    'ko-KR': '미국령 버진아일랜드',
    'id-ID': 'Kepulauan Virgin AS',
    'lo-LA': 'ຫມູ່ເກາະເວີຈິນຂອງສະຫະລັດ',
    'hi-IN': 'यूएस वर्जिन द्वीप'
  },
  {
    shortName: 'VU',
    tel: '678',
    name: '瓦努阿图',
    'zh-CN': '瓦努阿图',
    en: 'Vanuatu',
    'en-US': 'Vanuatu',
    'zh-HK': '萬那杜',
    'zh-TW': '萬那杜',
    'km-KH': 'វ៉ានូអាទូ',
    'vi-VN': 'Vanuatu',
    'th-TH': 'วานูอาตู',
    'ms-MY': 'Vanuatu',
    'ja-JP': 'バヌアツ',
    'ko-KR': '바누아투',
    'id-ID': 'Vanuatu',
    'lo-LA': 'ວານູອາຕູ',
    'hi-IN': 'वानुअतु'
  },
  {
    shortName: 'WF',
    tel: '681',
    name: '瓦利斯和富图纳',
    'zh-CN': '瓦利斯和富图纳',
    en: 'Wallis & Futuna',
    'en-US': 'Wallis & Futuna',
    'zh-HK': '瓦利斯和富圖納',
    'zh-TW': '瓦利斯和富圖納',
    'km-KH': 'វ៉ាលីស & ហ្វូធូណា',
    'vi-VN': 'Wallis & Futuna',
    'th-TH': 'วาลลิสและฟุตูนา',
    'ms-MY': 'Wallis & Futuna',
    'ja-JP': 'ウォリス・フツナ',
    'ko-KR': '월리스푸투나',
    'id-ID': 'Wallis & Futuna',
    'lo-LA': 'Wallis & Futuna',
    'hi-IN': 'वालिस और फ़्यूचूना'
  },
  {
    shortName: 'WS',
    tel: '685',
    name: '萨摩亚',
    'zh-CN': '萨摩亚',
    en: 'Samoa',
    'en-US': 'Samoa',
    'zh-HK': '薩摩亞',
    'zh-TW': '薩摩亞',
    'km-KH': 'សាម័រ',
    'vi-VN': 'Samoa',
    'th-TH': 'ซามัว',
    'ms-MY': 'Samoa',
    'ja-JP': 'サモア',
    'ko-KR': '사모아',
    'id-ID': 'Samoa',
    'lo-LA': 'ຊາມົວ',
    'hi-IN': 'समोआ'
  },
  {
    shortName: 'XK',
    tel: '383',
    name: '科索沃',
    'zh-CN': '科索沃',
    en: 'Kosovo',
    'en-US': 'Kosovo',
    'zh-HK': '科索沃',
    'zh-TW': '科索沃',
    'km-KH': 'កូសូវ៉ូ',
    'vi-VN': 'Kosovo',
    'th-TH': 'โคโซโว',
    'ms-MY': 'Kosovo',
    'ja-JP': 'コソボ',
    'ko-KR': '코소보',
    'id-ID': 'Kosovo',
    'lo-LA': 'ໂຄໂຊໂວ',
    'hi-IN': 'कोसोवो'
  },
  {
    shortName: 'YE',
    tel: '967',
    name: '也门',
    'zh-CN': '也门',
    en: 'Yemen',
    'en-US': 'Yemen',
    'zh-HK': '葉門',
    'zh-TW': '葉門',
    'km-KH': 'យេម៉ែន',
    'vi-VN': 'Yêmen',
    'th-TH': 'เยเมน',
    'ms-MY': 'Yaman',
    'ja-JP': 'イエメン',
    'ko-KR': '예멘 아랍 공화국',
    'id-ID': 'Yaman',
    'lo-LA': 'ເຢເມນ',
    'hi-IN': 'यमन'
  },
  {
    shortName: 'YT',
    tel: '262',
    name: '马约特',
    'zh-CN': '马约特',
    en: 'Mayotte',
    'en-US': 'Mayotte',
    'zh-HK': '馬約特',
    'zh-TW': '馬約特',
    'km-KH': 'ម៉ាយ៉ាត',
    'vi-VN': 'mayotte',
    'th-TH': 'มายอต',
    'ms-MY': 'Mayotte',
    'ja-JP': 'マヨット',
    'ko-KR': '마요트',
    'id-ID': 'Mayotte',
    'lo-LA': 'ມາຢອດ',
    'hi-IN': 'मैयट'
  },
  {
    shortName: 'ZA',
    tel: '27',
    name: '南非',
    'zh-CN': '南非',
    en: 'South Africa',
    'en-US': 'South Africa',
    'zh-HK': '南非',
    'zh-TW': '南非',
    'km-KH': 'អាហ្វ្រិកខាងត្បូង',
    'vi-VN': 'Nam Phi',
    'th-TH': 'แอฟริกาใต้',
    'ms-MY': 'Afrika Selatan',
    'ja-JP': '南アフリカ',
    'ko-KR': '남아프리카',
    'id-ID': 'Afrika Selatan',
    'lo-LA': 'ອາຟຣິກາໃຕ້',
    'hi-IN': 'दक्षिण अफ़्रीका'
  },
  {
    shortName: 'ZM',
    tel: '260',
    name: '赞比亚',
    'zh-CN': '赞比亚',
    en: 'Zambia',
    'en-US': 'Zambia',
    'zh-HK': '尚比亞',
    'zh-TW': '尚比亞',
    'km-KH': 'សំប៊ី',
    'vi-VN': 'Zambia',
    'th-TH': 'แซมเบีย',
    'ms-MY': 'Zambia',
    'ja-JP': 'ザンビア',
    'ko-KR': '잠비아',
    'id-ID': 'Zambia',
    'lo-LA': 'ແຊມເບຍ',
    'hi-IN': 'जाम्बिया'
  },
  {
    shortName: 'ZW',
    tel: '263',
    name: '津巴布韦',
    'zh-CN': '津巴布韦',
    en: 'Zimbabwe',
    'en-US': 'Zimbabwe',
    'zh-HK': '辛巴威',
    'zh-TW': '辛巴威',
    'km-KH': 'ហ្ស៊ីមបាវ៉េ',
    'vi-VN': 'Zimbabwe',
    'th-TH': 'ซิมบับเว',
    'ms-MY': 'Zimbabwe',
    'ja-JP': 'ジンバブエ',
    'ko-KR': '짐바브웨',
    'id-ID': 'Zimbabwe',
    'lo-LA': 'ຊິມບັບເວ',
    'hi-IN': 'ज़िम्बाब्वे'
  }
]

function getImageUrl(shortName) {
  return require(`@/assets/image/flags/${shortName}.png`)
}
const labelKey = localStorage.getItem('locale') === 'zh' ? 'zh-CN' : 'en'
const data = list.map((item) => {
  return {
    ...item,
    value: item.shortName,
    label: item[labelKey],
    icon: getImageUrl(item.shortName)
  }
})

export function getCountryLabel(shortName) {
  return (data.find((v) => v.value === shortName) || {}).label
}

export default data
