import { render, staticRenderFns } from "./ProductManager.vue?vue&type=template&id=29b9ee5d&scoped=true&lang=true"
import script from "./ProductManager.vue?vue&type=script&lang=js"
export * from "./ProductManager.vue?vue&type=script&lang=js"
import style0 from "./ProductManager.vue?vue&type=style&index=0&id=29b9ee5d&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "29b9ee5d",
  null
  
)

export default component.exports