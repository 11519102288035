import axios from 'axios'
import { Message } from 'element-ui'
const api = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 5000 // Set the desired timeout value
})

export function makeApiRequest(config) {
  if (!config.headers) {
    config.headers = {}
  }
  let lang = localStorage.getItem('locale') || 'en'
  switch (lang) {
    case 'zh':
      lang = 'zh-CN'
      break
    case 'en':
      lang = 'en-US'
      break
    default:
      lang = 'en-US'
      break
  }
  config.headers.lang = lang
  return new Promise((resolve, reject) => {
    api.request(config)
      .then(response => {
        if (response.data.code === 200) {
          return resolve(response.data)
        } else {
          if (!config.hideErrorMsg && response.data?.message) {
            Message.error(response.data.message)
          }
          return reject(response.data)
        }
      })
      .catch(error => {
        if (!config.hideErrorMsg && error?.message) {
          Message.error(error.message)
        }
        return reject(error)
      })
  })
}
