<!--
 * @Author: Sky
 * @Date: 2025-01-24 11:30:46
 * @LastEditors: Sky
 * @LastEditTime: 2025-01-25 15:40:52
 * @Description: 删除账户
-->
<template lang="">
  <div class="deactivate-account">
    <el-form ref="form" :model="form" :rules="rules" class="deactivate-form" hide-required-asterisk :validate-on-rule-change="false" @submit.native.prevent>
      <div>
        <h2 class="title">{{ $t('DeactivateAccount.title') }}</h2>
        <p class="desc">{{ $t('DeactivateAccount.desc') }}</p>
      </div>
      <el-form-item :label="$t('DeactivateAccount.phone')" prop="phone">
        <el-input v-model="form.phone" :placeholder="$t('DeactivateAccount.phone')" class="phone-input" :disabled="step === 2">
          <template slot="prepend">
            <div v-if="step === 2" class="current-area">
              <img :src="currentArea?.icon" alt="">
              <span class="tel">+{{ currentArea?.tel }}</span>
              <i class="el-icon-arrow-down"></i>
            </div>
            <el-dropdown v-else trigger="click" placement="bottom" @command="handleCountry" @visible-change="visibleChange">
              <div class="current-area pointer">
                <img :src="currentArea?.icon" alt="">
                <span class="tel">+{{ currentArea?.tel }}</span>
                <i class="el-icon-arrow-down"></i>
              </div>
              <el-dropdown-menu slot="dropdown" class="area-dropdown">
                <el-input v-model="searchValue" class="search-input"></el-input>
                <div class="area-list">
                  <el-dropdown-item v-for="item in filterCountryList" :key="item.shortName" :command="item.shortName">
                    <div class="area-item flex justify-between items-center">
                      <div class="ellipsis-1">
                        <span>+{{ item.tel }}</span>
                        <span class="ml-4px mr-4px">•</span>
                        <span class="ellipsis-1">{{ item.label }}</span>
                      </div>
                      <img :src="item.icon">
                    </div>
                  </el-dropdown-item>
                </div>
              </el-dropdown-menu>
            </el-dropdown>
          </template>
          <div v-if="step === 1" slot="suffix">
            <el-button type="text" class="get-code-btn" @click="getCode" :disabled="checkPhone() !== 1 || time > 0">
              <span v-if="time === 0">{{ $t('DeactivateAccount.getCode') }}</span>
              <van-count-down v-else-if="time > 0" :time="time * 1000" @finish="countDownFinish">
                <template #default="timeData">
                  <span class="block">{{ $t('DeactivateAccount.reGetCode', [timeData.seconds])}}</span>
                </template>
              </van-count-down>
            </el-button>
          </div>
        </el-input>
      </el-form-item>
      <el-form-item v-show="step === 1" :label="$t('DeactivateAccount.code')" prop="code">
        <el-input v-model="form.code" :placeholder="$t('DeactivateAccount.code')" maxlength="50"></el-input>
      </el-form-item>
      <el-form-item v-show="step === 2" :label="$t('DeactivateAccount.reason')" prop="reason">
        <el-input v-model="form.reason" type="textarea" :autosize="{ minRows: 3, maxRows: 6 }" resize="none" :placeholder="$t('DeactivateAccount.reasonPlaceholder')"></el-input>
      </el-form-item>
      <div class="btn-container">
        <el-button v-if="step === 1" type="primary" :disabled="!(checkPhone() === 1 && form.code)" :loading="btnLoading" @click="nextStep">{{ $t('DeactivateAccount.signIn') }}</el-button>
        <el-button v-else-if="step === 2" type="primary" :loading="btnLoading" @click="deactivateAccount">{{ $t('DeactivateAccount.deleteAccount') }}</el-button>
      </div>
    </el-form>
  </div>
</template>
<script>
import { getDeleteAccountOPT, checkOPT, deleteAccount } from '@/api/account'
import COUNTRY_LIST from '@/utils/countryList'
import { CountDown } from 'vant'
import { Message } from 'element-ui'
import 'vant/lib/index.css'
export default {
  name: 'DeactivateAccount',
  components: {
    VanCountDown: CountDown
  },
  data() {
    return {
      form: {
        phone: '',
        code: '',
        reason: ''
      },
      step: 1,
      time: 0,
      token: '',
      searchValue: '',
      currentArea: '',
      btnLoading: false
    }
  },
  created() {
    const area = {
      zh: '86',
      en: '1'
    }
    this.currentArea = COUNTRY_LIST.find((v) => v.tel === area[this.$i18n.locale])
  },
  computed: {
    filterCountryList() {
      let value = this.searchValue.trim().toUpperCase()
      if (value) {
        value = value.toUpperCase()
      }
      return COUNTRY_LIST.filter(
        (v) => v.tel.includes(value) || v.label.toUpperCase().includes(value) || v.value.includes(value)
      )
    },
    rules() {
      return {
        phone: [{
          validator: (rule, value, callback) => {
            const res = this.checkPhone()
            switch (res) {
              case 0:
                callback(new Error(this.$t('DeactivateAccount.phoneError')))
                break
              case 1:
                callback()
                break
              case 2:
                callback(new Error(this.$t('DeactivateAccount.characterLimit', [8, 12])))
                break
              case 3:
                callback(new Error(this.$t('DeactivateAccount.phoneInvalid')))
                break
            }
          },
          trigger: ['blur']
        }],
        code: [
          { required: true, message: this.$t('DeactivateAccount.codeError'), trigger: ['blur'] }
        ]
      }
    }
  },
  methods: {
    // 0 未输入 1 有效 2 长度错误 3 格式错误
    checkPhone() {
      if (!this.form.phone) {
        return 0
      }
      if (this.form.phone.length < 8 || this.form.phone.length > 12) {
        return 2
      }
      if (/^[0-9]*$/.test(this.form.phone)) {
        return 1
      }
      return 3
    },
    // 重置页面数据
    resetData() {
      this.$refs.form.resetFields()
      this.form.phone = ''
      this.form.code = ''
      this.form.reason = ''
      this.step = 1
      this.time = 0
      this.token = ''
      this.searchValue = ''
    },
    nextStep() {
      this.btnLoading = true
      checkOPT({
        dialCode: this.currentArea.tel,
        phone: this.form.phone,
        code: this.form.code
      }).then((res) => {
        this.token = res.data
        this.step = 2
      }).finally(() => {
        this.btnLoading = false
      })
    },
    deactivateAccount() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.btnLoading = true
          const reason = this.form.reason ? this.form.reason.trim() : ''
          deleteAccount({
            token: this.token,
            reason
          }).then(() => {
            this.resetData()
            Message.success(this.$t('DeactivateAccount.success'))
          }).finally(() => {
            this.btnLoading = false
          })
        }
      })
    },
    getCode() {
      getDeleteAccountOPT({
        dialCode: this.currentArea.tel,
        phone: this.form.phone
      }).then(() => {
        this.time = 60
      })
    },
    countDownFinish() {
      this.time = 0
    },
    handleCountry(command) {
      this.currentArea = COUNTRY_LIST.find((v) => v.shortName === command)
    },
    visibleChange(val) {
      if (!val) {
        this.searchValue = ''
      }
    }
  }
}
</script>
<style lang="less">
.ml-4px {
  margin-left: 4px;
}
.mr-4px {
  margin-right: 4px;
}
.area-dropdown {
  height: 400px;
  min-width: 260px;
  max-width: 300px;
  padding: 10px 12px;
  border-radius: 16px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  .search-input {
    margin-bottom: 15px;
    flex-shrink: 0;
  }
  .area-list {
    flex: 1;
    overflow-y: auto;
    /* 自定义滚动条 */
    &::-webkit-scrollbar {
      width: 10px;
      /* 滚动条宽度 */
      height: 10px;
      /* 滚动条高度 */
    }

    &::-webkit-scrollbar-track {
      background: #f5f7fa;
      /* 滑道颜色 */
      border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: rgb(213, 213, 218);
      /* 滚动条颜色 */
      border-radius: 4px;
      border: 2px solid #fff;

      /* 内边距，显示滑道背景色 */
      :hover {
        background-color: #c1c1c1;
      }
    }
    .area-item {
      img {
        flex-shrink: 0;
        width:38px;
        height:23px;
        margin-left: 10px;
      }
    }
  }
}
</style>
<style lang="less" scoped>
.deactivate-account {
  .deactivate-form {
    width: 50%;
    margin: 100px auto;
    padding: 60px;
    border-radius: 20px;
    background: #FAFAFA;
    box-sizing: border-box;
    text-align: left;
    .title {
      color: #000;
      font-size: 26px;
      font-weight: 500;
    }
    .desc {
      color: #595959;
      font-size: 17px;
      font-weight: 400;
      margin-bottom: 20px;
      white-space: pre-wrap;
    }
    .phone-input {
      ::v-deep {
        .el-input-group__prepend {
          background-color: #fff;
        }
        .el-input__suffix {
          right: 10px;
          display: flex;
          align-items: center;
          .get-code-btn {
            color: #3370FF;
            padding: 0;
            border-left: 1px solid #C4C4C4;
            padding-left: 20px;
            border-radius: 0;
            line-height: 22px;
            &.is-disabled {
              opacity: 0.6;
            }
            .van-count-down {
              color: #3370FF;
            }
          }
        }
      }
    }
    .current-area {
      display: flex;
      align-items: center;
      img {
        width: 18px;
      }
      .tel {
        margin: 0 5px;
      }
    }
    .el-form-item {
      margin-bottom: 20px;
      ::v-deep {
        .el-form-item__label {
          color: #000;
        }
      }
    }
    .btn-container {
      text-align: right;
      .el-button--primary.is-disabled {
        color: #FFF;
        background-color: #a0cfff;
        border-color: #a0cfff;
      }
    }
  }
}
</style>
