<!--
 * @Author: Sky
 * @Date: 2025-01-24 17:47:39
 * @LastEditors: Sky
 * @LastEditTime: 2025-01-24 18:01:16
 * @Description: 儿童安全政策协议
-->
<template>
  <div>
    <div>
      <SubHeader :data="data" />
      <AgreementTemplate />
    </div>
  </div>
</template>
<script>
import SubHeader from '../components/SubHeader.vue'
import AgreementTemplate from './AgreementTemplate.vue'
export default {
  name: 'ChildSafetyPolicy',
  components: {
    SubHeader,
    AgreementTemplate
  },
  data() {
    return {
      data: {
        label_name: 'childSafetyPolicy',
        content_desc: 'childSafetyPolicyContent',
        img: require('@/assets/image/sub-header/privacy-policy.png')
      }
    }
  }
}
</script>
<style lang="less" scoped></style>
