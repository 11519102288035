<template>
  <div>
    <div>
      <SubHeader :data="data" />
      <div class="container">
        <div class="container-text">{{ content }}</div>
      </div>
    </div>
  </div>
</template>
<script>
import SubHeader from '../components/SubHeader.vue'
import UserPrivacyPolicyZh from '@/assets/agreement/UserPrivacyPolicy_zh.txt'
import UserPrivacyPolicyEn from '@/assets/agreement/UserPrivacyPolicy_en.txt'
export default {
  name: 'Privacy Policy',
  components: {
    SubHeader
  },
  data() {
    return {
      data: {
        label_name: 'privacyPolicy',
        content_desc: 'privacy_policyContent',
        img: require('@/assets/image/sub-header/privacy-policy.png')
      }
    }
  },
  computed: {
    content() {
      return this.$i18n.locale === 'zh' ? UserPrivacyPolicyZh : UserPrivacyPolicyEn
    }
  }
}
</script>
<style lang="less" scoped>
.container-text {
  font-size: 16px;
  padding: 40px 0 80px;
  white-space: pre-wrap;
  text-align: justify;
}
</style>
