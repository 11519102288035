<template lang="">
  <div>
    <SubHeader :data="data" />
    <div class="container">
      <div class="container-text">{{ content }}</div>
    </div>
  </div>
</template>
<script>
import SubHeader from '../components/SubHeader.vue'
import UserTermServiceZh from '@/assets/agreement/UserTermService_zh.txt'
import UserTermServiceEn from '@/assets/agreement/UserTermService_en.txt'
export default {
  name: 'User Ferm of Service',
  components: {
    SubHeader
  },
  data() {
    return {
      data: {
        label_name: 'userTermService',
        content_desc: 'userTermServiceContent',
        img: require('@/assets/image/sub-header/user-term.png')
      }
    }
  },
  computed: {
    content() {
      return this.$i18n.locale === 'zh' ? UserTermServiceZh : UserTermServiceEn
    }
  }
}
</script>
<style lang="less" scoped>
.container-text {
  font-size: 16px;
  padding: 40px 0 80px;
  white-space: pre-wrap;
  text-align: justify;
}
</style>
